import React, {useEffect, useRef, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageWrapper from "./PageWrapper";
import {Button} from "react-bootstrap";
import {isMobile} from "react-device-detect";
import {AiOutlineCalendar, AiOutlineWarning} from "react-icons/ai";
import Palette from "../../util/Palette";
import {MdError, MdLocationOn} from "react-icons/md";
import {TiLocationOutline, TiTicket} from "react-icons/ti";
import {useCookies} from "react-cookie";
import Session from "../../models/Session";
import PapermoonTicketNode from "../reusable/PapermoonTicketNode";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import moment from "moment";
import ScheduleNode from "../reusable/ScheduleNode";
import GlobalData from "../../util/GlobalData";
import _ from "lodash";
import SessionModal from "../reusable/SessionModal";
import {useHistory} from 'react-router-dom'
import {CsvToHtmlTable} from "react-csv-to-table";
import {MDBDataTable} from 'mdbreact';

let firstLoad = false

export default function PatjarKita(props) {

    const [isLoginFormShown, setIsLoginFormShown] = useState(false);
    const [loginMessage, setLoginMessage] = useState("")

    const buyTicketRef = useRef(null);
    const [cookie, setCookie, removeCookie] = useCookies()
    const isEN = cookie.lang !== "id";
    const [fbaSessions, setFbaSessions] = useState([]);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    const history = useHistory()

    const [isToCModalOpen, setIsToCModalOpen] = useState(false)
    const [isPPModalOpen, setIsPPModalOpen] = useState(false)

    const [isSessionModalShow, setIsSessionModalShown] = useState(false)
    const [isPaymentModalShow, setIsPaymentModalShow] = useState(false)
    const [paymentUrl, setPaymentUrl] = useState("")
    const [selectedSession, setSelectedSession] = useState(null)

    function handleResize() {
        console.log('width: ' + window.innerWidth)

        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
        })
    }

    const fetchSessions = async () => {

        console.log("is calling with " + GlobalData.token)

        let sessions = await new Session().getFBASessions();

        let defaultId = 0

        if (firstLoad) {
            if (props.match.params.id) {
                defaultId = this.props.match.params.id
                console.log("def", defaultId)
            }
            firstLoad = false
        }

        if (defaultId > 0) {
            let session = sessions.find(e => {
                console.log(defaultId, parseInt(e.id), parseInt(e.id) === parseInt(defaultId))
                return parseInt(e.id) === parseInt(defaultId)
            })
            console.log("selected session", sessions)
            if (session) {
                // this.setState({
                //     ...this.props,
                //     selectedSession: session,
                //     isSessionModalShown: true
                // })
            }

        }

        console.log(sessions)
        setFbaSessions(sessions)

    }

    useEffect(() => {

        fetchSessions()

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }

    }, []);

    const showLogin = () => {
        setIsLoginFormShown(true)
        setLoginMessage('Harap login untuk melanjutkan pendaftaran')
        setIsSessionModalShown(false)
    }

    return (
        <PageWrapper
            backgroundColor={"#ffffff"}
            loginShow={isLoginFormShown}
            message={loginMessage}
            customOnHide={isLoginFormShown ? () => setIsLoginFormShown(false) : null}
            headerActive={'patjarkita'}
            hideFooter={true}
        >

            <Container fluid style={{backgroundColor: "#fdfdfd"}}>
                <Row style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: "center",
                    backgroundColor: "#ffffff"
                }}>
                    <Col xl={10} style={{display: 'flex', justifyContent: 'center', alignContent: "center"}}>
                        <img src={require("../../asset/2024/patjarkeciljuni/bannerpatjarkita.jpeg")}
                             style={{width: '100%', maxWidth: 900, height: "100%"}}/>
                    </Col>
                </Row>

                <Container style={{paddingTop: '1em', paddingBottom: '1em', backgroundColor: "#FFFFFF50"}}>
                    <Row style={{display: 'flex', justifyContent: 'center', marginTop: 50, marginBottom: 25}}>
                        <Col xl={10}>
                            <b style={{fontFamily: "Bebas-Neue", fontSize: 32}}>PENDAFTARAN PATJARKITA JAKARTA
                            </b>
                            <p style={{marginTop: 15}}>
                                <b>patjarkita</b> merupakan sebutan untuk kawan-kawan yang terlibat di balik layar patjarmerah. Kali ini, patjarmerah akan mengadakan patjarmerah kecil - Festival Kecil dan Pasar Buku yang didedikasikan untuk kawan-kawan kecil, belia, dan keluarga. Di setiap tempat yang patjarmerah singgahi, kami selalu membentuk barisan patjarkita, kawan bertumbuh dan ‘mata’ untuk mengenal setiap tempat dengan lebih baik.
                            </p>
                            <p>
                                patjarboekoe Jakarta dan sekitarnya yang memiliki kecintaan terhadap literasi dan ingin terlibat langsung di dapur patjarmerah kecil pada 29 Juni - 7 Juli 2024, sila mengisi formulir pendaftaran dengan menekan tombol "Daftar".
                            </p>
                            <p>
                                <b>
                                    <li>Penjaga Buku (20 orang)</li>
                                </b>
                            </p>
                            <p>
                                Memiliki tugas utama menata buku dengan rapi dan sesuai kategori. Penjaga buku juga bertanggung jawab membantu pengunjung menemukan buku-buku yang dicari. Lebih diutamakan penjaga buku yang mampu memberikan rekomendasi bacaan kepada pengunjung.
                            </p>

                            <p>
                                <b>
                                    <li>Perlengkapan & Logistik (3 orang)</li>
                                </b>
                            </p>
                            <p>
                                Memastikan seluruh perlengkapan penunjang kegiatan dalam kondisi baik seperti penerangan dan meja display buku. Menyiapkan perlengkapan acara festival seperti meja, kursi, properti panggung, sound system, proyektor, dan lainnya.
                            </p>

                            <p>
                                <b>
                                    <li>Transportasi (1 orang)</li>
                                </b>
                            </p>
                            <p>
                                Memiliki tugas utama untuk menjemput pengisi acara dan moderator ke lokasi festival. Memahami alur transportasi di wilayah lokasi acara dan penjemputan. Wajib memiliki SIM A yang masih berlaku. Mampu mengemudi mobil dengan transmisi otomatis dan manual. Menjaga kondisi kendaraan selalu baik dan bersih selama kegiatan berlangsung.
                            </p>

                            <p>
                                <b>
                                    <li>Runner (1 orang)</li>
                                </b>
                            </p>
                            <p>
                                Mengkoordinasikan dan menjadwalkan kendaraan untuk keperluan pengisi acara serta memastikan pengisi acara hadir di lokasi tepat waktu. Memasikan seluruh kebutuhan pengisi acara yang terkait dengan festival terpenuhi. Mampu mencari solusi apabila ada kendala terkait dengan kendaraan dan jadwal.
                            </p>

                            <p>
                                <b>
                                    <li>MC (2 orang)</li>
                                </b>
                            </p>
                            <p>
                                Membuka dan menutup setiap mata acara di festival patjarmerah. Mengumumkan secara rutin kepada pengunjung informasi terkait pasar buku dan acara festival.
                            </p>

                            <p>
                                <b>
                                    <li>Moderator Acara  (2 orang)</li>
                                </b>
                            </p>
                            <p>
                                Mampu mengatur, memandu, dan mengawasi jalannya acara festival. Memiliki pengetahuan dan kemampuan komunikasi yang baik, serta menggemari dunia cerita anak.
                            </p>

                            <p>
                                <b>
                                    <li>Staf Media Sosial (1 orang)</li>
                                </b>
                            </p>
                            <p>
                                Merancang strategi komunikasi di media sosial. Merancang dan mengatur unggahan ke media sosial patjarmerah untuk mengomunikasikan apa-apa saja acara dan hal-hal menarik yang ditawarkan patjarmerah kecil. Membangun kerja sama dengan akun-akun media sosial perbukuan, kepenulisan, dan baca untuk bisa terlibat dalam kegiatan.
                            </p>

                            <p>
                                <b>
                                    <li>Dokumentasi Foto dan Video (1 fotografer dan 1 videografer)</li>
                                </b>
                            </p>
                            <p>
                                Memiliki keterampilan fotografi dan video. Mendokumentasikan kegiatan festival dan pasar buku sejak pra kegiatan untuk keperluan konten harian publikasi di media sosial. Memiliki kemampuan mengarsip dokumen foto dan video.
                            </p>

                            <p>
                                <b>
                                    <li>Staf Sponsorship (1 orang)</li>
                                </b>
                            </p>
                            <p>
                                Mencari sponsor dan pendukung kegiatan festival patjarmerah kecil. Memiliki keterampilan negosiasi dan presentasi yang persuasif. Memiliki pengalamanan di bidang sponsorship menjadi nilai tambah.
                            </p>

                            <p>
                                <b>
                                    <li>Koordinator Affiliator TikTok (1 orang)</li>
                                </b>
                            </p>
                            <p>
                            Suka membaca dan memiliki pengetahuan terkait buku anak, remaja, dan keluarga. Melakukan koordinasi dengan para affiliator-affiliator yang akan melakukan live penjualan di area pasar buku. Memastikan para affiliator mendapatkan akses sesuai dengan jadwal serta memberikan informasi-informasi yang dibutuhkan oleh para affiliator. Memahami pemasaran di platform TikTok. 
                            </p>
                           

                            <p style={{marginTop: 40}}>
                                <b><i>patjarmerah menunggu portofolio terbaikmu sampai 30 April 2024.</i></b>
                            </p>

                        </Col>
                    </Row>
                </Container>
                <Row style={{
                    position: 'fixed',
                    justifyContent: 'center',
                    bottom: isMobile ? "3.5em" : 0,
                    backgroundColor: '#ffffff',
                    width: '100%',
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingRight: 0,
                }}>
                    <Col xl={8}>

                    </Col>
                    <Col xl={2} style={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignContent: "right",
                        backgroundColor: "#ffffff"
                    }}>
                        <Button
                            style={{
                                fontWeight: 'bolder',
                                fontSize: '1.05em',
                                color: 'white',
                                borderColor: '#a6191c',
                                backgroundColor: '#a6191c',
                                // borderWidth: 0,
                                paddingTop: 10,
                                paddingBottom: 10,
                                margin: 10,
                                width: "75%"
                            }}
                            onClick={() => window.open('https://forms.gle/Hz62gWguGQiPb3MHA')}
                        >
                            DAFTAR
                        </Button>
                    </Col>
                    <Col xl={2}>

                    </Col>
                </Row>
            </Container>
        </PageWrapper>
    )
}
