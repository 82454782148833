import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {FaCaretDown} from "react-icons/fa";
import Col from "react-bootstrap/Col";
import PageWrapper from "./PageWrapper";
import {ParallaxBanner} from "react-scroll-parallax";
import CountdownRow from "../reusable/CountdownRow";
import moment from "moment";
import {useCookies} from 'react-cookie';
import GlobalData from "../../util/GlobalData";
import {useHistory, useLocation} from "react-router-dom";
import {isMobile} from "react-device-detect";
import LoginModal from "../reusable/LoginModal";
import Book from "../../models/Book";
import ApplicationActivities from "../../models/ApplicationActivity";
import MainBanner from "../../asset/2024/remik/banner.jpeg"
//import MainBanner from "../../asset/fba2023/headerweb.png";
import MainBannerMobile from "../../asset/2024/remik/banner.jpeg"
//import MainBannerMobile from "../../asset/fba2023/headermobile.png";
import MainKawan from "../../asset/solo2023/main_kawan.png";
import { KawanPatjar, komunitas, penerbit, media } from "../../util/kawanpatjar";

/* let sponsors = []

for (let i = 0; i < 5; i++) {
  sponsors.push(require(`../../asset/solo2023/sponsor/${i + 1}.png`))
}

for (let i = 0; i < 6; i++) {
  sponsors.push(require(`../../asset/solo2023/sponsor/${i + 1}.jpeg`))
}


let kolaborator = []

for (let i = 0; i < 36; i++) {
  kolaborator.push(require(`../../asset/solo2023/kolaborator/comm${i + 1}.png`))
}

let mediaPartners = []

for (let i = 0; i < 36; i++) {
  mediaPartners.push(require(`../../asset/solo2023/media/image${i + 1}.png`))
}

let penerbit = []

for (let i = 0; i < 24; i++) {
  penerbit.push(require(`../../asset/solo2023/penerbit/${i + 1}.png`))
}

let mainPatjar = []

for (let i = 0; i < 4; i++) {
  mainPatjar.push(require(`../../asset/solo2023/mainPatjar/main${i + 1}.png`))
}

for (let i = 0; i < 6; i++) {
  mainPatjar.push(require(`../../asset/solo2023/mainPatjar/sub${i + 1}.png`))
} */

export default function Home(props) {
  let winWidth = window.innerWidth
  let history = useHistory()
  let location = useLocation();

  let [scrollPosition, setScrollPosition] = useState(0)
  let [user, setUser] = useState(GlobalData.user)
  let [windowIsLarge, setWindowIsLarge] = useState(winWidth > 1199)
  const [cookie, setCookie, removeCookie] = useCookies()

  const [loginShow, setLoginShow] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(GlobalData.token || cookie.token)
  const [userData, setUserData] = useState(GlobalData.user)
  const [selectedBook, setSelectedBook] = useState({additional_information: {}});
  const [isBookModalShown, setBookModalShown] = useState(false);

  const [specials, setSpecials] = useState([]);

  const [isLanguageInfoOpen, setIsLanguageInfoOpen] = useState(true);

  const [isBookStoresShown, setBookStoreShown] = useState(true);


  let isEN = cookie.lang !== "id"

  useEffect(() => {
    // updateFeatureAvailability();

    console.log("component di updateo")

    setUserData(GlobalData.user)

    ApplicationActivities.sendActivity({
      type: "OPEN_APP",
      description: "",
      timestamp: new Date(),
      os: isMobile ? "mobile-website" : "website"
    })

    return () => {
      window.removeEventListener('scroll', listenToScroll)
    }
  }, []);

  useEffect(() => {

  }, [])


  function listenToScroll() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    const scrolled = winScroll / height

    setScrollPosition(scrolled)

  }

  const renderSudoHeader = () => {
    if (isMobile) {
      return <div style={{height: "3em"}}/>
    } else {
      return
    }
  }

  const renderBanner = () => {
    if (isMobile) {

      return <>
        <Row style={{
          width: "103vw",
          background : "#e6e7e4",
          // backgroundImage: `url("${require("../../asset/header-july-bg-only.jpg")}")`,
          backgroundPosition: 'center',
          margin: 0,
          display: "flex",
          flexDirection: "column",
          // marginTop: "-3em",
        }}>

          <img
            onClick={() => {
              history.push("/renik")
            }}
            style={{
              // paddingBlockStart: "0.5em",
              width: "100%",
              // paddingTop : 30,
              imageOrientation: "center",
              objectFit: "contain",
              objectPosition: "top",
              paddingLeft: 0
            }} src={MainBannerMobile}/>

        </Row>

      </>

      return <Row style={{
        width: "100%",
        backgroundImage: `url("${GlobalData.subfolder}image/polos5.jpg")`,
        backgroundPosition: 'center',
        margin: 0,
        display: "flex",
        flexDirection: "column",
        marginTop: "-3em",
      }}>

        <div style={{
          height: "6em"
        }}/>

        <video style={{
          width: "100%",
          objectFit: "contain"
        }} autoPlay muted loop>
          <source src={require("../../asset/moving_banner_mobile.MOV")}/>
        </video>

      </Row>


    } else {
      // return <img src={GlobalData.subfolder + "image/banner-web.jpg"}
      //             style={{
      //                 width: "100%",
      //                 height :"100vh",
      //                 objectFit : "cover"
      //             }}
      // />

      return <Row style={{
        height: "40vh",
        width: "100%",
        backgroundColor: "#ffffff",
        // background : Palette.PESTA_BONEKA_LINEN,
        // backgroundImage: `url("${GlobalData.subfolder}image/star-bg.jpeg")`,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        margin: 0,
      }}>
        <img style={{
          width: "100%",
          height: " 100%",
          paddingBlockStart: "10px",
          objectFit: "contain",
          objectPosition: "center",
          cursor: 'pointer',
        }}
             onClick={() => history.push("/renik")}
             src={MainBanner}/>
        {/*<video style={{
                    width: "100%",
                    height: " 100%",
                    objectFit: "contain"
                }} autoPlay muted loop>
                    <source src={require("../../asset/moving_banner.MOV")}/>
                </video>*/}

      </Row>

      return <ParallaxBanner
        disabled={false}
        layers={[
          {
            image: GlobalData.subfolder + "image/banner-web-background.png",
            amount: 1,
            disabled: false,
            props: {
              style: {
                backgroundSize: "contain",
              }
            },
          },
          {
            // image : GlobalData.subfolder + "image/hand.PNG",
            children: <Container
              style={{
                marginBlockStart: "2%",
                marginBlockEnd: "2%"
              }}
            >
              <Row float="center">
                <Col lg={{offset: 3, span: 6}} style={{
                  alignItems: "center"
                }} float="center">
                  <div style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}>
                    <img
                      style={{
                        width: "100%",
                        left: 3,
                        objectFit: "contain",
                        height: "18em"
                      }}
                      src={GlobalData.subfolder + "image/complete-logo-cs.png"}/>
                  </div>
                </Col>
              </Row>

              {
                windowIsLarge ?
                  <Row style={{height: "15px"}}/>
                  :
                  null
              }

              <CountdownRow
                startTime={new moment("2020-04-28")}
                endTime={new moment("2020-04-30")}
                containerStyle={{
                  // marginInlineStart: "20%",
                  // marginInlineEnd: "20%"
                }}
              />

              {
                windowIsLarge ?
                  <Row style={{height: "10px"}}/>
                  :
                  null
              }

              {/*}
                            <Row>

                                <div style={{
                                    flex: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <a href={"https://www.instagram.com/patjarmerah_id/"} target={"_blank"} rel="noopener noreferrer">
                                        <Button
                                            style={{
                                                borderRadius: 8,
                                                borderWidth: 3,
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                            size={"lg"} variant={"outline-dark"}>
                                            <FaInstagram/>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",

                                            }}>
                                                &nbsp;Follow Us!
                                            </div>
                                            <span>
                                            </span>
                                        </Button>
                                    </a>
                                    <div style={{
                                        width: 20
                                    }}/>
                                    <a href={"https://play.google.com/store/apps/details?id=com.patjarmerah"}
                                       target={"_blank"} rel="noopener noreferrer">
                                        <Button
                                            style={{
                                                borderRadius: 8,
                                                borderWidth: 3,
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                            size={"lg"} variant={"outline-dark"}>
                                            <FaGooglePlay/>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",

                                            }}>
                                                &nbsp;Play Store
                                            </div>
                                            <span>
                                            </span>
                                        </Button>
                                    </a>
                                    <div style={{
                                        width: 20
                                    }}/>
                                    <Button
                                        style={{
                                            borderRadius: 8,
                                            borderWidth: 3,
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                        size={"lg"} variant={"outline-dark"}>
                                        <FaApple/>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",

                                        }}>
                                            &nbsp;iOS Store
                                        </div>
                                        <span>
                                            </span>
                                    </Button>

                                </div>
                            </Row>
                            {*/}

              {
                windowIsLarge ?
                  <Row style={{height: "15px"}}/>
                  :
                  null
              }

              <Row float="center">
                <Col lg={{offset: 3, span: 6}} style={{
                  alignItems: "center"
                }} float="center">
                  <div style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}>
                    <img
                      style={{
                        width: "100%",
                        left: 3,
                        objectFit: "contain",
                        height: "15em"
                      }}
                      src={GlobalData.subfolder + "image/kami-membaca.png"}/>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={{span: 4, offset: 4}}
                     style={{}}
                >
                  <div style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}>
                    <FaCaretDown style={{
                      height: 30,
                      width: 30,
                      color: "black"
                    }}/>
                  </div>

                </Col>
              </Row>

            </Container>,
            amount: 0
          }
        ]}
        style={{
          height: "100vh"
        }}
      />
    }
  }

  const fetchBooks = async function () {
    try {
      let books = await new Book().getAll();
      let temporarySpecial = []

      for (let b of books) {
        if (b.category === "Istimewa") {
          temporarySpecial.push(b)
        }
      }
      setSpecials(temporarySpecial)
    } catch (e) {
      console.log(e)
    }
  }

  const renderSponsors =()=>{
    return <>
      <div style={{
          backgroundColor: "white",
          // background : Palette.PESTA_BONEKA_LINEN
        }}>
          <div style={{
            height: "2em"
          }}/>
          <Row style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingInlineStart: isMobile ? "6%" : windowIsLarge ? "12%" : "5%",
            paddingInlineEnd: isMobile ? "6%" : windowIsLarge ? "12%" : "5%",
            margin: 'auto',
            marginTop: '50px',
            maxWidth: '1920px'
          }}>
            <Col xs={12} md={12}>
              <h2 style={{textAlign: "center"}}>kawanpatjar</h2>
            </Col>
            {KawanPatjar.map(image => {
              //image.replace(/(\s+)/g, '\\$1')
              console.log(image)
              return <Col xs={12} sm={6} lg={4} xl={3} style={{
                margin: "20px 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
                <img src={image} alt="mediaLogo" style={{
                  maxWidth: "100%",
                  height: "10em",
                  position: "relative",
                  objectFit: "contain"
                }}/>
              </Col>
            })}
           </Row>
           <Row>
            {komunitas.map(image => {
              return <Col xs={12} sm={6} lg={4} xl={3} style={{
                margin: "20px 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
                <img src={image} alt="mediaLogo" style={{
                  maxWidth: "100%",
                  height: "10em",
                  position: "relative",
                  objectFit: "contain"
                }}/>
              </Col>
            })}
            {media.map(image => {
              return <Col xs={12} sm={6} lg={4} xl={3} style={{
                margin: "20px 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
                <img src={image} alt="mediaLogo" style={{
                  height: "10em",
                  maxWidth: "100%",
                  position: "relative",
                  objectFit: "contain"
                }}/>
              </Col>
            })}
            {penerbit.map(image => {
              return <Col xs={12} sm={6} lg={4} xl={3} style={{
                margin: "20px 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
                <img src={image} alt="mediaLogo" style={{
                  height: "10em",
                  maxWidth: "100%",
                  position: "relative",
                  objectFit: "contain"
                }}/>
              </Col>
            })}
          </Row>
        </div>

    </>
  }

  return (
    <PageWrapper
      loginListener={(user) => {
        setUser(user)
      }}
      backgroundColor={"#f4f4f4"}
      headerActive={location.pathname === "/en/home" ? 'en-home' : 'home'}
      isBookStoresShown={isBookStoresShown}
      isBookModalShown={isBookModalShown}
      selectedBook={selectedBook}
      hideBookModal={() => setBookModalShown(false)}
    >
      <div style={{
        backgroundColor: "#f4f4f4",
        minHeight: '100vh'
        // background : Palette.PESTA_BONEKA_LINEN
      }}>

        <Modal
          size="sm"
          onHide={() => {
            setIsLanguageInfoOpen(false)
          }}
          show={isEN && isLanguageInfoOpen}
        >
          <Modal.Header closeButton>
            Information
          </Modal.Header>
          <Modal.Body>
            <div>
              This page is only available in Indonesian.
            </div>
          </Modal.Body>

        </Modal>

        <div style={{
          display: "flex",
          flexDirection: "column",
          marginTop: isMobile ? "-3em" : '0em',
        }}
        >

          {renderSudoHeader()}
          {renderBanner()}
          <div style={{
            // height : "5em",
            paddingTop: "1em",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
          >
            <a
              style={{
                marginTop: "0.3em",
              }}
              target="_blank" rel="noopener noreferrer"
              href={"https://play.google.com/store/apps/details?id=com.patjarmerah"}>
              <img
                style={{
                  height: "3em",
                  objectFit: "contain"
                }}
                src={require("../../asset/icon-google-play-alt.svg")}/>
            </a>

            <div style={{width: "0.5em"}}/>

            <a style={{
              marginTop: "0.3em",
            }}
               target="_blank" rel="noopener noreferrer" href={"https://www.instagram.com/patjarmerah_id/"}>
              <img
                style={{
                  height: "3em",
                  objectFit: "contain"
                }}
                src={require("../../asset/logo-instagram.png")}/>
            </a>

            <div style={{width: "0.5em"}}/>

            <a style={{
              marginTop: "0.3em",
            }}
               target="_blank" rel="noopener noreferrer"
               href={"https://open.spotify.com/show/3Jhs8GZVmwN7hp4MU6qjUy"}>
              <img
                style={{
                  height: "3em",
                  objectFit: "contain"
                }}
                src={require("../../asset/patjarpodcast.jpg")}/>
            </a>

          </div>

          <Row style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingInlineStart: isMobile ? "6%" : windowIsLarge ? "12%" : "5%",
            paddingInlineEnd: isMobile ? "6%" : windowIsLarge ? "12%" : "5%",
            marginTop: '15px',
          }}>
            {isBookStoresShown ?
              <Col md={4} xs={6} xl={2} style={{
                display: "flex",
                marginTop: "1em",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}>
                <div style={{
                  boxShadow: "1px 1px 3px 1px #9E9E9E",
                  borderRadius: 5,
                  paddingTop: '10px',
                  paddingBottom: '5px',
                  width: '100%',
                  backgroundColor: 'white'
                }}>
                  <a href={""} onClick={(e) => {
                    e.preventDefault()
                    history.push("/patjar-pilihan")
                  }} style={{
                    textAlign: "center"
                  }}>
                    <Container>
                      <Row style={{display: 'flex', justifyContent: 'center'}}>
                        <img
                          style={{
                            height: "2.5em",
                            objectFit: "contain",
                            marginBottom: '5px',
                            fontWeight: 'bold'
                          }}
                          src={require("../../asset/icc_pasar.png")}/>
                      </Row>
                      <Row style={{
                        fontWeight: '500',
                        fontSize: '0.9em',
                        display: 'flex',
                        justifyContent: 'center',
                        color: 'black'
                      }}>
                        patjarpilihan
                      </Row>
                    </Container>
                  </a>
                </div>

                {/*<a href={""} onClick={(e) => {*/}
                {/*    e.preventDefault()*/}
                {/*    history.push("/patjar-pilihan")*/}
                {/*}} style={{*/}
                {/*    textAlign: "center"*/}
                {/*}}>*/}
                {/*    <img*/}
                {/*        style={{*/}
                {/*            height: "3em",*/}
                {/*            objectFit: "contain"*/}
                {/*        }}*/}
                {/*        src={require("../../asset/icc_pasar.png")}/>*/}
                {/*    <p style={{*/}
                {/*        paddingTop: "0.3em",*/}
                {/*        height: "2em",*/}
                {/*        textAlign: "center",*/}
                {/*        color: "black"*/}
                {/*    }}>Pasar Buku</p>*/}
                {/*</a>*/}
              </Col> : null
            }
            {/* <Col md={4} xs={6} xl={2} style={{
              display: "flex",
              marginTop: "1em",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}>
              <div style={{
                boxShadow: "1px 1px 3px 1px #9E9E9E",
                borderRadius: 5,
                paddingTop: '10px',
                paddingBottom: '5px',
                width: '100%',
                backgroundColor: 'white'
              }}>
                <a href={""} onClick={(e) => {
                  e.preventDefault()
                  history.push("/patjarkita")
                }} style={{
                  textAlign: "center"
                }}>
                  <Container>
                    <Row style={{display: 'flex', justifyContent: 'center'}}>
                      <img
                        style={{
                          height: "2.5em",
                          objectFit: "contain",
                          marginBottom: '5px',
                          fontWeight: 'bold'
                        }}
                        src={require("../../asset/icc_festival.png")}/>
                    </Row>
                    <Row style={{
                      fontWeight: '500',
                      fontSize: '0.9em',
                      display: 'flex',
                      justifyContent: 'center',
                      color: 'black'
                    }}>
                      patjarkita
                    </Row>
                  </Container>
                </a>
              </div> */}

              {/*<a href={""} onClick={(e) => {*/}
              {/*    e.preventDefault()*/}
              {/*    history.push("/festival-literasi")*/}
              {/*}} style={{*/}
              {/*    textAlign: "center"*/}
              {/*}}>*/}
              {/*    <img*/}
              {/*        style={{*/}
              {/*            height: "3em",*/}
              {/*            objectFit: "contain"*/}
              {/*        }}*/}
              {/*        src={require("../../asset/icc_festival.png")}/>*/}
              {/*    <p style={{*/}
              {/*        paddingTop: "0.3em",*/}
              {/*        textAlign: "center",*/}
              {/*        height: "2em",*/}
              {/*        color: "black"*/}
              {/*    }}>Festival Literasi</p>*/}
              {/*</a>*/}

            {/* </Col> */}

            {/*<Col md={4} xs={6} xl={2} style={{*/}
            {/*    display: "flex",*/}
            {/*    marginTop: "1em",*/}
            {/*    flexDirection: "column",*/}
            {/*    alignItems: "center",*/}
            {/*    justifyContent: "center"*/}
            {/*}}>*/}
            {/*    <div style={{*/}
            {/*        boxShadow: "1px 1px 3px 1px #9E9E9E",*/}
            {/*        borderRadius: 5,*/}
            {/*        paddingTop: '10px',*/}
            {/*        paddingBottom: '5px',*/}
            {/*        width: '100%'*/}
            {/*    }}>*/}
            {/*        <a href={"#kawanpatjar"} onClick={(e) => {*/}

            {/*        }} style={{*/}
            {/*            textAlign: "center"*/}
            {/*        }}>*/}
            {/*            <Container>*/}
            {/*                <Row style={{display: 'flex', justifyContent: 'center'}}>*/}
            {/*                    <img*/}
            {/*                        style={{*/}
            {/*                            height: "2.5em",*/}
            {/*                            objectFit: "contain",*/}
            {/*                            marginBottom: '5px',*/}
            {/*                            fontWeight: 'bold'*/}
            {/*                        }}*/}
            {/*                        src={require("../../asset/icc_kawanpatjar.png")}/>*/}
            {/*                </Row>*/}
            {/*                <Row style={{*/}
            {/*                    fontWeight: '500',*/}
            {/*                    fontSize: '0.9em',*/}
            {/*                    display: 'flex',*/}
            {/*                    justifyContent: 'center',*/}
            {/*                    color: 'black'*/}
            {/*                }}>*/}
            {/*                    Kawan Patjar*/}
            {/*                </Row>*/}
            {/*            </Container>*/}
            {/*        </a>*/}
            {/*    </div>*/}
            {/*</Col>*/}

            <Col md={4} xs={6} xl={2} style={{
              display: "flex",
              marginTop: "1em",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}>
              <div style={{
                boxShadow: "1px 1px 3px 1px #9E9E9E",
                borderRadius: 5,
                paddingTop: '10px',
                paddingBottom: '5px',
                width: '100%',
                backgroundColor: 'white'
              }}>
                <a href={""} onClick={(e) => {
                  e.preventDefault()
                  history.push("/kata-patjar")
                }} style={{
                  textAlign: "center"
                }}>
                  <Container>
                    <Row style={{display: 'flex', justifyContent: 'center'}}>
                      <img
                        style={{
                          height: "2.5em",
                          objectFit: "contain",
                          marginBottom: '5px',
                          fontWeight: 'bold'
                        }}
                        src={require("../../asset/icc_katapatjar.png")}/>
                    </Row>
                    <Row style={{
                      fontWeight: '500',
                      fontSize: '0.9em',
                      display: 'flex',
                      justifyContent: 'center',
                      color: 'black'
                    }}>
                      Kata Patjar
                    </Row>
                  </Container>
                </a>
              </div>
            </Col>
          </Row>

          <div style={{
            height: "2em"
          }}/>

        </div>

        {/* {renderSponsors()} */}

        <LoginModal
          show={loginShow && !isLoggedIn}
          onHide={(token) => {
            setLoginShow(false)
            if (GlobalData.token) {
              setIsLoggedIn(true)
              setCookie("token", token, {path: "/", maxAge: 60 * 60 * 24 * 30})
            }
          }}
        />
      </div>
    </PageWrapper>

  );

}
