import Palette from "../../util/Palette";
import Col from "react-bootstrap/Col";
import React, {useState} from "react";

export default function PaketCard(props) {

    const [isHovered, setIsHovered] = useState(false)

    return <Col
        xs={12} md={6} style={{paddingTop: 15, paddingBottom: 10}}
        onClick={() => window.open(props.url, "_blank")}
    >
        <div style={{
            cursor: 'pointer',
            boxShadow: isHovered ? '0px 0px 10px 0px rgba(194,194,194,1)' : '0px 0px 3px 0px rgba(194,194,194,1)',
            borderRadius: 5,
            height: '100%'
        }}
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)}>
            <div style={{overflow: 'hidden'}}>
                <img src={props.src} style={{
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                    width: '100%',
                    transform: isHovered ? 'scale(1.25)' : 'none'
                }}/>
            </div>

            <div style={{
                fontFamily: 'Signika-Semibold',
                fontSize: '1.1em',
                paddingTop: 5,
                paddingLeft: 20
            }}>
                {props.name}
            </div>

            <div style={{
                fontFamily: 'Signika-Regular',
                paddingTop: 5,
                paddingLeft: 20,
                color: Palette.MAROON
            }}>
                {props.price}
            </div>

            <div style={{height: 1, backgroundColor: '#e3e3e3', marginTop: 5}}/>

            {
                props.content
            }


        </div>
    </Col>

}
