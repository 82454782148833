import React, {useEffect, useRef, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageWrapper from "./PageWrapper";
import {Button} from "react-bootstrap";
import {isMobile} from "react-device-detect";
import {AiOutlineCalendar, AiOutlineWarning} from "react-icons/ai";
import Palette from "../../util/Palette";
import {MdError, MdLocationOn} from "react-icons/md";
import {TiLocationOutline, TiTicket} from "react-icons/ti";
import {useCookies} from "react-cookie";
import Session from "../../models/Session";
import PapermoonTicketNode from "../reusable/PapermoonTicketNode";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import moment from "moment";
import ScheduleNode from "../reusable/ScheduleNode";
import GlobalData from "../../util/GlobalData";
import _ from "lodash";
import SessionModal from "../reusable/SessionModal";
import {useHistory} from 'react-router-dom'

let firstLoad = true

export default function GincuPage(props) {

    let [isExpanded, setIsExpanded] = useState(false)

    return (
        <PageWrapper
            loginShow={false}
            message={""}
            // customOnHide={isLoginFormShown ? () => setIsLoginFormShown(false) : null}
            headerActive={'gincu'}
        >
            <Container fluid style={{backgroundColor: "#FFFFFF"}}>
                <Row style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: "center",
                    backgroundColor: "#FFFFFF"
                }}>
                    <Col xl={10}
                         style={{display: 'flex', justifyContent: 'center', alignContent: "center", marginTop: 50}}>
                        <img src={require("../../asset/Header_Gincu.jpg")}
                             style={{width: '100%', maxWidth: 920, height: "100%"}}/>
                    </Col>
                </Row>

                <Container style={{paddingTop: '1em', paddingBottom: '1em', backgroundColor: "#FFFFFFD0"}}>
                    <Row style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 30,
                        marginBottom: 25,
                        textAlign: 'justify'
                    }}>
                        <Col xl={10}>
                            <p>
                                <b>TAMURA TOSHIKO</b> (1884-1945) adalah pengarang perempuan Jepang yang berkarya pada
                                akhir
                                zaman Meiji (1868-1912), Taisho (1912-1926), dan Showa sebelum perang (1926-1945).
                                Karier Tamura Toshiko dimulai saat ia menjadi pemenang penghargaan sastra yang
                                diselenggarakan Osaka Asahi Shinbun (Koran Asahi Osaka) pada 1911 lewat karya novelnya
                                yang berjudul Akirame (terjemahan harafiah: Menyerah). Lewat karya-karyanya, Tamura
                                Toshiko mempertanyakan, menggoncang, dan menggugat norma patriarki yang mengungkung
                                perempuan Jepang. Khususnya pada masa awal kepenulisannya, Tamura Toshiko banyak
                                mengangkat isu perempuan di dunia seni, seperti yang tergambar pada beberapa karyanya,
                                yaitu Akirame (1911), Onna Sakusha (terjemahan harafiah: Pengarang Perempuan, 1913),
                                Miira no Kuchibeni (Gincu Sang Mumi, 1914).
                            </p>
                            {
                                isExpanded ? <>
                                        <p>
                                            Membicarakan Tamura Toshiko tidak dapat kita lepaskan dari dinamika masyarakat
                                            pada masa ia hidup dan menuliskan karya-karyanya, yaitu zaman Meiji
                                            akhir-Taisho-Showa
                                            sebelum perang. Zaman Meiji akhir sampai Taisho awal adalah masa berkembangnya
                                            norma
                                            ryoosai kenbo (istri yang baik, ibu yang bijaksana) yang menjadi norma perempuan
                                            ideal
                                            Jepang. Pengukuhan norma perempuan ideal di Jepang tersebut didukung dengan
                                            arahan
                                            pemerintah pada masa itu untuk mendirikan dan mengembangkan sekolah-sekolah
                                            khusus
                                            perempuan yang mendidik perempuan untuk menjadi istri yang baik dan ibu yang
                                            bijaksana dan
                                            terbitnya majalah-majalah khusus perempuan yang berfungsi sebagai sumber
                                            pendidikan
                                            informal bagi perempuan Jepang (Saito 2003).
                                        </p>
                                        <p>
                                            Di sisi lain, mulai tahun 1910-an, wacana atarashii onna (terjemahan harafiah:
                                            perempuan baru) mulai menjadi perbincangan dan dipopulerkan oleh berbagai media
                                            Jepang.
                                            Atarashii onna mengacu pada perempuan yang memilih hidup dengan caranya sendiri
                                            dan
                                            menolak diikat oleh norma ryoosai kenbo. Istilah ini menunjukkan kekhawatiran
                                            sekaligus harapan
                                            terhadap perubahan status perempuan Jepang di dalam masyarakat (Yoshio 2014).
                                            Ada beberapa peristiwa penting yang mendorong lahirnya wacana atarashii onna di
                                            Jepang, yaitu terbitnya majalah sastra feminis pertama di Jepang yang berjudul
                                            Seitoo
                                            (arti: bluestockings, 1911-1916), dipentaskannya karya teater Henrik Ibsen
                                            berjudul A
                                            Doll’s House (diterjemahkan ke dalam bahasa Jepang menjadi Ningyoo no Ie) pada
                                            1911—yang berkisah tentang tokoh utama Nora yang pada awal lakon digambarkan
                                            menjalani
                                            peran ideal sebagai istri dan ibu dari kelas menengah, tetapi ia sadar bahwa
                                            dirinya tidak
                                            bahagia dalam pernikahannya dan di akhir cerita ia keluar dari rumah
                                            meninggalkan
                                            suaminya—dan terbitnya cerita bersambung di majalah Shirakaba yang berjudul Aru
                                            Onna
                                            (terjemahan harafiah: Seorang Perempuan) karya Arishima Takeo, yang
                                            menggambarkan perempuan
                                            pemberontak yang melawan norma secara terang-terangan (Yoshio 2014). Di luar
                                            Jepang, tepatnya di Amerika Serikat dan Eropa, akhir abad 19 sampai awal abad 20
                                            ditandai dengan gerakan feminisme gelombang pertama (Yoshio 2014). Berbagai
                                            peristiwa di dalam
                                            dan di luar Jepang ini berkelindan menumbuhkan sebuah pemikiran bahwa tempat
                                            perempuan
                                            bukan hanya di ranah domestik saja; ada gaya hidup di luar ryoosai kenbo, yaitu
                                            menjadi atarashii onna.
                                        </p>
                                        <p>
                                            Sebagai perempuan pengarang yang memulai kariernya pada awal 1910-an, Tamura
                                            Toshiko menjadi figur dari atarashii onna. Pada 1918, Tamura Toshiko pindah ke
                                            Vancouver, Kanada mengikuti kekasihnya, setelah bercerai dengan suaminya. Ia
                                            kembali ke Jepang
                                            pada 1936, tetapi pindah ke Shanghai pada 1942 hingga akhir hayatnya. Dari
                                            luasnya ruang
                                            gerak— pertama-tama di Jepang, lalu pindah ke Kanada, kembali lagi ke Jepang,
                                            kemudian
                                            pindah ke Shanghai—dan gaya hidupnya yang menentang norma ryoosai kenbo, kita
                                            dapat
                                            menyimpulkan bahwa kehidupan Tamura Toshiko sendiri adalah resistensi terhadap
                                            norma-norma patriarki pada zamannya.
                                        </p>
                                        <p>
                                            Karya-karya awal Tamura Toshiko, termasuk Gincu Sang Mumi yang akan diterbitkan
                                            penerbit
                                            Mai, mengangkat isu perempuan di dunia seni. Dunia seni bukanlah dunia yang
                                            netral dan
                                            hanya berpusat pada keindahan saja, tetapi dunia seni adalah sebuah ruang yang
                                            merefleksikan posisi sosial dan kekuasaan (Korsmeyer 2004). Praktik kekuasaan di
                                            dalam
                                            dunia seni juga terlihat pada dunia sastra di Jepang. Dalam dunia sastra awal
                                            abad 20,
                                            umumnya perempuan tidak dapat mengukuhkan posisinya sebagai pengarang jika tidak
                                            ada
                                            figur laki-laki yang kuat di belakangnya (Copeland dalam Shirane 2016). Tamura
                                            Toshiko
                                            pun dapat masuk ke dalam dunia sastra karena ia adalah murid Koda Rohan, seorang
                                            sastrawan yang sudah memiliki nama. Salah satu juri untuk karya debutnya,
                                            Akirame, yang
                                            memenangkan penghargaan sastra Osaka Asahi Shinbun pun adalah Koda Rohan.
                                            Praktik
                                            dukungan laki-laki di dunia sastra adalah hal yang wajar terjadi pada masa itu
                                            di
                                            Jepang. Pada masa itu, pengarang perempuan dikelompokkan dalam satu kelompok
                                            tersendiri
                                            yang disebut “joryuu sakka” (pengarang perempuan) dan karya-karya perempuan
                                            dinilai dari
                                            perspektif laki-laki. Laki-laki berperan sebagai kritikus sastra bagi
                                            karya-karya yang
                                            dihasilkan perempuan, menunjukkan bahwa dalam dunia sastra, otoritas dipegang
                                            oleh
                                            laki-laki.
                                        </p>
                                        <p>
                                            Maka, tidak heran jika Tamura Toshiko mempersoalkan posisi perempuan di dunia
                                            seni,
                                            tepatnya dalam dunia sastra, melalui karya-karya awalnya. Tamura Toshiko
                                            mempertanyakan
                                            seberapa jauh otoritas laki-laki dalam proses kreatif pengarang perempuan,
                                            mengungkap
                                            berbagai kendala yang dihadapi perempuan ketika ia mencoba bertahan di dunia
                                            sastra,
                                            mengkritisi perspektif laki-laki dalam menilai pengarang perempuan. Dunia seni
                                            pada masa
                                            itu di Jepang, seiring dengan dunia pemerintahan, keluarga, pendidikan, hukum,
                                            adalah
                                            dunia yang penuh dengan layer patriarki, tempat perempuan berada di pinggiran
                                            sementara
                                            laki-laki menjadi pusatnya.
                                        </p>
                                        <p>
                                            Karya-karya Tamura Toshiko ditulis lebih dari 100 tahun lalu di dalam konteks
                                            masyarakat
                                            Jepang, tetapi isu perempuan yang terpinggirkan di dalam masyarakat yang
                                            dikuasai norma
                                            patriarki masih tetap relevan dibahas saat ini. Melalui pembacaan terhadap karya
                                            Tamura
                                            Toshiko, kita dapat merefleksikan berbagai isu yang diangkat dengan kondisi kita
                                            sendiri
                                            di tengah masyarakat Indonesia. Apakah dunia ini sudah cukup adil untuk
                                            perempuan?
                                            Bagaimana posisi perempuan di dunia seni saat ini? Apakah perempuan yang
                                            berkarya di
                                            bidang seni memperoleh pengakuan yang setara dengan laki-laki? Mengapa sulit
                                            bagi
                                            perempuan untuk mengembangkan dirinya semaksimal mungkin? Apa saja
                                            tuntutan-tuntutan
                                            masyarakat yang dibebankan pada perempuan? Pertanyaan-pertanyaan ini dapat
                                            menjadi
                                            pemantik untuk kita mendiskusikan karya-karya Tamura Toshiko.
                                        </p>
                                        <p style={{textAlign: "right"}}>
                                            <b>Rouli Esther Pasaribu</b>
                                        </p>
                                        <p style={{textAlign: "right", fontSize: 13, marginTop: -15}}>
                                            Dosen dan peneliti kajian gender, sastra,
                                        </p>
                                        <p style={{textAlign: "right", fontSize: 13, marginTop: -15}}>
                                            dan budaya populer Jepang dan
                                            Indonesia
                                        </p>
                                        <p>
                                            <b>Daftar Referensi</b>
                                        </p>
                                        <p>
                                            Copeland, R. (2016). The rise of modern women’s literature. In H.Shirane, T.
                                            Suzuki, D.
                                            Lurie (Eds.), The cambridge history of Japanese literature (pp.598-604).
                                            Cambridge
                                            University Press.
                                        </p>
                                        <p>
                                            Korsmeyer, C. (2004). Gender and Aesthetics: An Introduction. Routledge.
                                        </p>
                                        <p>
                                            Saito, M. (2003). Modan Gaaru Ron. Bungeishunju.
                                        </p>
                                        <p>
                                            Yoshio, H. (2014). Performing the woman writer: literature, media, and gender
                                            politics
                                            in Tamura Toshiko’s Akirame and “Onna Sakusha”. Japanese Language and Literature
                                            48 (2),
                                            205-236. https://www.jstor.org/stable/24394405
                                        </p>
                                        <div
                                            onClick={() => {
                                                setIsExpanded(false)
                                            }}
                                            style={{
                                                color: Palette.MAROON,
                                                cursor: "pointer"
                                            }}>
                                            Read Less...
                                        </div>
                                    </>
                                    :
                                    <div>
                                        <p>
                                            Membicarakan Tamura Toshiko tidak dapat kita lepaskan dari dinamika
                                            masyarakat pada masa ia hidup dan menuliskan karya-karyanya, yaitu zaman
                                            Meiji akhir-Taisho-Showa sebelum perang. Zaman Meiji akhir sampai Taisho awal
                                            adalah masa berkembang <text
                                            onClick={() => {
                                                setIsExpanded(true)
                                            }}
                                            style={{
                                                color: Palette.MAROON,
                                                cursor: "pointer"
                                            }}>
                                            <b>Read More...</b>
                                        </text>
                                        </p>
                                    </div>
                            }


                        </Col>
                    </Row>
                </Container>
            </Container>
        </PageWrapper>
    )
}
