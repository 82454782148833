import ReactCardFlip from "react-card-flip";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import React, {Component} from "react";
import {Button} from "react-bootstrap";
import moment from "moment";
import 'moment/locale/en-gb'
import 'moment/locale/id'


export default class BilingualScheduleNode extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFlipped: false
        }
    }

    render() {

        let withFontSize = "1em"
        if(this.props.speaker){
            if(this.props.speaker.length > 160){
                withFontSize = "0.85em"
            }
        }

        let isEN = this.props.isEN || false

        return <Col
            xl={this.props.xl ? this.props.xl : 4}
            md={this.props.md ? this.props.md : 6}
            xs={this.props.xs ? this.props.xs : 12}
            style={{
                ...this.props.style,
                marginBottom: 30
            }}
        >
            <div
                onMouseEnter={() => this.setState({
                    isFlipped: true
                })}
                onMouseLeave={() => this.setState({
                    isFlipped: false
                })}
            >
                <ReactCardFlip
                    flipSpeedFrontToBack={0.2}
                    flipSpeedBackToFront={0.2}
                    isFlipped={this.state.isFlipped} flipDirection={"vertical"}>
                    <Card
                        style={{
                            width: "100%",
                            paddingTop: "100%",
                            backgroundSize: "contain",
                            borderRadius: 10
                        }}
                    >
                        <img
                            src={this.props.image}
                            style={{
                                position: "absolute",
                                objectFit : "cover",
                                height: "100%",
                                width: "100%",
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                borderRadius: 10
                            }}
                        />
                        {this.props.session.status === 'WAITING' || this.props.session.status === 'PENDING' || this.props.session.status === 'REJECTED'?
                            <div style={{
                                backgroundColor: this.props.session.status === 'WAITING' || this.props.session.status === 'PENDING' ? 'yellow' : "red",
                                color: this.props.session.status === 'WAITING' || this.props.session.status === 'PENDING' ? 'black' : 'white',
                                padding: 3,
                                fontSize: '0.75em',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                borderBottomLeftRadius: 2,
                            }}>{this.props.session.status === 'WAITING' && this.props.session.online_payment ? isEN ? "Waiting For Payment" : 'Menunggu Pembayaran' : this.props.session.status === 'WAITING' && !this.props.session.online_payment ? isEN ? 'Waiting Confirmation' : 'Menunggu Konfirmasi' : this.props.session.status === 'PENDING' ? isEN ? 'In Queue' : 'Dalam antrean' : isEN ? "Rejected" : "Ditolak"}</div>
                            : this.props.session.status === 'ACCEPTED' ? <div style={{
                                backgroundColor: 'green',
                                color: 'white',
                                padding: 3,
                                fontSize: '0.7em',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                borderBottomLeftRadius: 2
                            }}>{isEN ? "Registered" : "Terdaftar"}</div> : null}
                    </Card>
                    <Card
                        style={{
                            width: "100%",
                            paddingTop: "100%",
                            backgroundSize: "contain",
                            borderRadius: 10
                        }}
                    >
                        <div style={{
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div style={{margin: '15px'}}>

                                <h5 style={{
                                    fontSize: "1em"
                                }}>{this.props.title}</h5>

                                <span style={{fontSize: "0.7em", fontWeight: 'bold'}}>{isEN ? "With" : "Bersama"}</span>
                                <h6 style={{
                                    fontSize: withFontSize
                                }}>{this.props.speaker}</h6>

                                <span style={{fontSize: "0.7em", fontWeight: 'bold'}}>{isEN ? "Date & Time" : "Tanggal & Waktu"}</span>
                                <p style={{
                                    fontSize: "1em"
                                }}>
                                    {this.props.timeStart.locale(!isEN ? 'id' : "en-gb").utcOffset('+0700').format("dddd, DD MMMM YYYY")}<br/>
                                    {this.props.timeStart.locale(!isEN ? 'id' : "en-gb").utcOffset('+0700').format("HH.mm")} - {this.props.timeEnd.locale(!isEN ? 'id' : "en-gb").utcOffset('+0700').format("HH.mm")} (GMT+7)
                                </p>
                            </div>

                            <div style={{ position: 'absolute', bottom: 0, width: '90%', marginLeft: '5%' }}>
                                {(this.props.session.status === 'WAITING' && this.props.session.online_payment) || this.props.session.status === 'PENDING' ?
                                    <div style={{
                                        backgroundColor: '#fffeec',
                                        color: 'black',
                                        fontSize: '0.7em',
                                        border: '1px solid #ebd98b',
                                        borderRadius: 6,
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        textAlign: 'center',
                                        marginBottom: '10px'
                                    }}>
                                        {this.props.session.status === 'WAITING' && this.props.session.online_payment ? 'Bayar sebelum ' + moment(this.props.session.registration_info.modified_at).add(24,'hours').format('DD MMM YYYY, HH.mm') : 'Kuota habis namun Anda akan dikabarkan apabila sudah tersedia'}
                                    </div> : null}

                                <Button
                                    onClick={() => this.props.onClick(this.props.session)}
                                    size={"sm"} block variant={"outline-danger"} style={{
                                    width: '100%',
                                    marginBottom: '15px'
                                }}>
                                    {isEN ? "More" : "Selengkapnya"}
                                </Button>
                            </div>
                        </div>
                    </Card>
                </ReactCardFlip>
                <div style={{
                    height: 5
                }}/>
            </div>

        </Col>
    }
}
