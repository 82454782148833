import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import PageWrapper from "./PageWrapper";
import GlobalData from "../../util/GlobalData";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Palette from "../../util/Palette";
import {isMobile} from "react-device-detect";
import Card from "react-bootstrap/Card";
import Book from "../../models/Book"
import textFormatter from "../../util/textFormatter";
import ApplicationActivities from "../../models/ApplicationActivity";
import {Modal} from "react-bootstrap";
import {useCookies} from "react-cookie";
import BookModal from "../reusable/BookModal";

const stores = [
    // {
    //     url: "https://shopee.co.id/republikfiksi",
    //     name: "Republik Fiksi",
    //     marketplace: "Shopee",
    //     image: require("../../asset/logo-shopee.jpg")
    // },
    // {
    //     url: "https://www.tokopedia.com/republikfiksi",
    //     name: "Republik Fiksi",
    //     marketplace: "Tokopedia",
    //     image: require("../../asset/logo-tokped.png")
    // },
    // {
    //     url: "https://shopee.co.id/bukuindie",
    //     name: "Toko Budi",
    //     marketplace: "Shopee",
    //     image: require("../../asset/logo-shopee.jpg")
    // },
    {
        url: "https://shopee.co.id/patjarmerah",
        name: "patjarmerah",
        marketplace: "Shopee",
        image: require("../../asset/logo-shopee.jpg")
    },
    // {
    //     url: "https://www.bukuindie.com/",
    //     name: "Toko Budi",
    //     marketplace: "Website",
    //     image: GlobalData.subfolder + "image/logo/tokobudi.jpg"
    // },
    // {
    //     url: "https://play.google.com/store/search?q=%22penerbit%20banana%22&c=books&PAffiliateID=1100lIxb",
    //     name: "Google Play Books",
    //     marketplace: "E Book",
    //     image: GlobalData.subfolder + "image/logo/google-play-books.png"
    // }
]

let initial_book;

export default function BookStore(props) {
	const history = useHistory();
	const {id:book_id} = useParams();

    const [highlight, setHighlight] = useState([]);
    const [specials, setSpecials] = useState([]);
    let [windowIsLarge, setWindowIsLarge] = useState(window.innerWidth > 1199)
    const [selectedBook, setSelectedBook] = useState({additional_information: {}});
    const [isBookModalShown, setBookModalShown] = useState(false);
    const [isLanguageInfoOpen, setIsLanguageInfoOpen] = useState(true);
    const [cookie, setCookie, removeCookie] = useCookies()

    let isEN = cookie.lang !== "id"
    console.log("is en ? " + isEN)

    console.log("is window large ? " + windowIsLarge)

    useEffect(() => {
        fetchBooks()
        ApplicationActivities.sendActivity({
            type: "BOOKLIST",
            description: "",
            timestamp: new Date(),
            os: isMobile ? "Mobile Webiste" : "Website"
        })

        // alert(initial_book)

    }, [])

    async function fetchBooks() {



        try {
            let books = await new Book().getAll();
            console.log("books", books)

            let temporaryHighlight = []
            let temporarySpecial = []



            for(let b of books){
                if(b.category === "Istimewa"){
                    temporarySpecial.push(b)
                }else{
                    temporaryHighlight.push(b)
                }

				if(b.id == book_id)
				{
					initial_book = b;
				}
            }

            console.log(temporarySpecial)
            console.log(temporaryHighlight)

            setHighlight(temporaryHighlight)
            setSpecials(temporarySpecial)


        } catch (e) {
            console.log(e)
        }

        if(initial_book)
        {
            setBookModalShown(true);
            setSelectedBook(initial_book)
        }
    }

    return (
        <PageWrapper
            headerShy={false}
            headerActive={'book-store'}
            isBookModalShown={isBookModalShown}
            selectedBook={selectedBook}
            hideBookModal={() => {
                setBookModalShown(false)
                history.push("/patjar-pilihan")
            }}>

            <Container style={{marginBottom: '60px'}} fluid>

                <Modal
                    size="sm"
                    onHide={()=>{
                        setIsLanguageInfoOpen(false)
                    }}
                    show={isEN && isLanguageInfoOpen}
                >
                    <Modal.Header closeButton>
                        Information
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            This page is only available in Indonesian.
                        </div>
                    </Modal.Body>

                </Modal>

                <Row style={{
                    paddingInlineStart: isMobile ? "2%" : windowIsLarge ? "12%" : "5%",
                    paddingInlineEnd: isMobile ? "2%" : windowIsLarge ? "12%" : "5%",
                    marginTop: '30px'
                }}>
                    <Col
                        md={{offset: 0, span: 10}}
                        xl={{offset: 0, span: 10}}>
                        <h4 style={{width: "100%", textAlign: isMobile ? "center" : "left"}}>
                            Pasar Buku Virtual
                        </h4>
                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: isMobile ? "2%" : windowIsLarge ? "12%" : "5%",
                    paddingInlineEnd: isMobile ? "2%" : windowIsLarge ? "12%" : "5%",
                    alignItems: "flex-start",
                    justifyContent: "flex-start"
                }} noGutters={isMobile}>
                    {
                        stores.map((store, key) => {
                            return <Col
                                style={{
                                    marginBottom: "3px",
                                    padding: "2px",

                                }} md={3}>
                                <a target={"_blank"}  rel="noopener noreferrer"
                                   href={store.url}>

                                    <Card>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            color: "black"
                                            // justifyContent : "center"
                                        }}>
                                            <img
                                                style={{
                                                    width: "4em",
                                                    height: "4em",
                                                    objectFit: "contain"
                                                }}
                                                src={store.image}/>

                                            <div style={{width: "0.2em"}}/>

                                            <div style={{
                                                flex: 1,
                                                display: "flex",
                                                flexDirection: "column"
                                            }}>
                                                <div style={{
                                                    fontSize: "0.7em"
                                                }}>
                                                    {store.marketplace}<br/>
                                                </div>

                                                <div style={{
                                                    fontSize: "0.9em"
                                                }}>
                                                    <b>{store.name}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </a>
                            </Col>
                        })
                    }
                </Row>

                {/*}

                <Row style={{
                    paddingInlineStart: isMobile ? "2%" : windowIsLarge ? "12%" : "5%",
                    paddingInlineEnd: isMobile ? "2%" : windowIsLarge ? "12%" : "5%",
                    marginTop: '30px'
                }}>

                    <Col
                        md={{offset: 0, span: 10}}
                        xl={{offset: 0, span: 10}}>
                        <h4 style={{width: "100%", textAlign: isMobile ? "center" : "left"}}>
                            Patjar Istimewa
                        </h4>
                    </Col>
                </Row>

                <Row style={{
                    paddingTop: 10,
                    paddingInlineStart: isMobile ? "2%" : windowIsLarge ? "12%" : "5%",
                    paddingInlineEnd: isMobile ? "2%" : windowIsLarge ? "12%" : "5%",
                }} noGutters={isMobile}
                >
                    {
                        specials.map((book, key) => {
                            let info = book.additional_information
                            return <Col
                                style={{
                                    paddingInlineStart: isMobile && key % 2 === 1 ? "0.15em" : "0.3em",
                                    paddingInlineEnd: isMobile && key % 2 !== 1 ? "0.15em" : "0.3em"
                                }}
                                xs={6}
                                md={{span: 2}}
                                // xl={{span: 3}}
                                // md={{span: 3}}
                            >
                                <a href={'#'}
                                   onClick={() => {
                                       console.log(book)
                                       setSelectedBook(book)
                                       setBookModalShown(true);
                                   }}>

                                    <Card style={{width: '100%'}}>

                                        <div style={{
                                            width : "100%",
                                            paddingTop : "100%",
                                            backgroundImage : `url(${info.image})`,
                                            backgroundSize : "contain",
                                            backgroundPosition : "center",
                                            backgroundRepeat : "no-repeat",
                                            backgroundColor : "white"
                                        }}/>

                                        <Card.Body style={{
                                            height: isMobile || !windowIsLarge ? "8em" : "8rem",
                                            color: "black",
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: "0.5rem"
                                        }}>
                                            <b style={{
                                                fontSize: isMobile || !windowIsLarge ? "0.75rem" : "0.875rem",
                                                height: "2.5rem"
                                            }}>
                                                {book.title}
                                            </b>

                                            <p style={{
                                                fontSize: isMobile || !windowIsLarge ? "0.65rem" : "0.875rem",
                                                lineClamp: 2,
                                            }}>{info.author} {isMobile || !info.author || !info.publisher ? "" : "•"} {isMobile ? "" : info.publisher}</p>
                                            <b style={{
                                                fontSize: isMobile || !windowIsLarge ? "0.8rem" : "0.8rem"
                                            }}>
                                                Rp{textFormatter.moneyFormatter(info.price)}
                                            </b>
                                        </Card.Body>
                                    </Card>
                                </a>
                                <div style={{
                                    height: 10
                                }}/>
                            </Col>
                        })
                    }
                </Row>
                {*/}

                <Row style={{
                    paddingInlineStart: isMobile ? "2%" : windowIsLarge ? "12%" : "5%",
                    paddingInlineEnd: isMobile ? "2%" : windowIsLarge ? "12%" : "5%",
                    marginTop: '30px'
                }}
                >

                    <Col
                        md={{offset: 0, span: 10}}
                        xl={{offset: 0, span: 10}}>
                        <h4 style={{width: "100%", textAlign: isMobile ? "center" : "left"}}>
                            Buku Pilihan
                        </h4>
                    </Col>
                </Row>

                <Row style={{
                    paddingTop: 10,
                    paddingInlineStart: isMobile ? "2%" : windowIsLarge ? "12%" : "5%",
                    paddingInlineEnd: isMobile ? "2%" : windowIsLarge ? "12%" : "5%",
                }} noGutters={isMobile}
                >
                    {
                        highlight.map((book, key) => {
                            let info = book.additional_information
                            return <Col
                                style={{
                                    paddingInlineStart: isMobile && key % 2 === 1 ? "0.15em" : "0.3em",
                                    paddingInlineEnd: isMobile && key % 2 !== 1 ? "0.15em" : "0.3em"
                                }}
                                xs={6}
                                md={{span: 2}}
                                // xl={{span: 3}}
                                // md={{span: 3}}
                            >
                                <a href={"javascript:void(0);"}
                                   onClick={() => {
                                       console.log(book)
                                       setSelectedBook(book)
                                       setBookModalShown(true);
									   history.push("/patjar-pilihan/" + book.id);
                                   }}>

                                    <Card style={{width: '100%'}}>

                                        <div style={{
                                            width : "100%",
                                            paddingTop : "100%",
                                            backgroundImage : `url(${info.image})`,
                                            backgroundSize : "contain",
                                            backgroundPosition : "center",
                                            backgroundRepeat : "no-repeat",
                                            backgroundColor : "white"
                                        }}/>

                                        {/*<Card.Img*/}
                                        {/*    style={{*/}
                                        {/*        width: "100%",*/}
                                        {/*        height: isMobile ? "15em" : windowIsLarge ? "30em" : "20em",*/}
                                        {/*        objectFit: "cover"*/}
                                        {/*    }}*/}
                                        {/*    variant="top" src={info.image}/>*/}
                                        <Card.Body style={{
                                            height: isMobile || !windowIsLarge ? "8em" : "8rem",
                                            color: "black",
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: "0.5rem"
                                        }}>
                                            <b style={{
                                                fontSize: isMobile || !windowIsLarge ? "0.75rem" : "0.875rem",
                                                height: "2.5rem"
                                            }}>
                                                {book.title}
                                            </b>

                                            <p style={{
                                                fontSize: isMobile || !windowIsLarge ? "0.65rem" : "0.875rem",
                                                lineClamp: 2,
                                            }}>{info.author} {isMobile || !info.author || !info.publisher ? "" : "•"} {isMobile ? "" : info.publisher}</p>

                                            <b style={{
                                                fontSize: isMobile || !windowIsLarge ? "0.8rem" : "0.8rem"
                                            }}>
                                                Rp{textFormatter.moneyFormatter(info.price)}
                                            </b>
                                        </Card.Body>
                                    </Card>
                                </a>
                                <div style={{
                                    height: 10
                                }}/>
                            </Col>
                        })
                    }
                </Row>


            </Container>
        </PageWrapper>
    )

}
