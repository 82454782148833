import ApiRequest from "../util/ApiRequest";
import GlobalData from "../util/GlobalData";

export default class Book {

    getAll = async () => {

        let events = await ApiRequest.set("/events/25/items", "GET");

        return events

    }

}
