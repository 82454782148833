import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageWrapper from "./PageWrapper";
import { Button } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { AiOutlineCalendar, AiOutlineWarning } from "react-icons/ai";
import Palette from "../../util/Palette";
import { MdError, MdLocationOn } from "react-icons/md";
import { TiLocationOutline, TiTicket } from "react-icons/ti";
import { useCookies } from "react-cookie";
import Session from "../../models/Session";
import KonserDongengTicketNode from "../reusable/KonserDongengTicketNode";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import KonserDongengGambar from "../../asset/konser-dongeng/konserdongengbanner.jpeg";
import moment from "moment";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaInfoCircle } from "react-icons/fa";
import Banner from "../../asset/2024/radityadika/radit.jpeg"

export default function RadityaDika(props) {
    const [isLoginFormShown, showLoginForm] = useState(false);
    const buyTicketRef = useRef(null);
    const [cookie, setCookie, removeCookie] = useCookies()
    const isEN = false
    const [papermoonSesssions, setSessions] = useState([]);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    const [isToCModalOpen, setIsToCModalOpen] = useState(false)
    const [isPPModalOpen, setIsPPModalOpen] = useState(false)

    function handleResize() {
        console.log('width: ' + window.innerWidth)

        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
        })
    }

    useEffect(() => {
        new Session().getRadityaDikaSessions().then(res => {
            console.log(res)
            setSessions(res);
        }).catch(err => console.log(err))

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }

        // ApplicationActivities.sendActivity({
        //     type: "PAPERMOON",
        //     description: "",
        //     timestamp: new Date(),
        //     os: isMobile ? "mobile-website" : "website"
        // })
    }, []);

    return (
        <PageWrapper
            loginShow={isLoginFormShown}
            customOnHide={isLoginFormShown ? () => showLoginForm(false) : null}
            headerActive={'radityadika'}
            hideFooter
        >
            <Container fluid>
                <Container style={{ paddingTop: '1em', marginBottom: 70 }}>
                    {/*<Row style={{display: 'flex', justifyContent: 'left'}}>*/}
                    {/*    <img src={'/image/papermoon-header.jpg'} style={{width: '100%', maxWidth: 800}}/>*/}
                    {/*</Row>*/}


                    {/*<Row style={{display: 'flex', justifyContent: 'left'}}>*/}
                    {/*    <TiLocationOutline color={'#3EC200'} size={22}*/}
                    {/*                       style={{marginRight: 10, marginTop: 2}}/>*/}
                    {/*    <div style={{fontWeight: 'bold'}}>EVENT ONLINE</div>*/}

                    {/*    <div style={{height: 30, width: 2, backgroundColor: '#ebebeb', marginLeft: 15, marginRight: 15}}/>*/}

                    {/*    <TiTicket color={'#3EC200'} size={22}*/}
                    {/*              style={{marginRight: 10, marginTop: 2}}/>*/}
                    {/*    <div style={{fontWeight: 'bold'}}>{isEN ? 'US$ 10' : 'Rp100.000,-'}</div>*/}
                    {/*    <div style={{marginLeft: 3, color: 'red', opacity: isEN ? 1 : 0}}>*</div>*/}
                    {/*</Row>*/}

                    {/*{isEN ?*/}
                    {/*    <Row style={{*/}
                    {/*        display: 'flex',*/}
                    {/*        justifyContent: 'left',*/}
                    {/*        fontSize: 12,*/}
                    {/*        color: '#878787',*/}
                    {/*        marginTop: 5*/}
                    {/*    }}>*/}
                    {/*        /!*<Col style={{color: 'red', fontSize: 14, marginRight: 5, textAlign: 'right', paddingRight: 0}} xs={1} sm={2}>*!/*/}
                    {/*        /!*    **!/*/}
                    {/*        /!*</Col>*!/*/}
                    {/*        <Col style={{textAlign: 'left', }}>*/}
                    {/*            <span style={{marginRight: 5, color: 'red'}}>*/}
                    {/*                **/}
                    {/*            </span>*/}

                    {/*The price will be converted into rupiah with an exchange rate of 1 USD = IDR*/}
                    {/*15,000*/}
                    {/*        </Col>*/}
                    {/*        /!*<div>*</div>*!/*/}

                    {/*    </Row> : null*/}
                    {/*}*/}

                    <Row style={{ display: 'flex', justifyContent: 'center', marginTop: 40, marginBottom: 25 }}>
                        <img src={Banner}
                            style={{ width: '100%', maxWidth: 900, objectFit: 'contain', maxHeight: 500 }} />
                    </Row>

                    {/*<div style={{marginTop: 15, marginBottom: 35, textAlign: 'left'}}>*/}

                    {/*    <p style={{*/}
                    {/*        fontSize: '1.1em',*/}
                    {/*        fontWeight: 'bolder'*/}
                    {/*    }}>{isEN ? 'TICKET PRICE' : 'HARGA TIKET'}</p>*/}

                    {/*<div style={{marginTop: -5}}>*/}
                    {/*    {isEN ? 'USD 10' : 'IDR 100.000'} {isEN ? <span style={{color: 'red'}}>*</span> : null} {isEN ? 'or' : 'atau'} Barter*/}
                    {/*</div>*/}

                    {/*{isEN ?*/}
                    {/*    <div style={{fontSize: 12, color: 'grey'}}>*/}
                    {/*        <span style={{color: 'red', marginRight: 3}}>*</span>*/}
                    {/*        The price will be converted into rupiah with an exchange rate of 1 USD = IDR 15,000*/}
                    {/*    </div> : null*/}
                    {/*}*/}
                    {/*</div>*/}

                    <Row style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div style={{
                            marginTop: 15, textAlign: 'left', maxWidth: 900,
                            // background: `url('/image/beetle-down.png')`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "60px 60px",
                            backgroundPosition: "70% 5%",
                        }}>



                            <div style={{ marginBottom: 35, textAlign: 'left' }}>

                                <div style={{ marginTop: -5 }}>
                                    Penasaran bagaimana menulis dengan gaya yang unik dan komedi? Atau ingin menggali lebih dalam tentang proses kreatif pembuatan cerita?<br/><br/>
                                    patjarboekoe boleh bertanya apa saja perihal tulis-menulis, termasuk proses kreatif seorang Raditya Dika di Ngobrol Apa Saja Soal Menulis. Dari ide awal hingga proses penulisan dan publikasi, kulik bagaimana Raditya mengubah ide-ide sederhana menjadi karya yang memikat dan menghibur di beragam medium.<br/><br/>
                                    Berkolaborasi dengan patjarmerah dan Pos Bloc Jakarta, Radit juga mengajak patjarboekoe urun daya untuk menambah koleksi bacaan di rumah-rumah baca. Seluruh hasil penjualan tiket akan digunakan untuk pengadaan buku tersebut. patjarboekoe boleh juga menambah jumlah buku donasi dengan membawa buku-buku dari rumah atau membeli langsung di Kedai patjarmerah. Kotak pengumpulan buku tersedia di Kedai patjarmerah.<br/><br/>
                                    Melalui urun daya bersama ini, kita telah memberikan kaki pada buku-buku untuk bertemu lebih banyak pembaca.
                                </div>

                            </div>

                        </div>


                        <div style={{
                            backgroundColor: 'white',
                            padding: 30,
                            borderRadius: 4,
                            border: '1px solid #d1d1d1',
                            width: "100%",
                            maxWidth: 900
                        }} ref={buyTicketRef}>
                            <p style={{
                                fontSize: '1.1em',
                                fontWeight: 'bold'
                            }}>{isEN ? 'BUY TICKET' : 'BELI TIKET'}</p>

                            <div stlye={{ width: "100%" }}>
                                {
                                    papermoonSesssions.map((session, idx) => {
                                        return <KonserDongengTicketNode
                                            isEN={isEN} session={session}
                                            lineSeparator={true}
                                            showLoginForm={showLoginForm} />
                                    })
                                }
                            </div>


                            {
                                isEN ?
                                    <>
                                        <Row style={{
                                            height: 1,
                                            backgroundColor: '#e6e6e6',
                                            marginTop: 20,
                                            marginBottom: 20
                                        }} />
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "flex-end",
                                            justifyContent: "flex-end"
                                        }}>
                                            <Button
                                                variant={"outline-primary"}
                                                onClick={() => {
                                                    setIsPPModalOpen(true)
                                                }}
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center"
                                                }}>
                                                    <img src={require("../../asset/paypal-logo.png")}
                                                        style={{ height: "1em" }} />
                                                    <div style={{ marginLeft: "1em" }}>Pay With Paypal</div>
                                                </div>
                                            </Button>
                                        </div>

                                    </>
                                    :
                                    null
                            }


                        </div>

                    </Row>
                    {
                        isMobile ?
                            <Row>
                                <div>
                                    <br />
                                    <br />
                                    <br />
                                </div>
                            </Row>
                            :
                            null
                    }


                </Container>

                <Modal
                    size="lg"
                    onHide={() => {
                        setIsToCModalOpen(false)
                    }}
                    show={isToCModalOpen}
                >
                    <Modal.Header closeButton>
                        {isEN ? "Terms and Condition" : "Syarat dan Ketentuan"}
                    </Modal.Header>
                    <Modal.Body>
                        <ul style={{ marginBotton: "42px", lineHeight: "30px" }}>
                            <li>
                                {isEN ? "" : "1 tiket luring (offline) berlaku untuk 1 orang."}
                            </li>
                            <li>
                                {isEN ? "" : "Apabila anak ditemani oleh pendamping, pendamping juga harus membeli tiket."}
                            </li>
                            <li>
                                {isEN ? "All Sales are Final." : "Transaksi yang dilakukan bersifat final."}
                            </li>
                            <li>
                                {isEN ? "Items that have been purchased cannot be returned." : "Tiket yang dibeli tidak bisa ditukar atau dikembalikan."}
                            </li>
                            <li>
                                {isEN ? "" : "Harap menyelesaikan pembayaran dalam waktu 2 jam."}
                            </li>
                            <li>
                                {isEN ? "" : "Pemesanan dibatalkan secara otomatis apabila dalam waktu 2 jam pembayaran tidak dilakukan."}
                            </li>
                        </ul>
                    </Modal.Body>

                </Modal>

                <Modal
                    size="lg"
                    onHide={() => {
                        setIsPPModalOpen(false)
                    }}
                    show={isPPModalOpen}
                >
                    <Modal.Header closeButton>
                        {isEN ? "Pay With Paypal" : "Pembayaran Menggunakan Paypal"}
                    </Modal.Header>

                    <Modal.Body>
                        As an alternative method to accomodate overseas international payment, we now accept payment
                        using
                        Paypal.<br />

                        <br />
                        <br />

                        <b>How to Confirm Your Paypal Payment</b>
                        <ol>
                            <li>
                                Create an account in patjarmerah.com
                            </li>
                            <li>
                                Pay with Paypal using the following <a
                                    href={"https://www.paypal.com/paypalme/papermoonpuppet"}
                                    target={"_blank"}>link</a>
                            </li>
                            <li>
                                Confirm your payment via email to <a
                                    href="mailto: renjana@patjarmerah.com">renjana@patjarmerah.com</a>.
                                In the email, please include your <b>payment proof</b>, registered <b>email</b>, and
                                your <b>preferred
                                    show time</b>. Please also use the subject <b>Paypal Payment Confirmation</b>.
                            </li>
                            <li>
                                Please wait for our admins to process your payment and we will get back to you.
                            </li>
                            {/*<li>*/}
                            {/*    {isEN ? "Please complete your payment 2 hours within order." : "Harap menyelesaikan pembayaran 2 jam setelah pendaftaran."}*/}
                            {/*</li>*/}
                        </ol>

                        <br />

                        <b>Disclaimer</b>

                        <ol>
                            <li>
                                Please be aware that payment using paypal is confirmed manually by our admins, so it may
                                takes several
                                hours before confirmation.
                            </li>
                            <li>
                                If your preferred show time is full, we will put you in another time.
                            </li>
                            <li>
                                We recommend you to pay using other method first before using paypal.
                            </li>
                        </ol>
                    </Modal.Body>

                </Modal>



            </Container>
        </PageWrapper>
    )
}
