import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageWrapper from "./PageWrapper";
import { Button } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { AiOutlineCalendar, AiOutlineWarning } from "react-icons/ai";
import Palette from "../../util/Palette";
import { MdError, MdLocationOn } from "react-icons/md";
import { TiLocationOutline, TiTicket } from "react-icons/ti";
import { useCookies } from "react-cookie";
import Session from "../../models/Session";
import PapermoonTicketNode from "../reusable/PapermoonTicketNode";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import moment from "moment";
import ScheduleNode from "../reusable/ScheduleNode";
import GlobalData from "../../util/GlobalData";
import _ from "lodash";
import SessionModal from "../reusable/SessionModal";
import { useHistory } from 'react-router-dom'
import { CsvToHtmlTable } from "react-csv-to-table";
import { MDBDataTable } from 'mdbreact';

let firstLoad = false



const jsonData = {}

export default function MencariNaskah(props) {

    const [isLoginFormShown, setIsLoginFormShown] = useState(false);
    const [loginMessage, setLoginMessage] = useState("")

    const buyTicketRef = useRef(null);
    const [cookie, setCookie, removeCookie] = useCookies()
    const isEN = cookie.lang !== "id";
    const [fbaSessions, setFbaSessions] = useState([]);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    const history = useHistory()

    const [isToCModalOpen, setIsToCModalOpen] = useState(false)
    const [isPPModalOpen, setIsPPModalOpen] = useState(false)

    const [isSessionModalShow, setIsSessionModalShown] = useState(false)
    const [isPaymentModalShow, setIsPaymentModalShow] = useState(false)
    const [paymentUrl, setPaymentUrl] = useState("")
    const [selectedSession, setSelectedSession] = useState(null)

    function handleResize() {
        console.log('width: ' + window.innerWidth)

        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
        })
    }

    const fetchSessions = async () => {

        console.log("is calling with " + GlobalData.token)

        let sessions = await new Session().getFBASessions();

        let defaultId = 0

        if (firstLoad) {
            if (props.match.params.id) {
                defaultId = this.props.match.params.id
                console.log("def", defaultId)
            }
            firstLoad = false
        }

        if (defaultId > 0) {
            let session = sessions.find(e => {
                console.log(defaultId, parseInt(e.id), parseInt(e.id) === parseInt(defaultId))
                return parseInt(e.id) === parseInt(defaultId)
            })
            console.log("selected session", sessions)
            if (session) {
                // this.setState({
                //     ...this.props,
                //     selectedSession: session,
                //     isSessionModalShown: true
                // })
            }

        }

        console.log(sessions)
        setFbaSessions(sessions)

    }

    useEffect(() => {

        fetchSessions()

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }

    }, []);

    const showLogin = () => {
        setIsLoginFormShown(true)
        setLoginMessage('Harap login untuk melanjutkan pendaftaran')
        setIsSessionModalShown(false)
    }

    const renderOldBody = () => {
        return <>
            <Container style={{ paddingTop: '1em', paddingBottom: '1em', backgroundColor: "#FFFFFF50" }}>
                <Row style={{ display: 'flex', justifyContent: 'center', marginTop: 50, marginBottom: 25 }}>
                    <Col xl={10}>
                        <b style={{ fontFamily: "Bebas-Neue", fontSize: 32 }}>Renjana Indonesia Mencari Naskah
                        </b>
                        <p style={{ marginTop: 15 }}>
                            Tiga penerbit yang tergabung dalam kelompok Renjana Literasi Indonesia; Penerbit BaNaNa,
                            Penerbit Indonesia Tera, dan Indie Book Corner sedang mencari naskah-naskah terbaik dari
                            penulis terbaik Indonesia. Kami ingin mendengar suara-suara baru para penulis Indonesia
                            masa kini, baik dalam penulisan fiksi maupun nonfiksi.
                        </p>
                        <p>
                            Kami mencari naskah dalam tiga kategori berupa Novel atau Novela, Cerita Perjalanan,
                            Kumpulan Esai atau Memoar.
                        </p>
                        <p>
                            Jika kamu merasa memiliki kemampuan menulis yang baik, dan karyamu ingin diterbitkan,
                            dibaca banyak orang, menginspirasi, dan menawarkan sesuatu yang penting dari sudut
                            pandangmu, ini adalah waktu yang tepat untukmu mengirimkan karya. Selesaikan karya
                            terbaikmu, dan kami menunggu untuk membacanya.
                        </p>
                    </Col>
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 25 }}>
                    <Col xl={10}>
                        <b style={{ fontFamily: "Bebas-Neue", fontSize: 32 }}>Kriteria Naskah
                        </b>
                        <ol style={{ marginTop: 15 }}>
                            <li><b>Novel atau Novela.</b> Panjang naskah novel minimal 30.000 kata, sementara
                                panjang naskah novela berkisar 15.000-40.000 kata.
                            </li>
                            <li style={{ marginTop: 9 }}><b>Cerita Perjalanan.</b> Panjang naskah minimum 30.000 kata.
                            </li>
                            <li style={{ marginTop: 9 }}><b>Kumpulan Esai atau Memoar.</b> Panjang naskah minimal
                                25.000 kata.
                            </li>
                        </ol>
                    </Col>
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 25 }}>
                    <Col xl={10}>
                        <b style={{ fontFamily: "Bebas-Neue", fontSize: 32 }}>Ketentuan Pengiriman Naskah
                        </b>
                        <ol style={{ marginTop: 15 }}>
                            <li>Pengiriman naskah terbuka untuk umum.</li>
                            <li style={{ marginTop: 9 }}>Naskah merupakan karya asli, bukan hasil plagiat, bukan
                                terjemahan atau saduran.
                            </li>
                            <li style={{ marginTop: 9 }}>Naskah yang dikirim belum pernah dipublikasikan penerbit lain
                                atau tidak sedang mengikuti sayembara serupa.
                            </li>
                            <li style={{ marginTop: 9 }}>Memiliki sudut penceritaan yang unik dan segar.</li>
                            <li style={{ marginTop: 9 }}>Naskah utuh, sudah memiliki akhir, bukan sekadar
                                potongan-potongan bab.
                            </li>
                            <li style={{ marginTop: 9 }}>Tema penulisan bebas, tetapi diutamakan jenis karya yang
                                menggugah dan memiliki kesegaran gagasan.
                            </li>
                            <li style={{ marginTop: 9 }}>Boleh naskah yang kontroversial, tetapi tidak menyinggung
                                SARA.
                            </li>
                        </ol>
                    </Col>
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 25 }}>
                    <Col xl={10}>
                        <b style={{ fontFamily: "Bebas-Neue", fontSize: 32 }}>Format Pengiriman Naskah
                        </b>
                        <ol style={{ marginTop: 15 }}>
                            <li>Naskah diketik dalam format ukuran kertas kuarto (A4) dengan 1.5 spasi.</li>
                            <li style={{ marginTop: 9 }}>Margin Atas 4, Kiri 4, Kanan 3, Bawah 3 (dalam cm).
                            </li>
                            <li style={{ marginTop: 9 }}>Jenis huruf Times New Roman, ukuran huruf 12 pt, paragraf
                                rata kiri-kanan.
                            </li>
                            <li style={{ marginTop: 9 }}>Pengiriman naskah dapat dilakukan dengan mengisi formulir di
                                bagian bawah halaman ini.
                            </li>
                            <li style={{ marginTop: 9 }}>Panitia akan memberikan surel konfirmasi melalui
                                renjana@patjarmerah.com, jika naskah kamu sudah kami terima dengan baik.
                            </li>
                            <li style={{ marginTop: 9 }}>Boleh naskah yang kontroversial, tetapi tidak menyinggung
                                SARA.
                            </li>
                        </ol>
                    </Col>
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 25 }}>
                    <Col xl={10}>
                        <b style={{ fontFamily: "Bebas-Neue", fontSize: 32 }}>Ketentuan Tambahan
                        </b>
                        <ol style={{ marginTop: 15 }}>
                            <li>Setiap peserta yang mengirimkan naskah secara otomatis menyatakan kesediaan bahwa
                                naskah yang menang akan diterbitkan oleh penerbit di bawah kelompok Renjana
                                Indonesia, yaitu Penerbit BaNaNa, Penerbit Indonesia Tera, dan Indie Book Corner.
                            </li>
                            <li style={{ marginTop: 9 }}>Hak cipta naskah sepenuhnya milik penulis.</li>
                            <li style={{ marginTop: 9 }}>Akan dipilih 3 (tiga) pemenang pada setiap kategori naskah.
                                Total pemenang adalah 9 (sembilan) pemenang.
                            </li>
                            <li style={{ marginTop: 9 }}>Naskah para pemenang akan diterbitkan oleh penerbit
                                yang ada di bawah kelompok Renjana Indonesia, yaitu Penerbit BaNaNa, Indonesia Tera,
                                dan Indie Book Corner.
                            </li>
                            <li style={{ marginTop: 9 }}>Keputusan terkait penerbitan naskah merupakan keputusan juri
                                yang tidak dapat diganggu gugat.
                            </li>
                        </ol>
                    </Col>
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 25 }}>
                    <Col xl={10}>
                        <b style={{ fontFamily: "Bebas-Neue", fontSize: 32 }}>Hadiah dan Kontrak Penerbitan
                        </b>
                        <ol style={{ marginTop: 15 }}>
                            <li>Masing-masing pemenang akan mendapatkan uang tunai sebesar <b>Rp2.500.000</b></li>
                            <li style={{ marginTop: 9 }}>Pemenang akan mendapatkan kelas khusus, serta pendampingan
                                penulisan dan penyuntingan naskah bersama para editor masing-masing penerbit.
                            </li>
                            <li style={{ marginTop: 9 }}>Para pemenang akan mendapatkan kontrak penerbitan naskah dan
                                penerbitannya akan dibiayai oleh penerbit yang ada di dalam kelompok Renjana
                                Literasi Indonesia, yaitu Penerbit BaNaNa, Penerbit Indonesia Tera, dan Indie Book
                                Corner.
                            </li>
                            <li style={{ marginTop: 9 }}>Setiap naskah pemenang akan diterbitkan dengan sistem
                                pembagian royalti.
                            </li>
                        </ol>
                    </Col>
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 25 }}>
                    <Col xl={10}>
                        <b style={{ fontFamily: "Bebas-Neue", fontSize: 32 }}>Waktu Pelaksanaan
                        </b>
                        <ol style={{ marginTop: 15 }}>
                            <li>Periode pengiriman naskah dimulai sejak <b>1 November</b> hingga <b>25 Desember 2020</b>.
                            </li>
                            <li style={{ marginTop: 9 }}>Daftar panjang pemenang akan diumumkan pada <b>31 Januari
                                2021</b>.
                            </li>
                            <li style={{ marginTop: 9 }}>Daftar pendek pemenang akan akan diumumkan pada <b>7 Februari
                                2021</b>.
                            </li>
                            <li style={{ marginTop: 9 }}>Pemenang akan diumumkan pada <b>14 Februari
                                2021</b>.
                            </li>
                        </ol>
                    </Col>
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 25 }}>
                    <Col xl={10}>
                        <b style={{ fontFamily: "Bebas-Neue", fontSize: 32 }}>Dewan Juri
                        </b>
                        <ol style={{ marginTop: 15 }}>
                            <li>Dorothea Rosa Herliany, Penerbit Indonesia Tera.
                            </li>
                            <li style={{ marginTop: 9 }}>Irwan Bajang, Indie Book Corner.
                            </li>
                            <li style={{ marginTop: 9 }}>Windy Ariestanty, Renjana Literasi Indonesia.
                            </li>
                            <li style={{ marginTop: 9 }}>Yusi Avianto Pareanom, Penerbit BaNaNa.
                            </li>
                        </ol>
                    </Col>
                </Row>
            </Container>
            <Container style={{ paddingTop: '1em', paddingBottom: '1em', backgroundColor: "#FFFFFF50" }}>
                <Row>
                    <Col md={12}>
                        <h3><b>Daftar Naskah Diterima</b></h3>
                    </Col>
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'center', marginBottom: 25 }}>
                    <Col md={12}>
                        <MDBDataTable
                            paginationLabel={["<", ">"]}
                            displayEntries={false}
                            info={false}
                            striped
                            data={jsonData}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    }

    return (
        <PageWrapper
            backgroundColor={"#FFFFFF"}
            loginShow={isLoginFormShown}
            message={loginMessage}
            customOnHide={isLoginFormShown ? () => setIsLoginFormShown(false) : null}
            headerActive={'sayap'}
            hideFooter={true}
        >

            <Container fluid style={{ backgroundColor: "#FFFFFF" }}>
                <Row style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: "center",
                    backgroundColor: "#FFFFFF"
                }}>
                    <Col xl={10} style={{ display: 'flex', justifyContent: 'center', alignContent: "center" }}>
                        <img src={require("../../asset/2024/sayap/sayap-header.jpeg")}
                            style={{ width: '100%', maxWidth: 900, height: "100%" }} />
                    </Col>
                </Row>

                <Container style={{ paddingTop: '1em', paddingBottom: '1em', backgroundColor: "#FFFFFF50" }}>
                    <Row style={{ display: 'flex', justifyContent: 'center', marginTop: 50, marginBottom: 25 }}>
                        <Col xl={10}>
                            <b style={{ fontFamily: "Bebas-Neue", fontSize: 32 }}>
                            </b>
                            <p style={{marginTop: 15, fontSize: 21}}>
                                <i><b>Bayangkan, bagaimana hari-harimu jika kau punya sayap?</b></i>
                            </p>
                            <p style={{marginTop: 10}}>
                                Tulis ceritamu tentang sayap itu. patjarmerah kecil bersama Indonesia Tera akan memilih sepuluh tulisan karya kawan
                                kecil untuk diterbitkan bersama kisah dari Reda Gaudiamo.
                            </p>
                            <p><b>Hadiah:</b>
                            </p>
                            <ol style={{ marginTop: 15 }}>
                                <li style={{ marginTop: 9 }}>Cerita yang terpilih akan diterbitkan oleh Indonesia Tera dalam bentuk buku antologi
                                    bersama Reda Gaudiamo
                                </li>
                                <li style={{ marginTop: 9 }}>
                                    10 Kawan Kecil yang karyanya terpilih akan mendapatkan uang tunai masing-masing
                                    Rp1.000.000
                                </li>
                                <li style={{ marginTop: 9 }}>Sertifikat elektronik
                                </li>
                            </ol>
                            <p><b>Ketentuan:</b>
                            </p>
                            <ol style={{ marginTop: 15 }}>
                                <li>Sayembara terbuka untuk kawan kecil berusia 8-13 tahun.
                                </li>
                                <li style={{ marginTop: 9 }}>Tema cerita: Sayap.
                                </li>
                                <li style={{ marginTop: 9 }}>Cerita adalah karya fiksi, asli bukan hasil plagiat maupun
                                    terjemahan.
                                </li>
                                <li style={{ marginTop: 9 }}>Cerita belum pernah dipublikasikan.
                                </li>
                                <li style={{ marginTop: 9 }}>Panjang cerita sekitar 500-1000 kata; Times New Roman 12,
                                    spasi
                                    1, margin atas-bawah 2 cm, kiri 1,5 cm, kanan 1 cm.
                                </li>
                                <li style={{ marginTop: 9 }}>Kawan Kecil dapat mengirimkan lebih dari satu cerita.
                                </li>
                                <li style={{ marginTop: 9 }}>Kirim cerita dan profilmu dengan menekan tombol daftar di
                                    bagian
                                    bawah halaman ini.
                                </li>
                                <li style={{ marginTop: 9 }}>Mengisi formulir pendaftaran dan pernyataan keaslian karya
                                    yang dapat diunduh di <a href="https://bit.ly/SayembaraMenulisCeritaAnak"><b>sini</b></a>.
                                </li>
                                <li style={{ marginTop: 9 }}>Penjudulan dokumen karya: <b>Nama_Judul
                                    karya.</b> Contoh: <b>Saga_Sayap
                                        Buku.</b>
                                </li>
                                <li style={{ marginTop: 9 }}>Penulis yang ceritanya terpilih bersedia menjalani proses
                                    penyuntingan oleh tim penyunting Indonesia Tera dan Reda Gaudiamo.
                                </li>
                                <li style={{ marginTop: 9 }}>Hak cipta setiap cerita milik penulis. Hak terbit milik
                                    penerbit.
                                </li>
                                <li style={{ marginTop: 9 }}>Antologi diterbitkan pada Juli 2024 (tentatif).
                                </li>
                            </ol>
                            <p><b>Waktu Pelaksanaan:</b>
                            </p>
                            <ol style={{ marginTop: 15 }}>
                                <li style={{ marginTop: 9 }}>Cerita dikirim selambatnya 11 Maret 2024.
                                </li>
                                <li style={{ marginTop: 9 }}>Cerita terpilih akan diumumkan pada 6 April 2024.
                                </li>
                            </ol>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Row style={{
                position: 'fixed',
                bottom: 0,
                // backgroundColor: 'white',
                width: '100%',
                // borderTop: '1px solid #e6e6e6',
                paddingTop: 10,
                paddingBottom: 10,
                paddingRight: 20,

            }}
            >
                <Col style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    flexDirection: 'row'
                }}>

                        <Button
                            disabled
                            style={{
                                fontWeight: 'bolder',
                                fontSize: '1.05em',
                                backgroundColor: Palette.MAROON,
                                borderWidth: 0,
                                paddingTop: 10,
                                paddingBottom: 10,
                                margin: 3,
                                width: 210,
                            }}
                            onClick={() => { }}>Pendaftaran Ditutup
                        </Button>

                </Col>
            </Row>
        </PageWrapper>
    )
}
