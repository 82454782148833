import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import PageWrapper from "./PageWrapper";
import Form from "react-bootstrap/Form";
import { Row, Col, Button } from "react-bootstrap";

import _ from "lodash";
import User from "../../models/User";

let firstLoad = false



const jsonData = {}

export default function RequestDelete(props) {

    let [rejectionReason, setRejectionReason] = useState("")

    return <PageWrapper
        backgroundColor={"#FFFFFF"}
        headerActive={'request-delete'}
        hideFooter={true}
    >

        <Container fluid style={{ backgroundColor: "#FFFFFF", marginTop: 30 }}>
            <Row>
                <Col md={6}>
                    <b>Form penghapusan akun</b>
                    <div>

                    </div>
                    <div>
                        Alasan penghapusan: 
                    </div>
                    <Form.Group>
                        <Form.Control
                            value={rejectionReason}
                            onChange={evt => setRejectionReason(evt.target.value)}
                            style={{ fontSize: "1em" }} placeholder="" />
                    </Form.Group>
                    <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end", marginTop: 10 }}>
                        <Button onClick={async()=>{
                            try{
                                let user = new User()
                                let result = await user.requestDelete(rejectionReason)
                                setRejectionReason("")
                                alert("Permohonan telah diterima dan akan di proses dalam waktu kurang dari 5 hari kerja.")
                            }catch(e){
                                console.log(e)
                                alert("Terjadi Kesalahan")
                            }
                        }}>
                            Kirim
                        </Button>
                    </div>

                </Col>
            </Row>
        </Container>
    </PageWrapper>
}