import React, {Component, useEffect} from "react";
import {Button, Modal, Nav, Navbar, NavDropdown, Accordion} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import PageWrapper from "./PageWrapper";
import moment from "moment";
import Radium from 'radium'
import ScheduleNode from "../reusable/ScheduleNode";
import Session from "../../models/Session"
import _ from "lodash"
import SessionModal from "../reusable/SessionModal";
import GlobalData from "../../util/GlobalData";
import 'moment/locale/id'
import cookie from 'react-cookies'

import {
    isMobile
} from "react-device-detect";
import ApplicationActivities from "../../models/ApplicationActivity";
import QRCode from "react-qr-code";
import {AiFillCheckCircle} from "react-icons/all";
import Palette from "../../util/Palette";

export default class Surabaya2022Sessions extends Component {

    firstLoad = true

    constructor(props) {
        super(props)

        let winWidth = window.innerWidth

        this.state = {
            qr: "",
            error: false,
            errorMessage: "",
            windowIsLarge: winWidth > 1199,
            sessions: [],
            isSessionModalShown: false,
            loginShow: false,
            loginMessage: null,
            isLanguageInfoOpen: true,
            onlyShowRegisteredSessions: false
        }

        this.fetchSessions = this.fetchSessions.bind(this);

        this.fetchSessions();
    }

    sessions = [
        {
            image: "https://intellivent.sgp1.digitaloceanspaces.com/session/36/IMAGE/1574644231359.jpg",
            title: "Menulis Cerita Anak",
            speaker: "Reda Gaudiamo",
            timeStart: new moment("2020-04-20 13:00:00"),
            timeEnd: new moment("2020-04-21 18:00:00"),
        }, {
            image: "https://intellivent.sgp1.digitaloceanspaces.com/session/36/IMAGE/1574644231359.jpg",
            title: `Peluncuran Buku "Leak Tegal Sirah"`,
            speaker: `I Gusti Putu Bawa Samar Gantang (Indonesia Tera)
            Dorothea Rosa
            Herliany (Indonesia Tera)
            `,
            timeStart: new moment("2020-04-20 13:00:00"),
            timeEnd: new moment("2020-04-21 18:00:00"),
        }
    ]

    async fetchSessions() {

        console.log("is calling with " + GlobalData.token)

        let sessions = await new Session().getAllSurabaya2022Sessions();

        let defaultId = 0

        if (this.firstLoad) {
            if (this.props.match.params.id) {
                defaultId = this.props.match.params.id
                console.log("def", defaultId)
            }
            this.firstLoad = false
        }

        let filteredSessions = _.filter(sessions, (obj) => {
            return moment(obj.end_time).isAfter(new Date())
        })

        let formattedSession = _.groupBy(filteredSessions, (obj) => {
            return moment(obj.start_time).locale('id').format("dddd, DD MMMM YYYY")
        })

        if (defaultId > 0) {
            let session = sessions.find(e => {
                console.log(defaultId, parseInt(e.id), parseInt(e.id) === parseInt(defaultId))
                return parseInt(e.id) === parseInt(defaultId)
            })
            console.log("selected session", session)
            if (session) {
                this.setState({
                    ...this.props,
                    selectedSession: session,
                    isSessionModalShown: true
                })
            }

        }

        if(this.state.selectedSession){
            let ss = sessions.find(e => {
                console.log(defaultId, parseInt(e.id), parseInt(e.id) === parseInt(this.state.selectedSession.id))
                return parseInt(e.id) === parseInt(this.state.selectedSession.id)
            })
            if(ss){
                this.setState({
                    sessions: formattedSession,
                    selectedSession : ss
                })
            }
            return
        }

        this.setState({
            sessions: formattedSession
        })
    }

    showLogin = () => {
        this.setState({
            loginMessage: 'Harap login untuk melanjutkan pendaftaran',
            isSessionModalShown: false,
            loginShow: true,
            customOnHide: () => {
                this.fetchSessions();
                this.setState({loginShow: false, loginMessage: null, customOnHide: null})
            }
        })
    }

    addApplicationActivity = (session) => {
        ApplicationActivities.sendActivity({
            type: "SESSION_DETAIL",
            description: "",
            detail: session.id,
            timestamp: new Date(),
            os: isMobile ? "mobile-website" : "website"
        })
    }

    renderSessionOfDay(dateString, sessionOfDay) {
        let hasRegisteredEvents = false;

        {
            sessionOfDay.forEach((s, key) => {
                if (s.status === 'ACCEPTED') {
                    hasRegisteredEvents = true;
                }
            })
        }

        if (!hasRegisteredEvents && this.state.onlyShowRegisteredSessions) return null;

        return (
            <>
                <Accordion.Item eventKey={dateString}>
                    <Accordion.Header>{dateString}</Accordion.Header>
                    <Accordion.Body>
                        <Row>
                            {sessionOfDay.map((s, key) => {
                                if (s.status !== 'ACCEPTED' && this.state.onlyShowRegisteredSessions) return null;

                                return <ScheduleNode
                                    session={s}
                                    key={key}
                                    id={s.id}
                                    image={s.image_url}
                                    speaker={s.session_speaker}
                                    title={s.session_name}
                                    timeStart={moment(s.start_time)}
                                    timeEnd={moment(s.end_time)}
                                    onClick={(session) => {

                                        console.log("logging selected session", session)

                                        this.addApplicationActivity(session)
                                        this.setState({
                                            ...this.props,
                                            selectedSession: session,
                                            isSessionModalShown: true
                                        })
                                        this.props.history.push("/surabaya/" + s.id)

                                    }}
                                />
                            })}
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </>
        )

        return (
            <>
                <Row style={{
                    justifyContent: isMobile ? "center" : "flex-start"
                }}>
                    <h4>{dateString}</h4>
                </Row>
                <Row>
                    {sessionOfDay.map((s, key) => {
                        if (s.status !== 'ACCEPTED' && this.state.onlyShowRegisteredSessions) return null;

                        return <ScheduleNode
                            session={s}
                            key={key}
                            id={s.id}
                            image={s.image_url}
                            speaker={s.session_speaker}
                            title={s.session_name}
                            timeStart={moment(s.start_time)}
                            timeEnd={moment(s.end_time)}
                            onClick={(session) => {


                                this.addApplicationActivity(session)
                                this.setState({
                                    ...this.props,
                                    selectedSession: session,
                                    isSessionModalShown: true
                                })
                                this.props.history.push("/surabaya/" + s.id)

                            }}
                        />
                    })}
                </Row>
            </>
        )
    }

    renderSessions() {
        let dateStrings = Object.keys(this.state.sessions)

        return dateStrings.map((dateString, key) => {
            return <>
                <Accordion>
                    {this.renderSessionOfDay(dateString, this.state.sessions[dateString])}
                </Accordion>
            </>
        })
    }

    componentDidMount() {
        ApplicationActivities.sendActivity({
            type: "SCHEDULE_LIST",
            description: "",
            timestamp: new Date(),
            os: isMobile ? "Mobile Webiste" : "Website"
        })
    }

    render() {

        let lang = cookie.load('lang')
        console.log(lang)

        let isEN = lang !== "id"

        return (
            <PageWrapper
                headerShy={false}
                history={this.props.history}
                loginShow={this.state.loginShow}
                message={this.state.loginMessage}
                customOnHide={this.state.customOnHide}
                loginListener={(user) => this.fetchSessions()}
                headerActive={'surabaya'}
            >

                <Modal
                    size="sm"
                    onHide={() => {
                        this.setState({
                            isLanguageInfoOpen: false
                        })
                    }}
                    show={isEN && this.state.isLanguageInfoOpen}
                >
                    <Modal.Header closeButton>
                        Information
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            This page is only available in Indonesian.
                        </div>
                    </Modal.Body>

                </Modal>

                <Modal
                    size="sm"
                    centered
                    onHide={() => {
                        this.setState({paymentModalVisible: false})
                        window.location.reload();
                    }}
                    show={this.state.paymentModalVisible}
                >
                    <Modal.Header closeButton>
                        Informasi
                    </Modal.Header>
                    <Modal.Body>
                        <div>Untuk melanjutkan
                            pembayaran, silahkan menekan <a href={this.state.paymentUrl}>tautan ini</a>.
                        </div>
                    </Modal.Body>

                </Modal>

                <SessionModal
                    show={this.state.isSessionModalShown}
                    session={this.state.selectedSession}
                    setPending={() => {
                        this.fetchSessions();
                        this.setState({isSessionModalShown: false})
                    }}
                    onHide={() => {
                        this.setState({
                            isSessionModalShown: false,
                            selectedSession: null
                        })
                        window.history.pushState({}, '', "/surabaya");
                        // this.props.history.push("/akademi-patjarmerah")
                    }}
                    showLogin={this.showLogin}
                    fetchSessions={this.fetchSessions}
                    showPaymentModal={(paymentUrl) => {
                        console.log("hoihoi")
                        this.setState({paymentUrl, paymentModalVisible: true, isSessionModalShown: false})
                    }}
                />

                <Container style={{marginTop: "1em", marginBottom: 65}}>

                    <Row style={{
                        marginTop: isMobile ? 0 : "2em"
                    }}>
                    </Row>


                    {
                        this.renderSessions()
                    }

                    <div style={{height : 50}}>

                    </div>

                    <div
                        onClick={() => this.setState({onlyShowRegisteredSessions: !this.state.onlyShowRegisteredSessions})}
                        style={{
                            userSelect: 'none',
                            cursor: 'pointer',
                            position: 'fixed',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            bottom: 80,
                            backgroundColor: 'white',
                            width: '100%',
                            maxWidth: 320,
                            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                            paddingTop: 15,
                            paddingBottom: 15,
                            paddingLeft: 15,
                            paddingRight: 15,
                            borderRadius: 5,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            border: `3px solid ${Palette.MAROON}`,
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0
                        }}>
                        <AiFillCheckCircle color={this.state.onlyShowRegisteredSessions ? Palette.MAROON : '#dbdbdb'}
                                           style={{marginRight: 15}} size={30}/>
                        Hanya Tampilkan Acara Terdaftar
                    </div>

                    {/*{this.state.sessions.map((session, key) => {*/}
                    {/*    return <BilingualScheduleNode*/}
                    {/*        key={key}*/}
                    {/*        image={session.image_url}*/}
                    {/*        speaker={session.session_speaker}*/}
                    {/*        title={session.session_name}*/}
                    {/*        timeStart={moment(session.start_time)}*/}
                    {/*        timeEnd={moment(session.end_time)}*/}
                    {/*    />*/}
                    {/*})}*/}

                </Container>
            </PageWrapper>
        );
    }
}

let styles = {
    butt: {
        // Even though we don't have any special styles on the button, we need
        // to add empty :hover styles here to tell Radium to track this element's
        // state.
        ':hover': {
            color: "blue"
        }
    }
};

const style = {
    color: '#000000',
    ':hover': {
        color: 'green'
    }
};

const MyComponent = () => {
    return (
        <section style={style}>
            <div>
                UESSDFLADKF
            </div>
            <div className={"boi"}>
                MINEO
            </div>
        </section>
    );
};

const MyStyledComponent = Radium(MyComponent);
