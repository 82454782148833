import Carousel from "react-bootstrap/Carousel";
import {Button} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import {useParams} from "react-router-dom";
import Session from "../../models/Session";

export default function PapermoonModalPage(props) {
    const [cookie, setCookie, removeCookie] = useCookies()
    const [isEN, setEN] = useState(cookie.lang !== "id")

    const [registrationData, setRegistrationData] = useState({});

    useEffect(() => {
        new Session().getRegistrationInfo(new URLSearchParams(props.location.search).get('id')).then(res => {
            setEN(res.registration.language !== 'id')

            console.log(res)

            setRegistrationData(res)
        }).catch(err => {
            if (err.code === 'REGISTRATION_NOT_FOUND')
                alert(isEN ? 'You have not registered for this session!\nRedirecting you to the Papermoon page . . .' : 'Anda belum terdaftar pada sesi ini!\nMengarahkan Anda ke halaman Papermoon . . .')
            else {
                alert(isEN ? 'An error occured!\nRedirecting you to the Papermoon page . . .' : 'Kesalahan terjadi!\nMengarahkan Anda ke halaman Papermoon . . .')
            }
            window.location.href = '/papermoon'
        })

        // getRegistrationInfo();
    }, [])

    // const getRegistrationInfo = async() =>{
    //     let sessionModel = new Session()
    //     try{
    //         let info = await sessionModel.getRegistrationInfo(registrationId)
    //         console.log(info)
    //         setRegistrationData(info)
    //     }catch(e){
    //         console.log(e)
    //     }
    //
    // }

    return (
        <div style={{
            width: "100vw",
            height: "100vh",
            background: `url(${require("../../asset/bg-papermoon.jpg")})`,
            backgroundSize: "cover",
            display: "flex",
        }}>
            <Modal centered show={true} onHide={() => {
            }}>
                <div style={{
                    fontWeight: '600',
                    marginTop: 30,
                    marginLeft: 30,
                    marginRight: 30,
                    marginBottom: 16,
                    fontSize: '16px',
                    textAlign: 'center'
                }}>
                    {isEN ? `Hello ${registrationData?.registration?.full_name.trim()}, welcome to` : `Halo ${registrationData?.registration?.full_name.trim()}, selamat datang di pertunjukan`}
                    <div style={{fontWeight: '700', fontSize: '25px'}}>“A BUCKET OF BEETLES”</div>
                    {isEN ? 'from' : 'karya'} Papermoon Puppet Theatre.

                </div>

                {/*<div style={{width: '88%', height: 2, backgroundColor: '#E4E4E4', paddingLeft: 30, paddingRight: 30, alignSelf: 'center'}}/>*/}
                <div>
                    <Carousel>
                        <Carousel.Item>
                            <img src={require("../../asset/papermoon-preview/4.jpg")}
                                 style={{width: '100%', objectFit: 'contain'}}/>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={require("../../asset/papermoon-preview/1.jpg")}
                                 style={{width: '100%', objectFit: 'contain'}}/>
                        </Carousel.Item>

                        <Carousel.Item>
                            <img src={require("../../asset/papermoon-preview/2.jpg")}
                                 style={{width: '100%', objectFit: 'contain'}}/>
                        </Carousel.Item>

                        <Carousel.Item>
                            <img src={require("../../asset/papermoon-preview/3.jpg")}
                                 style={{width: '100%', objectFit: 'contain'}}/>
                        </Carousel.Item>
                    </Carousel>
                </div>

                <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 20, marginBottom: 18}}>
                    <a href='https://intellivent.sgp1.digitaloceanspaces.com/patjarmerah/assets/panduanbucketofbeetles.pdf'
                       download target={"_blank"}>
                        <Button variant={'outline-primary'}
                                style={{
                                    fontWeight: '600',
                                    marginLeft: 4,
                                    marginRight: 4
                                }}>
                            {isEN ? 'Download Guide' : 'Unduh Panduan'}
                        </Button>
                    </a>
                    <a href='https://intellivent.sgp1.digitaloceanspaces.com/patjarmerah/assets/bucketofbeetles_october_2020.pdf'
                       download target={"_blank"}>
                        <Button variant={'outline-primary'}
                                style={{
                                    fontWeight: '600',
                                    marginLeft: 4,
                                    marginRight: 4
                                }}>
                            {isEN ? 'Download Booklet' : 'Unduh Buku Acara'}
                        </Button>
                    </a>
                    <a href={registrationData.session ? registrationData.session.stream_url : '#'}
                       target={'_blank'}><Button style={{
                        fontWeight: '600',
                        marginLeft: 4,
                        marginRight: 4
                    }}>
                        {isEN ? 'Watch Now' : 'Tonton Sekarang'}
                    </Button>
                    </a>
                </div>
            </Modal>
        </div>

    )
}
