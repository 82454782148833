import ApiRequest from "../util/ApiRequest";
import GlobalData from "../util/GlobalData";

export default class Session {

    getAll = async () =>{
        return await ApiRequest.set("/events/25/sessions", "GET");
    }

    getAllFromFPI = async () =>{
        return await ApiRequest.set("/events/83/sessions", "GET");
    }

    buyTicket = async (sessionId, isEN) => {
        return await ApiRequest.set('/sessions/' + sessionId + '/register/1', 'POST', {language: isEN ? 'en' : 'id'});
    }

    buyTicketMulti = async (sessionId, quantity) => {
        console.log("BUYING", quantity)
        return await ApiRequest.set('/sessions/' + sessionId + '/register/' + quantity, 'POST', {language: 'id'});
    }

    confirmTransaction = async (sessionId, trxConfirmation) => {
        return await ApiRequest.set('/sessions/' + sessionId + '/register/1', 'POST', trxConfirmation);
    }

    cancel = async (sessionId) => {
        return await ApiRequest.set('/sessions/' + sessionId + '/cancel', 'POST');
    }

    uploadPaymentProof = async (sessionId, image) => {

        let formData = new FormData();

        console.log('blob', image)
        formData.append('upload', image, image.name);

        return await ApiRequest.setMultipart('/sessions/' + sessionId + '/proof/upload', 'POST', formData);
    }

    updateTransactionConfirmation = async(sessionId, trxConfirmation) => {
        return await ApiRequest.set('/sessions/' + sessionId + '/proof', 'POST', trxConfirmation);
    }

    getSessionStream = async(sessionId) => {
        return await ApiRequest.set('/sessions/' + sessionId + '/stream', 'GET');
    }

    changePaymentMethod = async(sessionId) => {
        return await ApiRequest.set(`/sessions/${sessionId}/registration/change`, 'POST')
    }

    getSessionRemainingQuota = async(sessionId) => {
        return await ApiRequest.set(`/sessions/${sessionId}/count`, 'GET')
    }

    getPapermoonSessions = async () => {
        return await ApiRequest.set("/events/75/sessions", "GET");
    }

    getPestaBonekaSessions = async () => {
        return await ApiRequest.set("/events/79/sessions", "GET");
    }

    getPestaBonekaWorkshops = async () => {
        return await ApiRequest.set("/events/80/sessions", "GET");
    }

    getPestaBonekaPerformances = async () => {
        return await ApiRequest.set("/events/81/sessions", "GET");
    }

    getRumasuarSessions = async () => {
        return await ApiRequest.set("/events/77/sessions", "GET");
    }

    getFBASessions = async () => {
        return await ApiRequest.set("/events/90/sessions", "GET");
    }

    getRegistrationInfo = async (registrationId) => {
        return await ApiRequest.set(`/sessions/registrations/${registrationId}`, "GET");
    }

    getKonserDongengSessions = async () => {
        let result1 = await ApiRequest.set("/events/84/sessions", "GET");
        let result2 = await ApiRequest.set("/events/85/sessions", "GET");
        return [...result1, ...result2]
    }

    getAllSurabaya2022Sessions = async () =>{
        return await ApiRequest.set("/events/86/sessions", "GET");
    }

    getAllBandung2022Sessions = async () =>{
        return await ApiRequest.set("/events/87/sessions", "GET");
    }

    getAllSolo2023Sessions = async () =>{
        return await ApiRequest.set("/events/88/sessions", "GET");
    }

    getAllPatjarmerahKecilSessions = async () =>{
        return await ApiRequest.set("/events/92/sessions", "GET");
    }

    getRadityaDikaSessions = async () => {
        return await ApiRequest.set("/events/93/sessions", "GET");
    }

    getAllRenikSessions = async () =>{
        return await ApiRequest.set("/events/94/sessions", "GET");
    }

}
