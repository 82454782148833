import React, {useEffect, useRef, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageWrapper from "./PageWrapper";
import {Button} from "react-bootstrap";
import {isMobile} from "react-device-detect";
import {AiOutlineCalendar, AiOutlineWarning} from "react-icons/ai";
import Palette from "../../util/Palette";
import {MdError, MdLocationOn} from "react-icons/md";
import {TiLocationOutline, TiTicket} from "react-icons/ti";
import {useCookies} from "react-cookie";
import Session from "../../models/Session";
import PapermoonTicketNode from "../reusable/PapermoonTicketNode";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import moment from "moment";
import ScheduleNode from "../reusable/ScheduleNode";
import GlobalData from "../../util/GlobalData";
import _ from "lodash";
import SessionModal from "../reusable/SessionModal";
import {useHistory} from 'react-router-dom'
import {CsvToHtmlTable} from "react-csv-to-table";
import {MDBDataTable} from 'mdbreact';

export default function AcaraPatjarKecil(props) {
    return <PageWrapper
        backgroundColor={"#FFFFFF"}
        // loginShow={isLoginFormShown}
        // message={loginMessage}
        // customOnHide={isLoginFormShown ? () => setIsLoginFormShown(false) : null}
        headerActive={'acarapatjarkecil'}
        hideFooter={true}
    >

        <Container fluid style={{ backgroundColor: "#FFFFFF" }}>
            <Row style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: "center",
                backgroundColor: "#FFFFFF"
            }}>
                <Col xl={10} style={{ display: 'flex', justifyContent: 'center', alignContent: "center" }}>
                    <img src={"https://patjarmerah.com/static/media/bannerpatjarkita.3d1ac74f.jpeg"}
                        style={{ width: '100%', maxWidth: 900, height: "100%" }} />
                </Col>
            </Row>

            <Container style={{ paddingTop: '1em', paddingBottom: '1em', backgroundColor: "#FFFFFF50" }}>
                <Row style={{ display: 'flex', justifyContent: 'center', marginTop: 0, marginBottom: 25 }}>
                    <Col xl={10} style={{ display: 'flex', justifyContent: 'center', alignContent: "center" }}>
                        <div style={{display : isMobile ? null : "flex", flex : 1, width : "100%", overflowX : "scroll", alignItems : "center", justifyContent : "center"}}>
                            <table cellSpacing={5} cellPadding={5} style={{tableLayout : "fixed", width : isMobile ? 450 : 800, fontSize : isMobile ? "0.75em" : "1em", borderSpacing : isMobile ? 10 : null}}>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td colSpan={4} style={{ paddingTop: "40px", paddingBottom: "10px", borderBottom: "1px solid gray" }}><b>Sabtu, 29 Juni 2024</b></td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td style={{ width: 100 }}>08.00 - 10.00</td>
                                    <td style={{ width: 200 }}>Dolan dan Jajan ke Pecinan*</td>
                                    <td style={{ width: 200 }}>Farid Mardhiyanto, Frisca Saputra</td>
                                    <td style={{ width: 200 }}>Pancoran Tea House</td>

                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>10.00 - 12.00</td>
                                    <td>Mendongeng Inklusif: Kesetaraan Lewat Cerita*</td>
                                    <td>Kak Agus</td>
                                    <td>Panggung Taman</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>11.00 - 12.30</td>
                                    <td>Pesta Dongeng: Kisah Negeri Syubidupapap</td>
                                    <td>Rumah Dongeng Pelangi</td>
                                    <td>Pangung Pasar Buku 1</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>13.00 - 14.30</td>
                                    <td>Mengasuh Kasih: Sebuah Percakapan tentang Relasi</td>
                                    <td>Gina S. Noer, Halimah, Yulia Indriati</td>
                                    <td>Panggung Taman</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>14.00 - 15.30</td>
                                    <td>Asyik Jelajah Rempah</td>
                                    <td>Amaranila (Mata Cinta), Jolyn (Ngramu Jamoe)</td>
                                    <td>Panggung Pasar Buku 1</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>14.00 - 16.00</td>
                                    <td>Penulisan Cerita Anak: Memanen Ide untuk Ceritamu*</td>
                                    <td>Reda Gaudiamo</td>
                                    <td>Kedai patjarmerah</td>

                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>16.00 - 17.30</td>
                                    <td>Tukar Cerita Buku Anak: Ini Bacaanku, Mana Bacaanmu?</td>
                                    <td>
                                        Hanna Ameera, Nyala Matahari, Nauli Tangguhati Purbokusumo, Raissa
                                        Danastri Andyta
                                    </td>
                                    <td>Panggung Pasar Buku 1</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>16.30 - 18.00</td>
                                    <td>
                                    Tepung, Gula Ditambah Jahil: Seni membaca ilustrasi The Tjong-Khing & Pengumuman Penghargaan Sastra Anak Indonesia
                                    </td>
                                    <td>
                                        Dr. Riama Maslan Sihombing M.Sn., Herdiana Hakim Ph.D., Roosie
                                        Setiawan, Kak Aio (AyoDI), Pengumuman oleh: Ibu Riris
                                    </td>
                                    <td>Panggung Taman</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>19.00 - 20.30</td>
                                    <td>CINTA: Kenal Diri Supaya Sayang</td>
                                    <td>Zahwa Islami, Adjie Santosoputro, Alexander Thian</td>
                                    <td>Panggung Pasar Buku 1</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>19.30 - 21.00</td>
                                    <td>Panggung Gulali: Misteri di Toko Buku, Kisah-kisah Kain Hitam*</td>
                                    <td>UlangAlik &amp; SEKAT Studio</td>
                                    <td>Kedai patjarmerah</td>

                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td colspan={4} style={{ paddingTop: "40px", paddingBottom: "10px", borderBottom: "1px solid gray" }}><b>Minggu, 30 Juni 2024</b></td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>08.00 - 10.00</td>
                                    <td>Jalan-jalan ke Pasar Baroe*</td>
                                    <td>Jakarta Good Guide</td>
                                    <td>Jelajah (Start dari Panggung 1 - Area Pasar Buku)</td>

                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>10.00 - 12.00</td>
                                    <td>Riang Membaca Lantang</td>
                                    <td>KAYABACA</td>
                                    <td>Kedai patjarmerah</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>11.30 - 13.00</td>
                                    <td>Dari Rak Buku Rumah</td>
                                    <td>
                                        Ernest Junius Wiyanto (Karya Rayakan), Ayuta F. Ladiba (Cilikba), Jogi
                                        Hutabarat (Lingkar Baca), Pradhana (Kutubuku), Elfira Prabandari
                                        (Rembuku)
                                    </td>
                                    <td>Panggung Taman</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>13.00 - 15.00</td>
                                    <td>Ruang Kelas Paman Gery: Belajar Seru Bercerita*</td>
                                    <td>Paman Gery</td>
                                    <td>Kedai patjarmerah</td>

                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>14.00 - 15.30</td>
                                    <td>A Bucket of Beetles</td>
                                    <td>Lunang Pramusesa, Ria Papermoon</td>
                                    <td>Panggung Taman</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>16.00 - 17.30</td>
                                    <td>Nguping Festival: Tukar Cerita Isi Dapur</td>
                                    <td>
                                        Patjarmerah kecil, Ayo Dongeng Indonesia, Festival Gulali, Festival
                                        Tacita, Flores Writers Festival, Sorowako Readers and Writers Festival
                                    </td>
                                    <td>Panggung Taman</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>16.00 - 17.30</td>
                                    <td>Kreator Baca Karya</td>
                                    <td>Bukuku.club</td>
                                    <td>Kedai patjarmerah</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>19.00 - 20.30</td>
                                    <td>Kenal Rumah Penerbitan</td>
                                    <td>PiBo, Kaya baca, GagasMedia, baNANA, Rakata, Haru</td>
                                    <td>Panggung Taman</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td colspan={4} style={{ paddingTop: "40px", paddingBottom: "10px", borderBottom: "1px solid gray" }}><b>Senin, 1 Juli 2024</b></td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>14.30 - 16.30</td>
                                    <td>Ruang Terampil Tangan Kecil: Bikin Kimbap dan Menggambar Tokoh*</td>
                                    <td>Tomo Kidsbook</td>
                                    <td>Kedai patjarmerah</td>

                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>16.30 - 18.00</td>
                                    <td>Main Seni Sama Sama: Olah Rasa, Eksplorasi, dan Refleksi*</td>
                                    <td>Adi Chandra, M.Psi., Psikolog., CGI (Rupaswarajiwa)</td>
                                    <td>Kedai patjarmerah</td>

                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>19.00 - 20.30</td>
                                    <td>Dari Dapur ke Dapur: Perjalanan Bertemu Rasa</td>
                                    <td>
                                        Gustianto Prabowo (Chef Jon), Nuran Wibisono, Debryna Dewi Lumanauw
                                    </td>
                                    <td>Panggung Pasar Buku 1</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>19.00 - 21.00</td>
                                    <td>Waktunya Bapak-bapak</td>
                                    <td>Wahyu Novianto, Ernest Junius Wiyanto, Wirawan Sukarwo</td>
                                    <td>Panggung Taman</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td colspan={4} style={{ paddingTop: "40px", paddingBottom: "10px", borderBottom: "1px solid gray" }}><b>Selasa, 2 Juli 2024</b></td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>15.30 - 17.00</td>
                                    <td>
                                        Meracik &amp; Memijat ala Mbok Nyunyu <br />
                                        (Prarilis buku: Cemani yang Letih)
                                    </td>
                                    <td>
                                        Hamzah Reevi, Ema Yustika Pranata S. Keb (Birth and Bloom), Dig &amp;
                                        Beyond
                                    </td>
                                    <td>Panggung Taman</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>16.00 - 18.00</td>
                                    <td>Zine Lab: Melipat Kertas, Merilis Gagas*</td>
                                    <td>Ika Vantiani</td>
                                    <td>Kedai patjarmerah</td>

                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>19.00 - 20.30</td>
                                    <td>Jakarta! Jakarta!: Yang Kita Belum Tahu</td>
                                    <td>
                                        Dari Halte ke Halte, Indonesia Book Party, Ngojak, Wisata Kreatif
                                        Jakarta, Komunitas Bambu
                                    </td>
                                    <td>Panggung Pasar Buku 1</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>19.00 - 20.30</td>
                                    <td>Menghidupkan Cinta Lewat Cerita</td>
                                    <td>Putri Suhendro, BBB Book Club</td>
                                    <td>Panggung Taman</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td colspan={4} style={{ paddingTop: "40px", paddingBottom: "10px", borderBottom: "1px solid gray" }}><b>Rabu, 3 Juli 2024</b></td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>15.00 - 16.30</td>
                                    <td>
                                        Mewarnai Dunia dengan Keberanian: Belajar dari Si Hebat Krayon Merah
                                    </td>
                                    <td>Katty Kaunang (TBC), Aisha Habir, Chike Tania</td>
                                    <td>Panggung Taman</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>15.30 - 17.00</td>
                                    <td>Healing-healing Hilang Pusing</td>
                                    <td>Rizki Nurzaman, Vishal Dasani</td>
                                    <td>Panggung Pasar Buku 2</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>16.00 - 18.00</td>
                                    <td>Menulis untuk Melepas</td>
                                    <td>Hilda Wardani, Han Jung, Dian Yustianingsih</td>
                                    <td>Panggung Pasar Buku 1</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>16.00 - 18.00</td>
                                    <td dir="ltr">patjarkecil Menjelajah Bahasa Nusantara</td>
                                    <td>Lingkarantarnusa</td>
                                    <td>Pojok Bermain</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>19.00 - 20.30</td>
                                    <td>Seandainya Aku Seorang Kreator</td>
                                    <td>Dayu Hatmanti, Hanin Dhiyah, Aji Nur</td>
                                    <td>Panggung Pasar Buku 1</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>19.00 - 20.30</td>
                                    <td>Jatuh Cinta Seperti di Novel-novel</td>
                                    <td>Indonesia Book Party</td>
                                    <td>Panggung Taman</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td colspan={4} style={{ paddingTop: "40px", paddingBottom: "10px", borderBottom: "1px solid gray" }}><b>Kamis, 4 Juli 2024</b></td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>11.00 - 12.30</td>
                                    <td>Art Therapy untuk dewasa*</td>
                                    <td></td>
                                    <td>Panggung Pasar Buku 2</td>

                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>15.30 - 17.30</td>
                                    <td>Siasat Teror dan Pengisahan</td>
                                    <td>Abi Ardianda</td>
                                    <td>Panggung Taman</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>15.00 - 17.30</td>
                                    <td>Merah Kuning Hijau Makananku</td>
                                    <td>Inge Tumiwa</td>
                                    <td>Panggung Pasar Buku 2</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>15.30 - 17.00</td>
                                    <td>Literasi Inklusi Sejak Dini</td>
                                    <td>KONEKIN</td>
                                    <td>Panggung Pasar Buku 1</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>15.30 - 17.30</td>
                                    <td>Kencan Cerita: Petualangan Dunia Warna-warni*</td>
                                    <td>PiBo</td>
                                    <td>Kedai patjarmerah</td>

                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>19.00 - 20.30</td>
                                    <td>
                                        Membuka Kamar Tertutup: Mendedahkan Bias-Bias Kenormalan Lewat Si
                                        Bengkok karya Ichikawa Saou
                                    </td>
                                    <td>Dewi Anggraeni (Anagram), Marthela (Konekin)</td>
                                    <td>Panggung Pasar Buku 1</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>19.00 - 20.30</td>
                                    <td>Mengempu Tiga Lidah</td>
                                    <td>Ivan Lanin, Yulia Loekito, Ilham Nurwansah</td>
                                    <td>Panggung Pasar Buku 2</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>19.00 - 20.30</td>
                                    <td>Navigasi Kebahagiaan</td>
                                    <td>Henry Manampiring, Rahne Putri, Syahid Muhammad</td>
                                    <td>Panggung Taman</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td colspan={4} style={{ paddingTop: "40px", paddingBottom: "10px", borderBottom: "1px solid gray" }}><b>Jumat, 5 Juli 2024</b></td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>15.00 - 17.00</td>
                                    <td>Pulang ke Rumah Puisi</td>
                                    <td>Theoresia Rumthe</td>
                                    <td>Panggung Pasar Buku 1</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>15.00 - 17.30</td>
                                    <td>Jumpa Editor</td>
                                    <td>Bukune, Baca, Black Swan, Akad, akhirpekan</td>
                                    <td>Panggung Taman</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>15.00 - 17.00</td>
                                    <td>Asah Asuh Imajinasi - Jika Aku Melihat Paus di Langit*</td>
                                    <td>Kidung Larasati</td>
                                    <td>kedai patjarmerah</td>

                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>16.00 - 17.30</td>
                                    <td>Taktik Penceritaan: Mengakrabkan yang Asing</td>
                                    <td>Wulang Sunu, Reda Gaudiamo, Andry Setiawan, Dian Pranasari</td>
                                    <td>Panggung Pasar Buku 2</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>19.00 - 20.30</td>
                                    <td>FYP Dulu, Novel Kemudian</td>
                                    <td>
                                        Syafial Rustama (Bukune), Andri Agus Fabianto (Akad), Putri Safira
                                        Della Del Piera &amp; Hellnyla (Black Swan)
                                    </td>
                                    <td>Panggung Pasar Buku 1</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>19.00 - 21.00</td>
                                    <td>Cerita Besar dari Masa Lalu</td>
                                    <td>Sony Matsuri, Sintia Astarina, Farid Hamka, Frisca Saputra</td>
                                    <td>Panggung Taman</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>19.00 - 21.00</td>
                                    <td>Menangkap Anak-anak Kata</td>
                                    <td>Syahid Muhammad</td>
                                    <td>Pasar Buku 2</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td colspan={4} style={{ paddingTop: "40px", paddingBottom: "10px", borderBottom: "1px solid gray" }}><b>Sabtu, 6 Juli 2024</b></td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>08.00 - 10.00</td>
                                    <td>Wisata Kuliner: Jelajah Spot Kuliner Legendaris Passer Baroe*</td>
                                    <td>Wisata kreatif Jakarta</td>
                                    <td>Jelajah (Start dari Panggung 1 - Area Pasar Buku)</td>

                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>10.00 - 11.00</td>
                                    <td>Bercerita, Bergerak dan Berbahagia Bersama: Sebuah Sesi Dongeng & Yoga Inklusif*</td>
                                    <td>Yogaland &amp; Kurcaci Dongeng</td>
                                    <td>Lobi Timur</td>

                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>10.30 - 12.30</td>
                                    <td>Mili Kecil: Ke Mana Surat-surat Pulang</td>
                                    <td>WB Winata, Hanna Ameera</td>
                                    <td>Panggung Pasar Buku 1</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>11.00 - 12.30</td>
                                    <td>Sembuh Sama-sama</td>
                                    <td>Helo Bagas</td>
                                    <td>Panggung Pasar Buku 2</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>11.00 - 12.30</td>
                                    <td>Workshop Dongeng: Kisah Kaus Kaki Kocak*</td>
                                    <td>Rumah Dongeng Pelangi</td>
                                    <td>Panggung Taman</td>

                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>13.00 - 15.00</td>
                                    <td>Menjadi Detective Cilik: Memecahkan Kasus Bersama Detective.id</td>
                                    <td>
                                        Detectives.id
                                    </td>
                                    <td>Panggung Pasar Buku 2</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>13.00 - 14.30</td>
                                    <td>Berlayar Bersama Ayah</td>
                                    <td>Zulfah Adiputri, Zunda, Matto Haq</td>
                                    <td>Panggung Taman</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>13.00 - 15.00</td>
                                    <td>Menulis Cerita Anak: Bekal Ceritamu Hari Ini</td>
                                    <td>Reda Gaudiamo</td>
                                    <td>Kedai patjarmerah</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>13.30 - 15.00</td>
                                    <td>
                                        Panggung Gulali: Mengenal Lebih Dekat Seni Pertunjukan untuk Penonton
                                        Anak
                                    </td>
                                    <td>
                                        Elisabeth Tita (Waktunya Main), Gema Swaratyagita (Kentjan Ketjil),
                                        Iskandar Muda (SEKAT Studio)
                                    </td>
                                    <td>Panggung Pasar Buku 1</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>15.00 - 16.30</td>
                                    <td>Kado Safara</td>
                                    <td>
                                    Sekar Ayu Adhaningrum (Sekolah Kembang), Asri Saraswati (Dosen Sastra Inggris, FIB UI), Aqmarina Andira
                                    </td>
                                    <td>Panggung Taman</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>15.00 - 17.00</td>
                                    <td>Corat-coret Bikin Buku</td>
                                    <td>Pipit Dwia (Bukuku.club)</td>
                                    <td>Kedai patjarmerah</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>16.00 - 17.30</td>
                                    <td>Resep Membuat Jagat Karya</td>
                                    <td>
                                    Yona Primadesi, Abinaya, Kak Aio (AyoDI), Hazura Indar Faradiba, Laksmi Mayesti  (SMM)
                                    </td>
                                    <td>Panggung Pasar Buku 1</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>16.00 - 17.30</td>
                                    <td>Seandainya Aku Seorang Ilustrator</td>
                                    <td>
                                        Wulang Sunu, Nadya Noor, Sufina Wamina Rizki (Children Illustrator ID)
                                    </td>
                                    <td>Pasar Buku 2</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>19.00 - 20.30</td>
                                    <td>Anak-anak Gerhana</td>
                                    <td>Yusi Avianto, Olive Hateem, Nezar Patria</td>
                                    <td>Pasar Buku 2</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>19.00 - 20.30</td>
                                    <td>UGD: Unit Galau Dewasa</td>
                                    <td>Pishi, Indra Dwi Prasetyo, Wisnu Kumoro</td>
                                    <td>panggung taman</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>19.30 - 21.00</td>
                                    <td>Luka-luka Lini Masa</td>
                                    <td>Kalis Mardiasih, Intan Khasanah (Kitabisa,com), Hanna Al Rasyid</td>
                                    <td>Pasar Buku 1</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>19.30 - 21.00</td>
                                    <td>
                                        Panggung Gulali: Petualangan Oya dan Oye: Mencari Tanaman Ajaib &amp;
                                        Waktunya Main - Sketch's Singing*
                                    </td>
                                    <td>Kentjan Ketjil x Rude Ruth, Waktunya Main</td>
                                    <td>Kedai patjarmerah</td>

                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td colspan={4} style={{ paddingTop: "40px", paddingBottom: "10px", borderBottom: "1px solid gray" }}>Minggu, 7 Juli 2024</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>08.00 - 10.00</td>
                                    <td>Jelajah Weltevreden*</td>
                                    <td>Jakarta Good Guide</td>
                                    <td>Pasar Buku 1</td>

                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>10.00 - 11.00</td>
                                    <td>Bercerita, Bergerak dan Berbahagia Bersama: Sebuah Sesi Dongeng & Yoga Inklusif*</td>
                                    <td>Yogaland &amp; Kurcaci Dongeng</td>
                                    <td>Lobi Timur</td>

                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>11.00 - 13.00</td>
                                    <td>Maju Ketawa Mundur Ketawa</td>
                                    <td>Sakdiyah Ma'ruf</td>
                                    <td>Pasar Buku 1</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>11.00 - 12.30</td>
                                    <td>Pesta Raya Cerita</td>
                                    <td>Ernest Junius Wiyanto (Karya Rayakan)</td>
                                    <td>Panggung Pasar Buku 2</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>11.00 - 13.00</td>
                                    <td>Dongeng Paman Gery: Tumbuh dengan Dongeng</td>
                                    <td>Paman Gery</td>
                                    <td>Panggung Taman</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>11.00 - 13.00</td>
                                    <td>
                                        Read-A-Loud &amp; Upcycling Workshop - Membuat Mainan dari Kardus*
                                    </td>
                                    <td>Mission Giga</td>
                                    <td>Kedai patjarmerah</td>

                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>13.30 - 15.00</td>
                                    <td>
                                        Membaca Arah Angin: Regenerasi Cerita dalam Dunia Anak, Remaja, dan
                                        Keluarga
                                    </td>
                                    <td>
                                    Herdiana Hakim Ph.D., Reda Gaudiamo, Anggia Kharisma, Melia Emmy (Bintang Kecil), Djokolelono, Natasha Chairani, Najelaa Shihab
                                    </td>
                                    <td>Panggung Pasar Buku 1</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>13.00 - 14.30</td>
                                    <td>Uji Tangkas Bahasa Indonesiamu</td>
                                    <td>Narabahasa, Anagram, Haru, baNANA, akhirpekan</td>
                                    <td>Pojok Bermain</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>13.00 - 14.30</td>
                                    <td>Telisik Puisi</td>
                                    <td>Theoresia Rumthe, Muhaimin Nurrizqy</td>
                                    <td></td>
                                    
                                    
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>13.30 - 15.30</td>
                                    <td dir="ltr">Bumi Bunyi dan Suara Dapur</td>
                                    <td dir="ltr">Bumi Bunyi</td>
                                    <td>Kedai patjarmerah</td>

                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>14.00 - 15.30</td>
                                    <td>Perjamuan Terang Bulan</td>
                                    <td>Zulfa Adiputri, Matto Haq</td>
                                    <td>Panggung Taman</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>16.00 - 18.00</td>
                                    <td>Sayap-sayap</td>
                                    <td>
                                        Ayo Dongeng Indonesia, RDP, Reda Gaudiamo, Yovie Music School, Open
                                        Mic patjarmerah kecil, Theoresia Rumthe, William Sihombing, Bintang
                                        Kecil, Sakdiyah Ma'ruf
                                    </td>
                                    <td>Pasar Buku 1</td>
                                </tr>
                                <tr style={{ verticalAlign: "top" }}>
                                    <td>19.30 - 21.00</td>
                                    <td>Ibadah Puisi untuk Joko Pinurbo</td>
                                    <td>
                                        <div>
                                            Reda Gaudiamo, Theoresia Rumthe, Willian Sihombing, Beni Satrio
                                        </div>
                                    </td>
                                    <td>Panggung Pasar Buku 1</td>
                                </tr>
                                <tr style={{ borderTop: "1px dotted gray" }}>
                                    <td colSpan={4}><b><i>* acara berbayar</i></b></td>
                                </tr>
                                <tr>
                                    <td colSpan={4}>
                                        Mata acara akan terus mengalami pemutakiran secara berkala di patjarmerah.com/acarapatjarkecil. Pendaftaran dibuka 20 Juni 2024 melalui <a href="https://patjarmerah.com/patjarkecil">patjarmerah.com/patjarkecil</a>
                                        {/* Pendaftaran dibuka mulai 20 Juni 2024 melalui <a href="https://patjarmerah.com/patjarkecil">patjarmerah.com/patjarkecil</a> */}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>

    </PageWrapper>
}