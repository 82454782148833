export const mapper = {
    home : {
        en : "/",
        id : "/"
    },
    "en-home" : {
        en : "/en/home",
        id : "/"
    },
    "book-store" : {
        id : "/patjar-pilihan",
        en : "/patjar-pilihan",
    },
    "my-session" : {
        id : "/akademi-patjarmerah",
        en : "/akademi-patjarmerah",
        // en : "/en/sessions"
    },
    "donation" : {
        id : "/galang-patjar"
    },
    "papermoon-promotion" : {
        id: "/papermoon",
        en: "/en/papermoon"
    },
    "kata-patjar" : {
        id : "/kata-patjar"
    },
    "post-payment" : {
        id : "/post-payment",
        en : "/post-payment"
    },
    "support" : {
        id : "/support",
        en : "/support"
    }, "rumasuar" : {
        id : "/rumasuar/detail",
        en : "/rumasuar/detail"
    }, "rumasuar-promotion" : {
        id : "/rumasuar",
        en : "/rumasuar"
    }, "fba" : {
        id : "/festivalbukuasia",
        en : "/festivalbukuasia"
    }, "mencarinaskah" : {
        id : "/mencarinaskah",
        en : "/mencarinaskah"
    }, "surgaanjingliar" : {
        id : "/surgaanjingliar",
        en : "/surgaanjingliar"
    }, "surgaanjingliar-detail" : {
        id : "/surgaanjingliar/detail",
        en : "/surgaanjingliar/detail"
    }, "perjamuan-pulang" : {
        id : "/perjamuan-pulang",
        en : "/perjamuan-pulang",
    }, "festival-pembaca-indonesia" : {
        id: "/banana",
        en: "/banana",
    }, "gincu" : {
        id: "/gincu",
        en: "/gincu",
    }, "konserdongeng" : {
        id: "/konserdongeng",
        en: "/konserdongeng"
    }, "patjarkita" : {
        id: "/patjarkita",
        en: "/patjarkita"
    }, "bandung" : {
        id: "/brf",
        en: "/brf"
    }, "sessions" : {
        id: "/solo",
        en: "/solo"
    }, "patjarmerah-kecil" : {
        id: "/patjarkecil",
        en: "/patjarkecil"
    }, "sayap" : {
        id: "/sayap",
        en: "/sayap"
    }, "request-delete" : {
        id: "/request-delete",
        en: "/request-delete"
    }, "acarapatjarkecil" : {
        id: "/acarapatjarkecil",
        en: "/acarapatjarkecil"
    }, "radityadika" : {
        id: "/radityadika",
        en: "/radityadika"
    }, "renik" : {
        id: "/renik",
        en: "/renik"
    },
}

export const translatePath = (page, lang, routeParams) =>{

    // let antiLang = lang === "en" ? "id" : "en"

    // if(lang === "en" && page === "home"){
    //     return "/en/home"
    // }

    let suffix = "";

    console.log("There is route params " + routeParams)

    if(routeParams){
        suffix = "/" + routeParams
    }

    if(mapper[page]){
        if(mapper[page][lang]){
            return mapper[page][lang] + suffix
        }
    }

    return mapper.home[lang] + suffix

}
