import ApiRequest from "../util/ApiRequest";
import GlobalData from "../util/GlobalData";

export default class User {

    login = async (user) =>{

        let userData = await ApiRequest.set("/login", "POST", {
            email : user.email,
            password : user.password
        });

        GlobalData.user = userData

        if(userData.token) {
            GlobalData.token = userData.token
        }

        return userData

    }

    register = async  (user) => {
        let response = await ApiRequest.set("/register", "POST", {
            email : user.email,
            full_name: user.full_name,
            password : user.password,
            gender: user.gender,
            date_of_birth: user.date_of_birth,
            phone_num: user.phone_num
        });

        return response;
    }

    updateUser = async (user) =>{
        delete user.token
        let response = await ApiRequest.set(`/user/${user.id}`, "PUT", user)
        return response
    }

    tokenLogin = async()=>{
        let userData = await ApiRequest.set(`/login`, "GET");
        GlobalData.user = userData
        console.log(GlobalData.user)
        return userData
    }

    forgotPassword = async(email) => {
        return await ApiRequest.set('/recover/appeal', 'POST', {email})
    }

    changePassword = async(password, validationCode) => {
        console.log('/recover/confirm/'+validationCode)
        console.log({password})

        return await ApiRequest.set('/recover/confirm/'+validationCode, 'POST', {password})
    }

    requestDelete = async(rejectReason) => {
        return await ApiRequest.set('/user/requestDeletion', 'POST', {
            rejectReason
        })
    }

}
