import React, {useEffect, useRef, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageWrapper from "./PageWrapper";
import {Button} from "react-bootstrap";
import {isMobile} from "react-device-detect";
import {AiOutlineCalendar, AiOutlineWarning} from "react-icons/ai";
import Palette from "../../util/Palette";
import {MdError, MdLocationOn} from "react-icons/md";
import {TiLocationOutline, TiTicket} from "react-icons/ti";
import {useCookies} from "react-cookie";
import Session from "../../models/Session";
import PapermoonTicketNode from "../reusable/PapermoonTicketNode";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import PaketCard from "../reusable/PaketCard";

export default function SurgaAnjingLiarDetail(props) {

    const descriptionRef = useRef(null);
    const buyBookRef = useRef(null);
    const buyBookAndTicketRef = useRef(null);
    const [isLanguageInfoOpen, setIsLanguageInfoOpen] = useState(true);
    const [cookie, setCookie, removeCookie] = useCookies()
    const isEN = cookie.lang !== "id";
    const [dimensions, setDimensions] = useState({width: window.innerWidth, height: window.innerHeight});
    const [isWatchLaunchingBtnShown, setWatchLaunchingBtnShown] = useState(false);
    const [isWatchIntimateBtnShown, setWatchIntimateBtnShown] = useState(false);
    const [intimateId, setIntimateId] = useState(null);

    const handleResize = () => {
        console.log(window.innerHeight)
        setDimensions({width: window.innerWidth, height: window.innerHeight})
    }

    useEffect(() => {

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <PageWrapper
            headerActive={'perjamuan-pulang'}
        >
            <Container fluid style={{
                // background: `url('/image/rumasuar-decoration.jpeg')`,
                // backgroundRepeat: "no-repeat",
                // backgroundSize: dimensions.width < 1500 ? '0px 0px' : `200px 544px`,
                // backgroundPosition: 'calc(100% - 100px) 70%',
                // backgroundAttachment: 'fixed'
            }}>
                <Container style={{
                    paddingTop: '1em', marginBottom: 70,
                }}>
                    <Modal
                        size="sm"
                        onHide={() => {
                            setIsLanguageInfoOpen(false)
                        }}
                        show={cookie.lang && isEN && isLanguageInfoOpen}
                    >
                        <Modal.Header closeButton>
                            Information
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                This page is only available in Indonesian.
                            </div>
                        </Modal.Body>

                    </Modal>
                    {/*<Row style={{display: 'flex', justifyContent: 'left'}}>*/}
                    {/*    <img src={'/image/papermoon-header.jpg'} style={{width: '100%', maxWidth: 800}}/>*/}
                    {/*</Row>*/}


                    {/*<Row style={{display: 'flex', justifyContent: 'left'}}>*/}
                    {/*    <TiLocationOutline color={'#3EC200'} size={22}*/}
                    {/*                       style={{marginRight: 10, marginTop: 2}}/>*/}
                    {/*    <div style={{fontWeight: 'bold'}}>EVENT ONLINE</div>*/}

                    {/*    <div style={{height: 30, width: 2, backgroundColor: '#ebebeb', marginLeft: 15, marginRight: 15}}/>*/}

                    {/*    <TiTicket color={'#3EC200'} size={22}*/}
                    {/*              style={{marginRight: 10, marginTop: 2}}/>*/}
                    {/*    <div style={{fontWeight: 'bold'}}>{isEN ? 'US$ 10' : 'Rp100.000,-'}</div>*/}
                    {/*    <div style={{marginLeft: 3, color: 'red', opacity: isEN ? 1 : 0}}>*</div>*/}
                    {/*</Row>*/}

                    {/*{isEN ?*/}
                    {/*    <Row style={{*/}
                    {/*        display: 'flex',*/}
                    {/*        justifyContent: 'left',*/}
                    {/*        fontSize: 12,*/}
                    {/*        color: '#878787',*/}
                    {/*        marginTop: 5*/}
                    {/*    }}>*/}
                    {/*        /!*<Col style={{color: 'red', fontSize: 14, marginRight: 5, textAlign: 'right', paddingRight: 0}} xs={1} sm={2}>*!/*/}
                    {/*        /!*    **!/*/}
                    {/*        /!*</Col>*!/*/}
                    {/*        <Col style={{textAlign: 'left', }}>*/}
                    {/*            <span style={{marginRight: 5, color: 'red'}}>*/}
                    {/*                **/}
                    {/*            </span>*/}

                    {/*The price will be converted into rupiah with an exchange rate of 1 USD = IDR*/}
                    {/*15,000*/}
                    {/*        </Col>*/}
                    {/*        /!*<div>*</div>*!/*/}

                    {/*    </Row> : null*/}
                    {/*}*/}

                    <Row style={{display: 'flex', justifyContent: 'center',  marginBottom: 25}}>
                        <Col md={12} style={{
                            display : "flex",
                            alignItems : "center",
                            justifyContent :"center",
                            padding : isMobile ? 0 : null
                        }}>
                            <img src={require("../../asset/perjamuan-pulang/header.png")}
                                 style={{
                                     width: '100%',
                                     maxWidth : isMobile ? null : 900,
                                     borderRadius: 5,
                                     objectFit: "contain",
                                     // maxHeight: isMobile ? null : "50vh"
                                 }}/>

                        </Col>
                    </Row>

                    {/*<div style={{marginTop: 15, marginBottom: 35, textAlign: 'left'}}>*/}

                    {/*    <p style={{*/}
                    {/*        fontSize: '1.1em',*/}
                    {/*        fontWeight: 'bolder'*/}
                    {/*    }}>{isEN ? 'TICKET PRICE' : 'HARGA TIKET'}</p>*/}

                    {/*<div style={{marginTop: -5}}>*/}
                    {/*    {isEN ? 'USD 10' : 'IDR 100.000'} {isEN ? <span style={{color: 'red'}}>*</span> : null} {isEN ? 'or' : 'atau'} Barter*/}
                    {/*</div>*/}

                    {/*{isEN ?*/}
                    {/*    <div style={{fontSize: 12, color: 'grey'}}>*/}
                    {/*        <span style={{color: 'red', marginRight: 3}}>*</span>*/}
                    {/*        The price will be converted into rupiah with an exchange rate of 1 USD = IDR 15,000*/}
                    {/*    </div> : null*/}
                    {/*}*/}
                    {/*</div>*/}

                    <Row style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={{
                            marginTop: 15, textAlign: 'left', maxWidth: 900,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "60px 60px",
                            backgroundPosition: "70% 0%",
                        }}>

                            <p style={{
                                fontSize: '1.3em',
                                fontWeight: 'bolder',

                            }} ref={descriptionRef}>DESKRIPSI</p>

                            <div style={{
                                marginBottom: 35, textAlign: 'left',
                            }}>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left',

                                }}>JUDUL</p>

                                <div style={{marginTop: -5,}}>
                                    Jalan Panjang untuk Pulang
                                </div>

                            </div>

                            <div style={{marginBottom: 35, textAlign: 'left'}}>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left',

                                }}>KARYA</p>

                                <div style={{marginTop: -5,}}>
                                    Agustinus Wibowo
                                </div>

                            </div>

                            <div style={{marginBottom: 35}}>
                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left',

                                }}>
                                    SINOPSIS
                                </p>

                                Setiap tulisan perjalanan Agustinus Wibowo selalu membawa pembacanya bukan hanya pada
                                perjalanan fisik belaka, melainkan juga perjalanan batin dalam mencari makna “pulang”.
                                Dari setiap tempat yang Agustinus kunjungi, kita pun bisa mendapatkan refleksi diri yang
                                sanggup menjawab pertanyaan-pertanyaan kita tentang identitas. Sebab, kepulangan bukan
                                hanya soal kembali ke tempat yang kita sebut rumah. Kepulangan yang hakiki juga berarti
                                kembali pada diri sendiri: menemukan identitas diri dan berdamai dengannya.
                                <br/>
                                <br/>
                                Dalam rangka merayakan makna “pulang” itulah, patjarmerah menggelar #patjaristimewa
                                “Perjamuan Pulang” bersama Agustinus Wibowo dan rekan pejalan lainnya. Untuk dapat
                                bergabung dalam perjamuan ini, patjarboekoe bisa memilih satu dari beberapa paket
                                #patjaristimewa yang tersedia.

                            </div>

                            <div style={{marginBottom: 35, textAlign: 'left'}} ref={buyBookRef}>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left',

                                }}>
                                    PAKET <span style={{fontSize: '.9em', color: 'grey'}}>(Tekan paket untuk mengetahui detailnya)</span>
                                </p>

                                <Row>
                                    {
                                        [{
                                            name: "Paket A (Stok 10)",
                                            price: "Rp500.000",
                                            url: "https://shopee.co.id/-Paket-A-Jalan-Panjang-Untuk-Pulang-Agustinus-Wibowo-Gramedia-i.34379470.7577838049",
                                            content: <>
                                                <div style={{

                                                    paddingTop: 3,
                                                    paddingLeft: 20,
                                                    paddingRight: 20,
                                                    color: 'grey',
                                                    paddingBottom: 10
                                                }}>
                                                    - 1 buku Titik Nol bertanda-tangan penulis <br/>
                                                    - 1 buku Selimut Debu bertanda-tangan penulis <br/>
                                                    - 1 buku Garis Batas bertanda-tangan penulis <br/>
                                                    - 1 buku Jalan Panjang untuk Pulang bertanda-tangan penulis <br/>
                                                    - Sesi virtual “Perjamuan Pulang” bersama Agustinus Wibowo dan rekan
                                                    pejalan lainnya <br/>
                                                    - 2 tiket #akademipatjarmerah untuk Seri Tulisan perjalanan bersama Agustinus Wibowo, yaitu Kelas Dasar I dan Kelas Dasar II.<br/>
                                                </div>
                                            </>,
                                            src: require("../../asset/perjamuan-pulang/PaketA.jpeg")
                                        }, {
                                            name: "Paket B (Stok 15)",
                                            price: "Rp350.000",
                                            url: "https://shopee.co.id/-Paket-B-Jalan-Panjang-Untuk-Pulang-Agustinus-Wibowo-Gramedia-i.34379470.6677838388",
                                            content: <>
                                                <div style={{
                                                    paddingTop: 3,
                                                    paddingLeft: 20,
                                                    paddingRight: 20,
                                                    color: 'grey',
                                                    paddingBottom: 10
                                                }}>
                                                    - 1 buku Jalan Panjang untuk Pulang bertanda-tangan penulis<br/>
                                                    - 2 tiket #akademipatjarmerah untuk Seri Tulisan perjalanan bersama Agustinus Wibowo, yaitu Kelas Dasar I dan Kelas Dasar II.<br/>
                                                    - Sesi virtual “Perjamuan Pulang” bersama Agustinus Wibowo dan rekan
                                                    pejalan lainnya.<br/>
                                                </div>
                                            </>,
                                            src: require("../../asset/perjamuan-pulang/PaketB.jpeg")
                                        }, {
                                            name: "Paket C (Stok 15)",
                                            price: "Rp250.000",
                                            url: "https://shopee.co.id/-Paket-C-Jalan-Panjang-Untuk-Pulang-Agustinus-Wibowo-Gramedia-i.34379470.9411397153",
                                            content: <>
                                                <div style={{
                                                    paddingTop: 3,
                                                    paddingLeft: 20,
                                                    paddingRight: 20,
                                                    color: 'grey',
                                                    paddingBottom: 10
                                                }}>
                                                    - 1 buku Jalan Panjang untuk Pulang bertanda-tangan penulis<br/>
                                                    - 1 tiket #akademipatjarmerah untuk Seri Tulisan perjalanan bersama Agustinus Wibowo. Pilih salah satu: Kelas Dasar I atau Kelas Dasar II.<br/>
                                                    - Sesi virtual “Perjamuan Pulang” bersama Agustinus Wibowo dan rekan
                                                    pejalan lainnya.<br/>
                                                </div>
                                            </>,
                                            src: require("../../asset/perjamuan-pulang/PaketC.jpeg")
                                        }, {
                                            name: "Paket D (Stok 10)",
                                            price: "Rp130.000",
                                            url: "https://shopee.co.id/-Paket-D-Jalan-Panjang-Untuk-Pulang-Agustinus-Wibowo-Gramedia-i.34379470.3477927380",
                                            content: <>
                                                <div style={{
                                                    paddingTop: 3,
                                                    paddingLeft: 20,
                                                    paddingRight: 20,
                                                    color: 'grey',
                                                    paddingBottom: 10
                                                }}>
                                                    - 1 buku Jalan Panjang untuk Pulang bertanda-tangan penulis<br/>
                                                    - Sesi virtual “Perjamuan Pulang” bersama Agustinus Wibowo dan rekan
                                                    pejalan lainnya.
                                                </div>
                                            </>,
                                            src: require("../../asset/perjamuan-pulang/PaketD.jpeg")
                                        }, {
                                            name: "Paket E (Stok 90)",
                                            price: "Rp270.000",
                                            url: "https://patjarmerah.com/akademi-patjarmerah/273",
                                            content: <>
                                                <div style={{
                                                    paddingTop: 3,
                                                    paddingLeft: 20,
                                                    paddingRight: 20,
                                                    color: 'grey',
                                                    paddingBottom: 10
                                                }}>
                                                    - 2 tiket #akademipatjarmerah untuk Seri Tulisan perjalanan bersama Agustinus Wibowo, yaitu Kelas Dasar I dan Kelas Dasar II.<br/>
                                                    - Sesi virtual “Perjamuan Pulang” bersama Agustinus Wibowo dan rekan pejalan lainnya.<br/>
                                                </div>
                                            </>,
                                            src: require("../../asset/perjamuan-pulang/PaketE.jpeg")
                                        }, {
                                            name: "Paket F (Stok 90)",
                                            price: "Rp150.000",
                                            url: "https://patjarmerah.com/akademi-patjarmerah/274",
                                            content: <>
                                                <div style={{
                                                    paddingTop: 3,
                                                    paddingLeft: 20,
                                                    paddingRight: 20,
                                                    color: 'grey',
                                                    paddingBottom: 10
                                                }}>
                                                    - 1 tiket #akademipatjarmerah untuk Seri Tulisan perjalanan bersama Agustinus Wibowo. Pilih salah satu: Kelas Dasar I atau Kelas Dasar II.<br/>
                                                    - Sesi virtual “Perjamuan Pulang” bersama Agustinus Wibowo dan rekan pejalan lainnya.
                                                </div>
                                            </>,
                                            src: require("../../asset/perjamuan-pulang/PaketF.jpeg")
                                        }].map((obj, key) => {
                                            return <PaketCard
                                                name={obj.name}
                                                price={obj.price}
                                                src={obj.src}
                                                url={obj.url}
                                                content={obj.content}
                                            />
                                        })
                                    }

                                </Row>
                            </div>

                            <div
                                style={{
                                    // marginTop: 15,
                                    marginBottom: 35,
                                    // paddingBottom: 15,
                                    // paddingTop: 15,
                                    textAlign: 'left',
                                }}>

                                {/*<div style={{*/}
                                {/*    marginBottom: 35, textAlign: 'left',*/}

                                {/*}}>*/}

                                {/*    <p style={{*/}
                                {/*        fontSize: '1.1em',*/}
                                {/*        fontWeight: 'bolder',*/}
                                {/*        textAlign: 'left',*/}

                                {/*    }}>Waktu</p>*/}

                                {/*    <div style={{marginTop: -5,}}>*/}
                                {/*        - 25-30 Januari 2020 - Prapesan<br/>*/}
                                {/*        - 1-12 Februari 2020 - Pengiriman Tiket Kelas Virtual<br/>*/}
                                {/*        - 14 Februari 2020 - Kelas Menulis Virtual Bersama Kelas Menulis Adimas Immanuel<br/>*/}
                                {/*    </div>*/}

                                {/*</div>*/}


                            </div>

                            {/*{isEN ? "Is Supported By patjarmerah, Kotasis, dan Intellivent" : "Didukung oleh patjarmerah, Kotasis, dan Intellivent"}*/}

                        </div>
                    </Row>
                </Container>

            </Container>
        </PageWrapper>
    )
}
