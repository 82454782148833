import {Button, Col, Container, Modal, Row, Spinner} from "react-bootstrap";
import React, {Component, useState} from "react";

import 'moment/locale/id'
import Flag from "react-flags";

import {FaGlobe} from "react-icons/fa";
import {useCookies} from "react-cookie";

export default function PaymentConfirmationModal(props) {

    const [cookie, setCookie, removeCookie] = useCookies(["token"])

    return <Modal
        size="md"
        centered
        show={props.show}
        onHide={() => props.hide()}
    >
        <Modal.Header>
            Select Language
        </Modal.Header>

        <Container>
            <Row>
                <Col
                    onClick={()=>{
                        setCookie("lang", "id", {path: "/", maxAge: 60 * 60 * 24 * 30})
                        props.hide()
                    }}
                    xs={6} md={6} style={{display : "flex", flexDirection : "column", padding : "2em", alignItems :"center", cursor : "pointer"}}>
                    <div style={{
                        width : "100%",
                        paddingTop : "100%",
                        borderRadius : "50%",
                        backgroundPosition : "center",
                        backgroundImage : `url(${require("../../asset/id.svg")})`,
                        backgroundRepeat : "no-repeat",
                        border : "1px solid #ccc",
                        backgroundSize : "cover",
                    }}/>
                    <span style={{marginTop : "1em"}}>
                        Indonesia
                    </span>
                </Col>
                <Col
                    onClick={()=>{
                        setCookie("lang", "en", {path: "/", maxAge: 60 * 60 * 24 * 30})
                        props.hide()
                    }}
                    xs={6} md={6} style={{display : "flex", flexDirection : "column", padding : "2em", alignItems :"center", cursor : "pointer"}}>
                    <div style={{
                        width : "100%",
                        paddingTop : "100%",
                        borderRadius : "50%",
                        backgroundPosition : "center",
                        backgroundImage : `url(${require("../../asset/GB.svg")})`,
                        backgroundRepeat : "no-repeat",
                        backgroundSize : "cover",
                        border : "1px solid #ccc",
                    }}/>
                    <span style={{marginTop : "1em"}}>
                        English
                    </span>
                </Col>
            </Row>
        </Container>
    </Modal>

}
