import sponsor0 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/00. Pos Bloc New.png"
import sponsor1 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/01. Sponsor/01. SMM.png"
import sponsor2 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/01. Sponsor/02. Sekolah.mu.png"
import sponsor3 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/01. Sponsor/03. Keluarga Kita.png"
import sponsor4 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/01. Sponsor/04. Grafie.png"
import sponsor5 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/01. Sponsor/05._Count_Three.PNG"
import sponsor6 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/01. Sponsor/06._Hello_Panda_New_2019-01.png"
import sponsor7 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/01. Sponsor/FIX NEW LOGO MEIJI WELLNESS.png"
import sponsor8 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/01. Sponsor/00_Logo_UN_Indonesia_-_2021-05 (1).png"
import sponsor9 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/01. Sponsor/LOGOGRAM INDONESIANA HITAM.png"

import komunitas1 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/ABC Reading Club.jpeg"
import komunitas2 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/AYODI.png"
import komunitas3 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Birth and Bloom.png"
import komunitas4 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Bookhive.PNG"
import komunitas5 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Dig and Beyond.png"
import komunitas6 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Flores_Writers_Festival.png"
import komunitas7 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Humi.jpg"
import komunitas8 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Indonesia Book Party - Green Transparent.png"
import komunitas9 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Jakarta Good Guide.png"
import komunitas10 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Karyaraya 2024.png"
import komunitas11 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Kitabisa_Blue_Square.png"
import komunitas12 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Klub Buku Nyala Matahari (logo).png"
import komunitas13 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/LOGO LITTLE QUOKKA.png"
import komunitas14 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/LOGO RDP TRANSPARANT (1) (1).png"
import komunitas15 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Logo BBB Book Club.jpeg"
import komunitas16 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Logo Bukuku.Club-1 (1).png"
import komunitas17 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Logo Detectives ID.png"
import komunitas18 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Logo Green Meadow.png"
import komunitas19 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Logo Konekin.png"
import komunitas20 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Logo Kurcaci Dongeng.png"
import komunitas21 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Logo Lingkar Baca (Circle).png"
import komunitas22 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Logo Narabahasa.png"
import komunitas23 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Logo NgoJak (1).png"
import komunitas24 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Logo Pesta Literasi Indonesia.png"
import komunitas25 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/MILI KECIL FONT.png"
import komunitas26 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Mata Cinta.jpeg"
import komunitas27 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Ngramu Jamoe.jpeg"
import komunitas28 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Paman Gery 2.jpeg"
import komunitas29 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Papermoon Puppet Theatre.jpg"
import komunitas30 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Provisi Mandiri Pratama Logo (1).png"
import komunitas31 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/RSJ BLACK.png"
import komunitas32 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Rembuku.PNG"
import komunitas33 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/SEKAT Studio.png"
import komunitas34 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/SRWF 2023.PNG"
import komunitas35 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/TaCita.png"
import komunitas36 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Ulang Alik (Blue).png"
import komunitas37 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/WISATA KREATIF JAKARTA.png"
import komunitas38 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Wahana Kreator.png"
import komunitas39 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/Waktunya Main.PNG"
import komunitas40 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/cilikba bulat.png"
import komunitas41 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/dari halte ke halte.png"
import komunitas42 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/logo KawanPuan.png"
import komunitas43 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/Gulali.JPEG"
import komunitas44 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/Kamar Kata-Kata.png"
import komunitas45 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/Mai (1).png"
// import komunitas46 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/Miles New Logo White.png"
import komunitas47 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/PS2_Miles 01.png"
import komunitas48 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/Save lagu anak.jpeg"
import komunitas49 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/Sekolah kambang.PNG"
import komunitas50 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/visinema group_black.png"

import komunitas51 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/add2/01. JAKARTA_EMBASSY_OF_SWEDEN_RGB.png"
import komunitas52 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/add2/Bumi Bunyi.JPG"
import komunitas53 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/add2/Debryna.jpeg"
import komunitas54 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/add2/Ennoia-Game-logo-color.png"
import komunitas55 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/add2/Flores Festival.png"
import komunitas56 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/add2/Kutubuku.jpeg"
import komunitas57 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/add2/Logo BRD.png"
import komunitas58 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/add2/Logo Kentjan Ketjil.png"
import komunitas59 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/add2/Logo Kurcaci Dongeng (1).png"
import komunitas60 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/add2/Logo RudeRuth (Black).png"
import komunitas61 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/add2/LOGO SBI (1).PNG"
import komunitas62 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/add2/Logo Yovie Music School .jpeg"
import komunitas63 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/add2/Omah jangan diam terus.jpg"
import komunitas64 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/add2/RUMAH CERITA PNG (1).png"
import komunitas65 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/add2/Sahabat Lampu Baca (1).jpeg"
import komunitas66 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/add2/tukutuku-a72c-02.png"
import komunitas67 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/add2/Voice.jpg"
import komunitas68 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/addition/add2/Yogaland.jpg"

import komunitas69 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/new/Bintang Kecil - Baru (2).png"
import komunitas70 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/new/Children Illustrator ID.png"
import komunitas71 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/new/Forum TBM.jpeg"
import komunitas72 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/new/Glam Rock Kitchen.png"
import komunitas73 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/new/Kemendikbud - Baru (1).png"
import komunitas74 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/new/LOGO Kecil.png"
import komunitas75 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/new/LOGO-01.png"
import komunitas76 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/new/Logo FDII (1).png"
import komunitas77 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/new/Logo sanggar anak akar.jpg"
import komunitas78 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/new/Miwa.png"
import komunitas79 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/new/PhotoRoom-20230626_084150.png"
import komunitas80 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/new/Rumah Tjempaka (1).png"
import komunitas81 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/02. komunitas/new/What Asha Reads.png"

import media1 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/Bahana FM.png"
import media2 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/Broadwayworld Indonesia logo-1.png"
import media3 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/LOGO AUTOMOTO.png"
import media4 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/LOGO NEVERLAND (HORISONTAL)-03.png"
import media5 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/Logo FAMZ Guide - Color.png"
import media6 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/Logo Jayakarta.news.png"
import media7 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/Logo-05-05.png"
import media8 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/Logo-FTNews2.png"
import media9 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/Mommies Daily.PNG"
import media10 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/Omong-omong.PNG"
import media11 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/TVRI World.png"
import media12 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/Travelmaker Indonesia.png"

import media13 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/new/Buruanco.png"
import media14 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/new/Context Logo.png"
import media15 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/new/Eventbanget.png"
import media16 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/new/FEED WMNLyfe11.png"
import media17 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/new/Fomomedia.jpg"
import media18 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/new/Hypeabisid-01.png"
import media19 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/new/Indopop.png"
import media20 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/new/Klub Buku Narasi.jpg"
import media21 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/new/LOGO CIA GRAD.png"
import media22 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/new/Logo Diajeng Hitam-8.png"
import media23 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/new/Logo Koran Bisnis Indonesi.png"
import media24 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/new/Logo tirto biru tua.png"
import media25 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/new/M Bloc.jpg"
import media26 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/new/PARENTSQUAD LOGO 5 (2) (3).png"
import media27 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/new/Popmama.com Purple (Original)_Transparent Background.png"
import media28 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/new/Warung Kambiri.png"
import media29 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/new/logo-fimela putih.png"
import media30 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/new/logo-fimela.png"
import media31 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/03. media partner/new/logo_haibunda_(1).png"

import penerbit1 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/-Utama- Logo GagasMedia - Versi Hitam.png"
import penerbit2 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/20210801_AKHIRPEKAN_LOGOMARK 1.png"
import penerbit3 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/20210801_AKHIRPEKAN_WORDMARK 1.png"
import penerbit4 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/Bookabook_logo_2.png"
import penerbit5 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/Bukune.png"
import penerbit6 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/D9D29AB0-617E-4CA5-BA6D-868D172D23C2.png"
import penerbit7 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/Harulogo2.png"
import penerbit8 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/Hires-logo-tomo.png"
import penerbit9 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/Indonesia Tera.png"
import penerbit10 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/Kawan Pustaka.jpg"
import penerbit11 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/Kayabaca Logo Full - Green_Vector (1).png"
import penerbit12 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/Logo G Gradien.png"
import penerbit13 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/Logo Penerbit Infermia.png"
import penerbit14 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/Logo Shira Media.png"
import penerbit15 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/Logo Teroka Hitam (3).png"
import penerbit16 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/Logo blackswan  (1).png"
import penerbit17 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/Logo lingkar antarnusa.png"
import penerbit18 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/NARATAMA LOGO FIX1.png"
import penerbit19 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/Pibo Logo.png"
import penerbit20 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/Rakata_Normal.png"
import penerbit21 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/gpu.png"
import penerbit22 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/logo akad berwarna.png"
import penerbit23 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/logo kobam full.png"
import penerbit24 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/penerbit-baca-logo.png"
import penerbit25 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/anagram.png"
import penerbit26 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/Logo_Penerbit Gorga.png"
import penerbit27 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/Mission Giga Logo (1) (1).png"

import penerbit28 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Alvabet.png"
import penerbit29 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Amazing Books.png"
import penerbit30 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Aniwayang.png"
import penerbit31 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Asta.png"
import penerbit33 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Balai Pustaka.png"
import penerbit34 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Bali Lagi-Lagi.png"
import penerbit35 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Bestari.png"
import penerbit36 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Briliant.png"
import penerbit37 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Buku Katta.png"
import penerbit38 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Buku Kita.png"
import penerbit39 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Buku Mojok.png"
import penerbit40 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/BukuAku.png"
import penerbit41 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Cerita Marica.png"
import penerbit42 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Diva Press.png"
import penerbit43 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Erlangga.png"
import penerbit44 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Folxtale.PNG"
import penerbit45 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Guru Bumi(1).png"
import penerbit46 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Ideokids.png"
import penerbit47 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Infermia.png"
import penerbit48 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Inshan Books(1).png"
import penerbit49 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Jejak Pustaka(1).png"
import penerbit50 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Kawah Media.png"
import penerbit51 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Litara.png"
import penerbit52 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Literaloka.png"
import penerbit53 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Logo Mighty Jungle Transparent.png"
import penerbit54 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Logo-MIZAN-PUBLISHING-261.jpg"
import penerbit55 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Mekar Cipta Lestari.png"
import penerbit56 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Minima.png"
import penerbit57 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Mizan.png"
import penerbit58 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Noura Kids.jpeg"
import penerbit59 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Phinisi Samudra Ilmu.png"
import penerbit60 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Pickpockie Books.png"
import penerbit61 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Pojok Cerpen.png"
import penerbit62 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Post Press.png"
import penerbit63 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Rayya Creativa.png"
import penerbit64 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Reneturos.png"
import penerbit65 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Sarang Plus.png"
import penerbit66 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Tetum.png"
import penerbit67 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Tsugaeda.png"
import penerbit68 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Warning Books.png"
import penerbit69 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/WhatsApp Image 2023-10-25 at 2.05.14 PM.jpeg"
import penerbit70 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/Yaumi Kids.png"
import penerbit71 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/e9fba76dd85a7380d879e75df26c8147.jpg"
import penerbit72 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/logo impian studio.png"
import penerbit73 from "../../src/asset/2024/patjarkeciljuni/kawanpatjar/04. penerbit/new/logo minima.jpg"

export const KawanPatjar = [
  sponsor0,
  sponsor1,
  sponsor2,
  sponsor3,
  sponsor4,
  sponsor5,
  sponsor6,
  sponsor7,
  sponsor8,
  sponsor9,
]

export const komunitas = [
  komunitas1,
  komunitas2,
  komunitas3,
  komunitas4,
  komunitas5,
  komunitas6,
  komunitas7,
  komunitas8,
  komunitas9,
  komunitas10,
  komunitas11,
  komunitas12,
  komunitas13,
  komunitas14,
  komunitas15,
  komunitas16,
  komunitas17,
  komunitas18,
  komunitas19,
  komunitas20,
  komunitas21,
  komunitas22,
  komunitas23,
  komunitas24,
  komunitas25,
  komunitas26,
  komunitas27,
  komunitas28,
  komunitas29,
  komunitas30,
  komunitas31,
  komunitas32,
  komunitas33,
  komunitas34,
  komunitas35,
  komunitas36,
  komunitas37,
  komunitas38,
  komunitas39,
  komunitas40,
  komunitas41,
  komunitas42,
  komunitas43,
  komunitas44,
  komunitas45,
  // komunitas46,
  komunitas47,
  komunitas48,
  komunitas49,
  komunitas50,

  komunitas51,
  komunitas52,
  komunitas53,
  komunitas54,
  komunitas55,
  komunitas56,
  komunitas57,
  komunitas58,
  komunitas59,
  komunitas60,

  komunitas61,
  komunitas62,
  komunitas63,
  komunitas64,
  komunitas65,
  komunitas66,
  komunitas67,
  komunitas68,
  
  komunitas69,
  komunitas70,
  komunitas71,
  komunitas72,
  komunitas73,
  komunitas74,
  komunitas75,
  komunitas76,
  komunitas77,
  komunitas78,
  komunitas79,
  komunitas80,
  komunitas81,
]

export const penerbit = [
  penerbit1,
  penerbit2,
  penerbit3,
  penerbit4,
  penerbit5,
  penerbit6,
  penerbit7,
  penerbit8,
  penerbit9,
  penerbit10,
  penerbit11,
  penerbit12,
  penerbit13,
  penerbit14,
  penerbit15,
  penerbit16,
  penerbit17,
  penerbit18,
  penerbit19,
  penerbit20,
  penerbit21,
  penerbit22,
  penerbit23,
  penerbit24,
  penerbit25,
  penerbit26,
  penerbit27,

  penerbit28,
  penerbit29,
  penerbit30,
  penerbit31,
  penerbit33,
  penerbit34,
  penerbit35,
  penerbit36,
  penerbit37,
  penerbit38,
  penerbit39,
  penerbit40,
  penerbit41,
  penerbit42,
  penerbit43,
  penerbit44,
  penerbit45,
  penerbit46,
  penerbit47,
  penerbit48,
  penerbit49,
  penerbit50,
  penerbit51,
  penerbit52,
  penerbit53,
  penerbit54,
  penerbit55,
  penerbit56,
  penerbit57,
  penerbit58,
  penerbit59,
  penerbit60,
  penerbit61,
  penerbit62,
  penerbit63,
  penerbit64,
  penerbit65,
  penerbit66,
  penerbit67,
  penerbit68,
  penerbit69,
  penerbit70,
  penerbit71,
  penerbit72,
  penerbit73,
]

export const media = [
  media1,
  media2,
  media3,
  media4,
  media5,
  media6,
  media7,
  media8,
  media9,
  media10,
  media11,
  media12,
  media13,
  media14,
  media15,
  media16,
  media17,
  media18,
  media19,
  media20,
  media21,
  media22,
  media23,
  media24,
  media25,
  media26,
  media27,
  media28,
  media29,
  media30,
  media31,
]