import React, {Component} from 'react';
import {BrowserRouter, HashRouter, Route, useHistory, Switch} from "react-router-dom"
import './App.css';
import Home from "./component/page/Home";
import {ParallaxProvider} from "react-scroll-parallax";
import Sessions from "./component/page/Sessions";
import Surabaya2022Sessions from "./component/page/Surabaya2022Sessions";
import Bandung2022Sessions from "./component/page/Bandung2022Sessions";
import FPISessions from "./component/page/FPISessions";
import {CookiesProvider} from "react-cookie";
import GlobalData from "./util/GlobalData";
import BookStore from "./component/page/BookStore";
import Donation from "./component/page/Donation";
import ZodiacPromotion from "./component/page/ZodiacPromotion";
import KataPatjar from "./component/page/KataPatjar";
import PapermoonPromotion from "./component/page/PapermoonPromotion";
import Info from "./component/page/Info";

import EnHome from "./component/page/en/Home"
import EnSessions from "./component/page/en/Sessions"
import PapermoonModalPage from "./component/page/PapermoonModalPage";
import PostPaymentPage from "./component/page/PostPaymentPage";
import PapermoonComingSoon from "./component/page/PapermoonComingSoon";
import RumasuarPromotionPage from "./component/page/RumasuarPromotionPage";
import RumasuarPage from "./component/page/RumasuarPage";
import FestivalBukuAsiaPage from "./component/page/FestivalBukuAsiaPage";
import GincuPage from "./component/page/GincuPage";
import PestaBonekaPromotion from "./component/page/PestaBonekaPromotion";
import MencariNaskah from "./component/page/MencariNaskah";
import SurgaAnjingLiar from "./component/page/SurgaAnjingLiar";
import SurgaAnjingLiarDetail from "./component/page/SurgaAnjingLiarDetail";
import PerjamuanPulangDetail from "./component/page/PerjamuanPulangDetail";
import KonserDongeng from "./component/page/KonserDongeng";
import PatjarKita from "./component/page/patjarkita";
import SessionsV2023 from "./component/page/SessionsV2023";
import PatjarmerahKecilPage from './component/page/PatjarmerahKecilPage';
import Sayap from './component/page/Sayap';
import RequestDelete from './component/page/RequestDelete';
import AcaraPatjarKecil from './component/page/AcaraPatjarKecil';
import RadityaDika from './component/page/RadityaDika';
import RenikPage from "./component/page/RenikPage";


class App extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <>
                <CookiesProvider>
                    <ParallaxProvider>
                        <BrowserRouter basename="/">
                        <Switch>

                            <Route exact path= "/" component={Home}/>
                            <Route path="/post-payment" component={PostPaymentPage}/>
                            <Route path="/festival-literasi" component={Sessions}/>
                            <Route path="/akademi-patjarmerah/:id" component={Sessions}/>
                            <Route path="/akademi-patjarmerah" component={Sessions}/>

                            <Route path="/solo/:id" component={SessionsV2023}/>
                            <Route path="/solo" component={SessionsV2023}/>

                            <Route path="/patjar-pilihan/:id" component={BookStore}/>
                            <Route path="/patjar-pilihan" component={BookStore}/>
                            <Route path="/galang-patjar" component={Donation}/>
                            <Route path="/kata-patjar" component={KataPatjar}/>
                            <Route path="/zodiac" component={ZodiacPromotion}/>
                            <Route exact path="/papermoon" component={PapermoonPromotion}/>
                            <Route path="/papermoon/watch" component={PapermoonModalPage}/>

                            <Route exact path="/konserdongeng" component={KonserDongeng}/>

                            <Route exact path="/patjarkecil" component={PatjarmerahKecilPage}/>
                            <Route exact path="/patjarkecil/:id" component={PatjarmerahKecilPage}/>

                            <Route path="/support" component={Info}/>
                            <Route exact path="/rumasuar" component={RumasuarPromotionPage}/>
                            <Route path="/rumasuar/detail" component={RumasuarPage}/>

                            <Route path="/mencarinaskah" component={MencariNaskah}/>
                            <Route path="/patjarkita" component={PatjarKita}/>

                            <Route exact path="/surgaanjingliar/detail" component={SurgaAnjingLiarDetail}/>
                            <Route path="/surgaanjingliar" component={SurgaAnjingLiar}/>

                            <Route path="/perjamuan-pulang" component={PerjamuanPulangDetail}/>

                            {/* <Route path="/festivalbukuasia/:id" component={FestivalBukuAsiaPage}/> */}
                            {/* <Route path="/festivalbukuasia" component={FestivalBukuAsiaPage}/> */}

                            <Route path="/gincu" component={GincuPage}/>

                            <Route exact path= "/en/" component={PapermoonPromotion}/>
                            <Route exact path= "/en/home" component={Home}/>

                            <Route exact path= "/en/papermoon" component={PapermoonPromotion}/>

                            {/*<Route exact path= "/en/papermoon/:id" component={PestaBonekaPromotion}/>*/}
                            {/*<Route exact path= "/papermoon/:id" component={PestaBonekaPromotion}/>*/}
                            {/*<Route exact path= "/pesta-boneka" component={PestaBonekaPromotion}/>*/}
                            {/*<Route exact path= "/pesta-boneka/:id" component={PestaBonekaPromotion}/>*/}
                            {/*<Route exact path= "/en/pesta-boneka/:id" component={PestaBonekaPromotion}/>*/}
                            {/*<Route exact path= "/en/pestaboneka/:id" component={PestaBonekaPromotion}/>*/}

                            {/* <Route path="/banana/:id" component={FPISessions}/> */}
                            {/* <Route path="/banana" component={FPISessions}/> */}
                            <Route path="/acarapatjarkecil" component={AcaraPatjarKecil}/>

                            <Route path="/sayap" component={Sayap}/>

                            <Route exact path="/radityadika" component={RadityaDika}/>

                            <Route exact path="/renik" component={RenikPage}/>
                            <Route exact path="/renik/:id" component={RenikPage}/>


                            <Route path="/request-delete" component={RequestDelete}/>

                        </Switch>
                        </BrowserRouter>
                    </ParallaxProvider>
                </CookiesProvider>
            </>

        );
    }


}


export default App;
