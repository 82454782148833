import {Component, useEffect, useState} from "react";
import React from "react";
import Header from "../reusable/Header";
import LoginModal from "../reusable/LoginModal";
import {useCookies} from 'react-cookie';
import GlobalData from "../../util/GlobalData";
import User from "../../models/User"
import Footer from "../reusable/Footer";
import {
    isMobile
} from "react-device-detect";
import ForgotPasswordModal from "../reusable/ForgotPasswordModal";
import BookModal from "../reusable/BookModal";
import LanguageSelectionModal from "../reusable/LanguageSelectionModal";
import {translatePath} from "../../util/PathTranslator";
import {useHistory, useLocation, useParams} from 'react-router-dom'

export default function PageWrapper(props) {

    const [forgotPasswordShow, setForgotPasswordShow] = useState(false);
    const [loginShow, setLoginShow] = useState(false);
    const [cookie, setCookie, removeCookie] = useCookies()
    const [isLoggedIn, setIsLoggedIn] = useState(GlobalData.token || cookie.token)
    const [userData, setUserData] = useState(GlobalData.user);

    let location = useLocation();
    let history = useHistory()
    let params = useParams()

    let doLogin = async () => {
        if (!GlobalData.token && cookie.token) {
            GlobalData.token = cookie.token
            let userModel = new User()
            let user = await userModel.tokenLogin()

            if (props.loginListener) {
                props.loginListener(user)
            }

            setUserData(user)
        }
    }

    useEffect(() => {
        doLogin()
        window.scrollTo(0,0)
    },[])

    /**TEST**/

    console.log("header active " + props.headerActive)

    let redirectTo = translatePath(props.headerActive, cookie.lang ? cookie.lang : "en", params.id)

    if(redirectTo !== location.pathname || (location.pathname === "" && redirectTo === "/")){
        console.log("will redirect to " + redirectTo , location.pathname)
        history.push(redirectTo)
    }

    return (
        <div style={{marginTop: isMobile ? 0 : '22x', overflowX : "hidden"}}>

            <Header
                shy={props.headerShy}
                isLoggedIn={isLoggedIn}
                onLoginPress={() => {
                    setLoginShow(true)
                }}
                user={userData}
                active={props.headerActive}
                isBookStoresShown={props.isBookStoresShown}
            />

            <LoginModal
                show={props.loginShow || (loginShow && !isLoggedIn)}
                message={props.message}
                onHide={(token) => {
                    setLoginShow(false)
                    if (GlobalData.token) {
                        setIsLoggedIn(true)
                        setCookie("token", token, {path: "/", maxAge: 60 * 60 * 24 * 30})
                    }
                }}
                customOnHide={props.customOnHide}
                showForgotPassword={() => {
                    setLoginShow(false);
                    setForgotPasswordShow(true)
                }}
            />

            <ForgotPasswordModal
                show={forgotPasswordShow}
                hideForgotPassword={() => setForgotPasswordShow(false)}/>

            <div style={{height: props.headerShy ? 0 : "3em", overflowX : "hidden"}}/>
            {
                props.children
            }

            <div style={{height: "4em", backgroundColor : props.backgroundColor ? props.backgroundColor: "white"}}/>

            {props.hideFooter ? null : <Footer activeTab={props.headerActive} isBookStoresShown={props.isBookStoresShown}/>}

            <BookModal show={props.isBookModalShown} book={props.selectedBook} onHide={props.hideBookModal}/>

        </div>
    );

}
