import React, {Component, useEffect, useState} from "react";
import {Slide} from "react-reveal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Palette from "../../util/Palette";
import {Button, Navbar, Dropdown, OverlayTrigger, ListGroup, Popover} from "react-bootstrap";
import GlobalData from "../../util/GlobalData";
import {Link, useHistory} from "react-router-dom";
import {
    isMobile
} from "react-device-detect";
import {useCookies} from "react-cookie";
import Container from "react-bootstrap/Container";
import EditProfileModal from "./EditProfileModal";
import LanguageSelectionModal from "./LanguageSelectionModal";

export default function Header(props) {

    let [scrollPosition, setScrollPosition] = useState(0)
    const [editProfileShow, setEditProfileShow] = useState(false)
    let history = useHistory()
    let user = GlobalData.user
    const [cookie, setCookie, removeCookie] = useCookies()

    const [languageModalShow, setIsLanguageModalShow] = useState(false)

    const isLanguageModalOpen = languageModalShow

    useEffect(() => {

        if(!cookie.lang){
            setCookie("lang", "id", {path: "/", maxAge: 60 * 60 * 24 * 30})
        }

        return () => {
            window.removeEventListener('scroll', listenToScroll)
        }
    }, []);

    useEffect(() => window.addEventListener('scroll', listenToScroll))

    function listenToScroll() {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        setScrollPosition(scrolled)
    }

    let header;

    let isEN = cookie.lang !== "id"

    if (isMobile) {
        header = <Slide top when={
            !props.shy || scrollPosition > 0.1
        }
        >
            <div style={{
                width: "100%",
                height: "3em",
                position: "fixed",
                top: 0,
                zIndex: 1000,
                display: "flex",
                alignItems: "center",
                background: "white",
                boxShadow: "0px 4px 8px 1px #888888"
            }}>
                {/*<Helmet>*/}
                {/*    <title>patjarmerah</title>*/}
                {/*    <meta name="description" content="Festival kecil literasi dan pasar buku keliling nusantara"/>*/}
                {/*    <meta name="keywords"*/}
                {/*          content="patjarmerah, patjar, merah, festival, buku, keliling, murah, diskon"/>*/}
                {/*</Helmet>*/}

                <Row style={{
                    alignItems: "center",
                    flex : 1
                }}
                >
                    <Col xs={4}>
                        <img
                            onClick={(e) => {
                                e.preventDefault()
                                if (isEN) {
                                    history.push("/en/home")
                                } else {
                                    history.push("/")
                                }
                            }}
                            style={{
                                width: "100%",
                                height : "3em",
                                maxHeight: "3em",
                                objectFit: "contain",
                            }}
                            src={GlobalData.subfolder + "image/logo/patjarmerah.png"}
                        />
                    </Col>

                    {
                        !props.isLoggedIn &&
                        <Col xs={8} style={{
                            justifyContent: "flex-end",
                            paddingRight: "-10px",
                            display : "flex"
                        }}
                        >
                            <Button
                                variant={"danger"}
                                style={{
                                    background: Palette.MAROON,
                                    width: 100
                                }}
                                onClick={() => {
                                    props.onLoginPress()
                                    console.log("caserole")
                                }}
                                size={"sm"} block>
                                Login
                            </Button>

                            <div style={{
                                width: "2em",
                                height: "2em",
                                marginLeft: 4,
                                borderRadius: "50%",
                                backgroundPosition: "center",
                                backgroundImage: cookie.lang === "id" ? `url(${require("../../asset/id.svg")})` : `url(${require("../../asset/GB.svg")})`,
                                backgroundRepeat: "no-repeat",
                                border: "1px solid #ccc",
                                backgroundSize: "cover",
                                cursor: "pointer"
                            }} onClick={() => {
                                setIsLanguageModalShow(true)
                            }}/>


                        </Col>
                    }


                    {
                        props.isLoggedIn &&
                        <Col xs={8} style={{
                            justifyContent: "flex-end",
                            paddingRight: "-10px",
                            display : "flex"
                        }}
                        >

                            <Dropdown
                                onSelect={(eventKey, event) => {
                                    if (eventKey === "3") {
                                        console.log("this is chosen")
                                        GlobalData.token = null
                                        GlobalData.user = null

                                        removeCookie("token", {path: '/'})
                                        console.log("cookie " + cookie.token)
                                        setTimeout(function () {
                                            window.location.reload();
                                        }, 500);
                                    }

                                    if (eventKey === "1") {
                                        console.log("numero uno")
                                        setEditProfileShow(true)
                                    }

                                    if (eventKey === "2") {
                                        setIsLanguageModalShow(true)
                                    }

                                }}
                                alignRight>
                                <Dropdown.Toggle
                                    variant={"light"}
                                    id="dropdown-custom-1">
                                    <img src={GlobalData.subfolder + "image/default_user.png"} style={{
                                        position: "relative",
                                        right: 0,
                                        height: "2em",
                                        borderRadius: "2em"
                                    }}/>

                                </Dropdown.Toggle>
                                <Dropdown.Menu className="super-colors">
                                    <Dropdown.Item eventKey="1">{isEN ? "Edit Profile" : "Ubah Profil"}</Dropdown.Item>
                                    <Dropdown.Item eventKey="2">{isEN ? "Change Language" : "Ubah Bahasa"}</Dropdown.Item>
                                    <Dropdown.Item eventKey="3">{isEN ? "Logout" : "Keluar"}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>{' '}

                        </Col>

                    }

                </Row>

            </div>
        </Slide>
    } else {

        if (scrollPosition < 0.05 && props.shy) {
            header = null;
        } else {
            header = <Slide top when={
                !props.shy || scrollPosition > 0.1
            }>
                <div style={{
                    width: "100%",
                    position: "fixed",
                    top: 0,
                    zIndex: 1000,
                    display: "flex",
                    background: "white",
                    boxShadow: "1px 1px 1px 1px #888888",
                    padding: '10px'
                }}>
                    <Container fluid>
                        {/*<Helmet>*/}
                        {/*    <title>patjarmerah</title>*/}
                        {/*    <meta name="description" content="Festival kecil literasi dan pasar buku keliling nusantara"/>*/}
                        {/*    <meta name="keywords"*/}
                        {/*          content="patjarmerah, patjar, merah, festival, buku, keliling, murah, diskon"/>*/}
                        {/*</Helmet>*/}

                        <Row>
                            <Col md={2} xs={2}>
                                <img
                                    style={{
                                        width: "100%",
                                        maxHeight: "2em",
                                        objectFit: "contain",
                                        marginTop: '6px'
                                    }}
                                    src={GlobalData.subfolder + "image/logo/patjarmerah.png"}
                                />
                            </Col>

                            <Col md={6} xs={10} style={{display: 'flex', alignItems: 'center'}}
                            >
                                <a href={"#"} style={{
                                    // display: isEN ? "none" : null,
                                    color: Palette.MAROON,
                                    fontSize: "0.8em",
                                    marginLeft: "1em",
                                    fontWeight: props.active === 'home' || props.active === "en-home" ? 'bold' : 'normal',
                                }} onClick={(e) => {
                                    e.preventDefault()
                                    if (isEN) {
                                        history.push("/en/home")
                                    } else {
                                        history.push("/")
                                    }
                                }}>
                                    {isEN ? "Home" : "Halaman Utama"}
                                </a>

                                <a href={""} style={{
                                    // display: isEN ? "none" : null,
                                    color: Palette.MAROON,
                                    fontSize: "0.8em",
                                    marginLeft: "1em",
                                    fontWeight: props.active === 'book-store' ? 'bold' : 'normal',
                                }} onClick={(e) => {
                                    e.preventDefault()
                                    history.push("/patjar-pilihan")
                                }}>
                                    patjarpilihan
                                </a>

                                <a href={"/renik"} style={{
                                    // display: isEN ? "none" : null,
                                    color: Palette.MAROON,
                                    fontSize: "0.8em",
                                    marginLeft: "1em",
                                    fontWeight: props.active === 'renik' ? 'bold' : 'normal',
                                }}>
                                    Renik
                                </a>

                                {/* <a href={"/patjarkita"} style={{
                                    // display: isEN ? "none" : null,
                                    color: Palette.MAROON,
                                    fontSize: "0.8em",
                                    marginLeft: "1em",
                                    fontWeight: props.active === 'patjarkita' ? 'bold' : 'normal',
                                }}>
                                    patjarkita
                                </a> */}

                                
                                {/* <a href={""} style={{
                                    // display: isEN ? "none" : null,
                                    color: Palette.MAROON,
                                    fontSize: "0.8em",
                                    marginLeft: "1em",
                                    fontWeight: props.active === 'patjarkita' ? 'bold' : 'normal',
                                }} onClick={(e) => {
                                    e.preventDefault()
                                    history.push("/patjarkita")
                                }}>
                                    patjarkita
                                </a> */}

                                {/*<a href={""} style={{*/}
                                {/*    // display: isEN ? "none" : null,*/}
                                {/*    color: Palette.MAROON,*/}
                                {/*    fontSize: "0.8em",*/}
                                {/*    marginLeft: "1em",*/}
                                {/*    fontWeight: props.active === 'bandung' ? 'bold' : 'normal',*/}
                                {/*}} onClick={(e) => {*/}
                                {/*    e.preventDefault()*/}
                                {/*    history.push("/brf")*/}
                                {/*}}>*/}
                                {/*    Bandung*/}
                                {/*</a>*/}

                                {/*<a href={""} style={{*/}
                                {/*    // display: isEN ? "none" : null,*/}
                                {/*    color: Palette.MAROON,*/}
                                {/*    fontSize: "0.8em",*/}
                                {/*    marginLeft: "1em",*/}
                                {/*    fontWeight: props.active === 'papermoon-promotion' ? 'bold' : 'normal',*/}
                                {/*}} onClick={(e) => {*/}
                                {/*    e.preventDefault()*/}
                                {/*    history.push("/papermoon")*/}
                                {/*}}>*/}
                                {/*    Papermoon*/}
                                {/*</a>*/}

                                {/*<a href={""} style={{*/}
                                {/*    // display: isEN ? "none" : null,*/}
                                {/*    color: Palette.MAROON,*/}
                                {/*    fontSize: "0.8em",*/}
                                {/*    marginLeft: "1em",*/}
                                {/*    fontWeight: props.active === 'surgaanjingliar' || props.active === 'surgaanjingliar-detail' ? 'bold' : 'normal',*/}
                                {/*}} onClick={(e) => {*/}
                                {/*    e.preventDefault()*/}
                                {/*    history.push("/surgaanjingliar/detail")*/}
                                {/*}}>*/}
                                {/*    patjar istimewa*/}
                                {/*</a>*/}

                            </Col>

                            <Col md={4} xs={12} style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                paddingRight: "1em"
                            }}>
                                {props.isLoggedIn ?
                                    <div style={{
                                        flexDirection: "row-reverse",
                                        alignItems: "center"
                                    }}>

                                        <Dropdown
                                            onSelect={(eventKey, event) => {
                                                if (eventKey === "3") {
                                                    console.log("this is chosen")
                                                    GlobalData.token = null
                                                    GlobalData.user = null

                                                    removeCookie("token", {path: '/'})
                                                    console.log("cookie " + cookie.token)
                                                    setTimeout(function () {
                                                        window.location.reload();
                                                    }, 500);

                                                }

                                                if (eventKey === "1") {
                                                    console.log("numero uno")
                                                    setEditProfileShow(true)
                                                }
                                            }}
                                            alignRight>
                                            <Dropdown.Toggle
                                                variant={"light"}
                                                id="dropdown-custom-1">
                                                <img src={GlobalData.subfolder + "image/default_user.png"} style={{
                                                    position: "relative",
                                                    right: 0,
                                                    height: "2em",
                                                    borderRadius: "2em"
                                                }}/>

                                                <span style={{
                                                    marginLeft: '10px',
                                                    color: Palette.MAROON,
                                                    fontWeight: 'bold',
                                                    fontSize: '0.8em'
                                                }}>
                                                    {user ? user.full_name : null}
                                                </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="super-colors">
                                                <Dropdown.Item
                                                    eventKey="1">{isEN ? "Edit Profile" : "Ubah Profil"}</Dropdown.Item>
                                                <Dropdown.Item eventKey="3">{isEN ? "Logout" : "Keluar"}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>{' '}
                                    </div> :
                                    <Button
                                        variant={"danger"}
                                        style={{
                                            background: Palette.MAROON,
                                            paddingInlineStart: "4em",
                                            paddingInlineEnd: "4em"
                                        }}
                                        onClick={() => {
                                            props.onLoginPress()
                                            console.log("caserole")
                                        }}
                                        size={"sm"}>
                                        Login
                                    </Button>}


                                <div style={{
                                    width: "2em",
                                    height: "2em",
                                    marginLeft: "1em",
                                    borderRadius: "50%",
                                    backgroundPosition: "center",
                                    backgroundImage: cookie.lang === "id" ? `url(${require("../../asset/id.svg")})` : `url(${require("../../asset/GB.svg")})`,
                                    backgroundRepeat: "no-repeat",
                                    border: "1px solid #ccc",
                                    backgroundSize: "cover",
                                    cursor: "pointer"
                                }} onClick={() => {
                                    setIsLanguageModalShow(true)
                                }}/>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </Slide>
        }

    }

    const onSelected = () => {

    }

    return <>
        {header}

        <LanguageSelectionModal
            show={isLanguageModalOpen}
            hide={() => setIsLanguageModalShow(false)}
        />

        <EditProfileModal
            onHide={() => {
                setEditProfileShow(false)
            }}
            show={editProfileShow}
        />
    </>

}
