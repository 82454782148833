import React, {useEffect, useRef, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageWrapper from "./PageWrapper";
import {Button} from "react-bootstrap";
import {isMobile} from "react-device-detect";
import {AiOutlineCalendar, AiOutlineWarning} from "react-icons/ai";
import Palette from "../../util/Palette";
import {MdError, MdLocationOn} from "react-icons/md";
import {TiLocationOutline, TiTicket} from "react-icons/ti";
import {useCookies} from "react-cookie";
import Session from "../../models/Session";
import PapermoonTicketNode from "../reusable/PapermoonTicketNode";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import moment from "moment";
import ScheduleNode from "../reusable/ScheduleNode";
import GlobalData from "../../util/GlobalData";
import _ from "lodash";
import SessionModal from "../reusable/SessionModal";
import {useHistory} from 'react-router-dom'
import {CsvToHtmlTable} from "react-csv-to-table";
import {MDBDataTable} from 'mdbreact';

let firstLoad = false

const csvData = `
Judul Naskah;Kategori;Pengarang
2020;Novel atau Novela;Tian
(Mega)mendung;Novel atau Novela;B.E. Raynangge
(Tak) Seindah Bunga Lili;Novel atau Novela;Marliana Kuswanti
$ALFIE$;Novel atau Novela;Andra
11 Day;Novel atau Novela;pena putih
A Letter from Budapest;Novel atau Novela;Risty Khoirunisa
A Worker's Life;Novel atau Novela;Catharina Novia Christanti
Adlan & Adlina;Novel atau Novela;Nur Laila
Adu Rayu;Novel atau Novela;Mayangputri
 Aftretaste;Novel atau Novela;Tary Lestari
Ahli Waris;Novel atau Novela;Sika Indriyawati
Airlangga;Novel atau Novela;Yeni Fitriyani
Aku dan Mereka Satu Jiwa;Novel atau Novela;Fitriyanti Setioninggrum
Aku Ingin Berubah;Novel atau Novela;Luthfiyah Qurrotul Aini
Aku Manusian yang Bangun Tidur Bahagia;Novel atau Novela;Mahar Alima
Aku Menulis, Maka Aku Ada ;Novel atau Novela;Arifah Jauhari Syams
Amulet Suwarnadwipa;Novel atau Novela;Alexandro Dominic Harun
Anak Angin di Kerajaan Aero;Novel atau Novela;Kandil Sukma Ayu
Anatema;Novel atau Novela;Dwi Candra Nurrohman
Anathema;Novel atau Novela;Deby Saditri Primadani (Mahesvara)
Angel's Letters;Novel atau Novela;Yomita Sonia Tanjung
Angin di balik Tornado;Novel atau Novela;Tsaqifa Aulya
Antara Kamu dan Guru BK (The Youtube Podcast);Novel atau Novela;Msutofa P
Antibiotik: Dokter, Mafia Obat, dan Jurnalistik;Novel atau Novela;Sirojul Khafid
Arath Lulureh;Novel atau Novela;A. Rifka Afifah
Are You Rainbow?;Novel atau Novela;Lely Qurrota A'yun
Arti Cinta;Novel atau Novela;Marlia Ayuni
Arunika;Novel atau Novela;Esti Menur Sukanti
Awal Mula Mengeja;Novel atau Novela;Udiarti
Awiligar;Novel atau Novela;ali busche
Ayah, Kita Orang Indonesia, Kan?;Novel atau Novela;Maurinta
Azka - Rahasia Sarang Cakrawala;Novel atau Novela;Sintong Arfiyansyah
Batas;Novel atau Novela;AL Wijaya
Batas Kenyataan;Novel atau Novela;Bella Puteri Shafariyenni
Beritahu Aku Suatu Hari Nanti;Novel atau Novela;rimachmed
Beutiful Mind;Novel atau Novela;Yanni Langen
Bidadari Surga Abizar;Novel atau Novela;Arfi Stiyani
Bingkai Surga Untuk Raiha ;Novel atau Novela;ZiAldina
Blacklight Dawn;Novel atau Novela;Anky Prasetya
Bondari;Novel atau Novela;Fikra Fadilla
Boneka Sosial;Novel atau Novela;Morella Mulyadina
Bu Guru Nadia;Novel atau Novela;Risalati
bukan penembak jitu;Novel atau Novela;suharyo widagdo
Bukan Tentang Hari Ini;Esai atau Memoar;Ach Sufyan Wahyudi
Bunga untuk Arjuna;Novel atau Novela;Yuanita Fransiska
Busway;Novel atau Novela;Dian S. Hadiningrum
Cakrawala Nusantara: Pencarian Gelang Mpu Nala;Novel atau Novela;Alexandro Dominic Harun
Candra Bagaskara: Rajapati Manjer Kawuryan;Novel atau Novela;Putriyana Asmarani
Cara Esay Menulis Esai, Panduan Praktis Menulis Untuk Para Guru;Esai atau Memoar;Sofyan Aziz
Cara Terbaik Menulis Kitab Suci;Novel atau Novela;Yudhi Herwibowo
Cara Terburuk Untuk Melupakan;Novel atau Novela;Ramona Sandi
Catatan Harian Anak-Anak Khatuslitiwa;Novel atau Novela;Elia Okki
Catatan Kecil;Novel atau Novela;Katyusha Melody Rusli
Catatan Seorang Bajingan;Esai atau Memoar;Ra!
Cincin Perak Bermata Putih;Novel atau Novela;Erwan Hamdani
Cinta Hadir di Benua ke 3;Novel atau Novela;Putri Nur Pratiwi
Cinta Tanpa Alasan;Novel atau Novela;Willy Pangeran
Cinta Terlarang;Novel atau Novela;Dyan Roh Ayu
Cintaku Tak Salah?;Novel atau Novela;Anima
Classic;Novel atau Novela;Desi Ramadani
Courage;Novel atau Novela;Kartika Aska/ Kartikha Sri Rahmayanty
Darah dan Mimpi;Novel atau Novela;Latif Fianto
Demi Kalian;Novel atau Novela;Anima
Demi Yono;Novel atau Novela;Deviannistia Suyonoputri
Di Lingkaran Api;Novel atau Novela;Fajar R Ayuningtyas
Dia yang Tidak Disebutkan Namanya;Novel atau Novela;Januar Eriyanto
Diary Wanita Hebat;Novel atau Novela;Aroma
Dibalik Rahasia Tergelap;Novel atau Novela;Yesika Samara
Dispersionis;Novel atau Novela;Intan N.S.
Don't Fall For Me;Novel atau Novela;Ariska Putri Nugroho (Aoirisuka)
Dona Nobis Pacem;Novel atau Novela;Dionisius Sapto Nugroho
Dua Keberuntungan;Novel atau Novela;Ika Arif Wulandari
Dunia katleya, Dunia Kadhafi;Novel atau Novela;Y Agusta Akhir
Dunia Literasi dari Kenangan Hingga Fredy S;Esai atau Memoar;F Daus AR
Dunia Romantisme dimana Chandie Hidup;Novel atau Novela;Vakker Foss dan Flowchan
Ego;Novel atau Novela;W_eird
Endles Love;Novel atau Novela;Istiyanti Utami
Etalase Sepatu;Novel atau Novela;Tian Topandi
Fake Memories;Novel atau Novela;Farah Nadia
Falang: Catatan Perjalanan ke Beberapa Negara di Asia Tenggara;Tulisan Perjalanan;Fuji Adriza
Finding Ara;Novel atau Novela;Najiyah
Gaea;Novel atau Novela;Brenda Maria
Gaia;Novel atau Novela;Yuhana Kusumaningrum
Galaunya Seperempat Abad;Novel atau Novela;Monica
Gembel Doyan Jalan;Tulisan Perjalanan;Agung Dermawan
Gula, Gula;Novel atau Novela;Nuril Basri
Gunung Mengajarkan Filosofi Kehidupan;Tulisan Perjalanan;Debora Juliana
Hadiah Perjalanan;Tulisan Perjalanan;Natalia Oetama
Hanya Karena Cinta;Novel atau Novela;Anima
Harta Karun di Ujung Pelangi;Novel atau Novela;Bobi Tuankotta
Hidup Dalam Koma;Novel atau Novela;Ratu Agung Ayu Yulianatasya Agustina
HOMO RELIGIUS: Refleksi Religiusitas Masyarakat Urban di Era Pandemi;Esai atau Memoar;A. Fikri Amiruddin Ihsani
Hubbyna;Novel atau Novela;Anis Mubsiroh
I Wish You Were Here;Novel atau Novela;Ryscha Dwi Iryani
Iblis dari Hutan Kan;Novel atau Novela;Sukron Hadi
Ibu Bukan Pelacur;Novel atau Novela;Ansar Siri
Ikan-Ikan dan Kunang-Kunang di Kedung Mayit;Novel atau Novela;Dewanto Amin Sadono
Ilu Ilalang;Novel atau Novela;Al Ayubi
Imlek dan Empu;Novel atau Novela;Maurinta
impian dan halangan;Novel atau Novela;mathoyatul ma'rifah
In The End of The Day;Novel atau Novela;Hafshah Widya Arini
Ingatan Masa Lalu;Novel atau Novela;Ria Yulia Priskilla
Insecure Inside Of Me;Novel atau Novela;Jafarrudin
Insekta;Novel atau Novela;Wi Noya
INSIGHT : When You See Me From Another Side;Novel atau Novela;Yunita Widyaningsih
Jagad Pramudita;Novel atau Novela;Gusti Aditya Suprihana
Jalan Keluar Berduri;Novel atau Novela;Rio Hosana
Jalan Setapak Menuju Rumah;Novel atau Novela;Rafael Yanuar
Jawaban yang Tak Pernah Sempurna Perihal Cinta;Novel atau Novela;Juli Prasetya
Jejak di Balik Waktu;Novel atau Novela;Tiarda Simarmata
Jejak Kaki Kita;Tulisan Perjalanan;Precillia Leonita
Jelaga dan Hujan Kering;Novel atau Novela;Winnie Tjahjono
Jemantik;Novel atau Novela;Adia Puja
Jendela Senja Rea;Novel atau Novela;Retnoasih
Jo's Bucket List;Novel atau Novela;saff
Jodoh Jenaka;Novel atau Novela;Nur Azizah
Judul Naskah;Jenis Naskah;Nama Penulis
Kacapiring Mekar Tak Jauh dari Rawanya;Novel atau Novela;Alfa Mahsuni
Kamelia Putih;Novel atau Novela;Nicco Machi
Karena Wajah Tuhan;Esai atau Memoar;Kafha
Kartu Hati dan Mimpi;Novel atau Novela;Raisa Fardani
Kausa Mimpi;Novel atau Novela;Ihza Handika
Kaylova;Novel atau Novela;Melati Afdhila
Ke Malaysia, ke Tanah Perang;Novel atau Novela;Romi Afriadi
Keheningan Batin, Cahaya, dan Sebuah Puisi;Esai atau Memoar;Yus Amin. Db
Kelas-kelas di Luar Ruang Perkuliahan;Novel atau Novela;Wahyudi Bahtiar
Kembali Ke Awal;Novel atau Novela;AL Wijaya
Kidung Hening;Esai atau Memoar;Esthy Wika
Kisah Mereka;Novel atau Novela;Asia Wistaria
Kisah Satu Hari yang Berakhir dengan Putusnya Alat Kelamin;Novel atau Novela;Adia Puja
Kita;Novel atau Novela;Aliyudin
Komidi Putar Witarsih;Novel atau Novela;Andriyana
Konstelasi Lestari;Novel atau Novela;edira putri
Kopi Subuh untuk Bapak;Novel atau Novela;Hadi Mulyono
Ksatria Khandra;Novel atau Novela;Prilda Titi Saraswati
Ku lepas kau dengan ikhlas;Novel atau Novela;Juliana
Kuda Nonggeng Membelah Jakarta;Novel atau Novela;Wildan.S.Chopa
Land of Freedom;Novel atau Novela;Sigit Candra Lesmana
Langit Magenta;Novel atau Novela;Sundea
Langkah-langkah Membunuh Kenangan;Novel atau Novela;Mawan Belgia
Larung Suwung;Novel atau Novela;Tary Lestari
Lebah Pukul 10:00 pm;Novel atau Novela;Ogie Munaya
Lecah;Novel atau Novela;Rina R. Ridwan  (Nur Rina Chairani)
Legendarium Elenar (Kisah-kisah di Bumi Tengah);Novel atau Novela;Syihabul Furqon
Lelaki Dari Masa Lalu;Novel atau Novela;Angelia Maharani
Lelaki yang Bercerita tentang Suara;Novel atau Novela;Sayyidatul Imamah
Liminal;Tulisan Perjalanan;Algonz Dimas B. Raharja
Lipur;Novel atau Novela;Latif Pungkasniar
Little Monster;Novel atau Novela;Tsalitsa Normadina
Logika Mayorita Mengungkung Kreativitas Kita;Esai atau Memoar;Aliurridha
Lonceng Gereja dan Azan di Israel;Tulisan Perjalanan;Han Gagas
Loss And Breakage;Novel atau Novela;Choirul Anwar
Love In The Middle Of Corona;Novel atau Novela;Johan Aindy Putra Lubis
Love You Dear.......?;Novel atau Novela;Anima
Luka? (Sebuah Usaha Menyikapi Patah Hati, Supaya tak Berujung Menyakiti);Novel atau Novela;Abdul Hamid (Hamid mmd)
M(a)mpir;Novel atau Novela;Jihan Bintang Pamungkas
Mahkota Raja;Novel atau Novela;Lissiery Khanran
manusia bertopeng;Novel atau Novela;suharyo widagdo
Marcapada;Novel atau Novela;Fajrin Yusup Muttaqin
Marissa;Novel atau Novela;Kangwindo
Markombur;Esai atau Memoar;Acha Hallatu
Masih Ada Harapan;Tulisan Perjalanan;Maria Viktoria Yusta Alus
Mataluina;Novel atau Novela;Dimas Putra Ramadhan
Mataya;Novel atau Novela;Yuditeha
Maya;Novel atau Novela;Ahmad Luel
Maysaroh Dalam Bahaya;Novel atau Novela;Eko Sabto Utomo
Me And Five Sister;Novel atau Novela;Wa Ode Al Wahyuni Madu
Mega;Novel atau Novela;Kevin Farrel Saifan
Melawan Nasib;Novel atau Novela;Tiffani Yulita
Melihat Diri;Esai atau Memoar;Toto Priyono
Melihat Indonesia dengan Terbuka;Esai atau Memoar;Ni Nyoman Ayu Suciartini
Melihat Orang-Orang Buta;Novel atau Novela;Aflaha Rizal Bahtiar (nama pena: Aflaha Rizal)
memanusiakan manusia;Tulisan Perjalanan;suharyo widagdo
Membaca Tubuh di Laut;Novel atau Novela;Aksan Taqwin Embe
Memberi Makan Malaikat;Novel atau Novela;Daniel
Memoar Pandemi 2020 : Pergulatan Batin Pada Covid-19;Esai atau Memoar;Novy Khayra
Memoar Teratai Merah;Novel atau Novela;Evan Yovian
Menakar Rasa  (ROMANCE NEVER END);Novel atau Novela;Heru Patria
Mencari Cara Mati Menderita;Novel atau Novela;Agus Subakir
Mencari Kebahagiaan, Menemukannya Di Dalam Hati;Novel atau Novela;Heri Widianto
Mencari Rakyat Di Tengah Pandemi Covid-19 Global;Esai atau Memoar;Anicetus Windarto
Mencocol Sambal di Tasikmalaya, Menyeruput Latte di Paris;Esai atau Memoar;Setyaningsih
Menepi dari Ingar Bingar Pesta;Esai atau Memoar;Fajar Martha
Mengalah;Novel atau Novela;Dian Alima
Mengejar Cita Menuai Cinta;Novel atau Novela;Shainakaf
Mengunjungi Rumah Kekasih;Tulisan Perjalanan;Almunifah Nashir
Mentari Sehari Tidak Besinar;Novel atau Novela;Theresia Hardiyanti
Menukam Tambo;Novel atau Novela;Beri Hanna
Menunaikan Sepak Bola Indocina;Tulisan Perjalanan;Gusti Aditya Suprihana
Meru Anjani;Tulisan Perjalanan;Laily M Octavia
Meterai Terakhir;Novel atau Novela;Jemmy Piran
Mey Lin dan Isi Kepala Kekasihnya;Novel atau Novela;Ardi Rai Gunawan
Mi Jowo;Novel atau Novela;Joanne
Mimpi Buruk Itu Membakar Kita;Novel atau Novela;Surya Gemilang
Miracantika;Novel atau Novela;Chandra Wulan
Misteri Hilangnya Sang Bidadari;Novel atau Novela;Ogie Munaya
Mokondo: Lelaki Tua Kesepian yang Mencintai Buku-Buku;Novel atau Novela;Y Agusta Akhir
Momen Antara;Novel atau Novela;Andina Yudiarti
Morat-marit Sang Juru Lapar;Novel atau Novela;Eko sabto utomo
Museum Lengser;Novel atau Novela;Intan N.S.
Musim Semi Revolusi;Novel atau Novela;Grace Angelica Nadapdap
Musulmanin;Novel atau Novela;Sarita Diang
Mutiara dalam Lumpur;Novel atau Novela;Ariza Qanita
My Dangerous Husband;Novel atau Novela;Matchapio (kimmy keisha)
My Idiot Boyfriend;Novel atau Novela;Annisa Dwi Utami
My Troublemaker ;Novel atau Novela;Aulia Rahma Tambusai
Nadif (Janji-Janji Masa Depan);Novel atau Novela;Uli Aprilia Mukaromah (Little Magic)
Nafsu Binatang;Novel atau Novela;Afandi Garudatama
Nalar Kaum Sarungan: Pergulatan Pemikiran Islam Era Milenial;Esai atau Memoar;Nasrullah Ainul Yaqin
Namaku Patra;Novel atau Novela;Faizatus Safiah
Negasi;Novel atau Novela;Danara
Nyutrayu;Novel atau Novela;Joko Gesang Santoso
Olivia;Novel atau Novela;Febri Angraini
One Name Disappeared in the World;Novel atau Novela;Samisih
Opera Sabun Mandi;Novel atau Novela;Trio Fajar Kurniawan
Our Home;Novel atau Novela;Amanda Christabella
Pahit.Manis;Novel atau Novela;Semesta Aksara
Pahlawan di Tepian Zaman;Novel atau Novela;Nur Cholish Majid
Pahlawan Hati ;Esai atau Memoar;Sarifah Aini Ritonga
Panah Untuk Claudia;Novel atau Novela;Marshalino Artland Ruimassa
Panggung Terakhir;Novel atau Novela;Faye Yolody
Papa dan Ayah;Novel atau Novela;Maurinta
Papua membuatku Jatuh Cinta;Tulisan Perjalanan;Siti Mukifah
Part Of My Wound;Novel atau Novela;Imelda Hafizah
Partition (Phase 1);Novel atau Novela;Yurirsa Pradhipta
Patahkan Saja Hatinya!;Novel atau Novela;Elvira Tania
Patetis;Novel atau Novela;Febry Nur Rafahmi
Pembelajaran Jarak Jauh;Novel atau Novela;Cindy Wijaya
Pemburu-Pemburu Celaka;Novel atau Novela;Mochamad Nasrullah
Pencarian ;Novel atau Novela;Yokko cesoeria lubis
Pencarian ;Novel atau Novela;Sekar Aprilia Maharani
Pendidikan Pembebasan;Esai atau Memoar;Ade Mulyono
Perempuan dan Penanya (Dalam Buku, Sastra, Bahasa);Esai atau Memoar;Anindita S Thayf
Perempuan Proyek;Novel atau Novela;Iroy
Perempuan yang Mendistorsi Semesta;Novel atau Novela;Riyanto
Perempuan yang Menyengam Sejarah;Novel atau Novela;Rian Gunady Putra (RG Putra)
Peri Kecil Patah Sayap;Novel atau Novela;Isna Hayu Rizqina
Perihal Aku dan Diri Berdiri, Mencari dan Berdamai;Tulisan Perjalanan;Ayunda Hanifah
Perjalanan Rahasia (Raga, Hasrat, Jiwa dan Asa);Novel atau Novela;Annisa Adysti Ayunanditia
Perjanjian Dengan Arwah;Novel atau Novela;Kakanda Redi
Pernikahan Doktor ANN;Novel atau Novela;Almunifah Nashir
Pesan dari Perjalanan;Esai atau Memoar;Risty Khoirunisa
Pesona Nusantara dalam Kata;Tulisan Perjalanan;Akhmad Idris
Pesta Boneka;Novel atau Novela;Ahmad Dicka Hudzaifi
Petaka;Novel atau Novela;Beni Irawan
Piet;Novel atau Novela;Rindy Agassi
Piutang Rindu (Pendekatan Novel Berbasis Akuntansi);Novel atau Novela;Fadli Refualu
Planet Biru;Novel atau Novela;Yuhana Kusumaningrum
Pocinta;Novel atau Novela;Akhmad Sekhu
Pohon Emas;Novel atau Novela;Yudhi Herwibowo
Pramanik;Novel atau Novela;Adya Tuti
Pramoedya, Multatuli dan Buli Buli Nirwan;Esai atau Memoar;Endhiq Anang P
Puisi untuk Dirga;Novel atau Novela;Selviani
Pulang;Tulisan Perjalanan;Cybil Azri Yusnandha
Pulih;Novel atau Novela;Miranda Seftiana
Queen of The South Beach;Novel atau Novela;Ahmad Dicka Hudzaifi
Rantai Makanan;Novel atau Novela;Ahmad Dicka Hudzaifi
Redite;Novel atau Novela;Sri Nurani
Regal Couture;Novel atau Novela;Saxifraga
Rekam Jejak Langkah Kakiku;Tulisan Perjalanan;Diza Inuchia
Relief of Love;Novel atau Novela;Geti Oktaria Pulungan
Republik Rimba Raya;Novel atau Novela;Prilda Titi Saraswati
Resolusi Ketujuh;Novel atau Novela;agustina masito
Rin dan Pulau Pemimpi;Novel atau Novela;Saskara Jeneva
Rindu untuk sang Pendusta;Novel atau Novela;Yurizka
Rinne;Novel atau Novela;Evan Yovian
Risa;Novel atau Novela;rizqyfebrina
Riset di Finlandia: Catatan para Petualang Intelektual;Esai atau Memoar;Adrianto Dwi Nugroho dkk.
Riwayat Gila Swargaloka;Novel atau Novela;Pangga Rahmad
Riwayat Gila Swargaloka;Novel atau Novela;Pangga Rahmad
Rumah Pertama;Novel atau Novela;Wicak Hidayat
Rumah Syurga Para Bidadari;Novel atau Novela;Fathor Rosi
S (Sepotong Senja Sepenggal Sangka);Novel atau Novela;Heru Patria
Saat Kaki Bercerita;Tulisan Perjalanan;Ziadah
Salah kirim file, pakai yang judulnya: Cincin Perak Bermata Putih (REVISI);Novel atau Novela;Erwan Hamdani
Salju pun Turun di Jakarta;Novel atau Novela;Hizbul Ridho
Salma Tidak Diciptakan dari Tulang Rusuk Angki;Novel atau Novela;Bobi Tuankotta
Samarandu;Novel atau Novela;Ade Maulana
Samsara;Novel atau Novela;Bagus Anugerah Yoga
Sapphire In The Lavender Garden;Novel atau Novela;Ariska Putri Nugroho (Aoirisuka)
Sasmita dan Hikayat Burung Bence;Novel atau Novela;Hari Niskala
Sastra Komunal Era Digital - Sehimpun Esai dan Kritik Sastra;Esai atau Memoar;Wahyu Kris
Sastra, Manusia, dan Negara;Esai atau Memoar;Akhmad Idris
Satu Bulan Tiga Rindu;Novel atau Novela;Yus Gesa
Saujana;Tulisan Perjalanan;Denai Guna
Sebongkah Roti, Sepotong Keju, dan Sebakul Gegar;Esai atau Memoar;Lusi Wulan
Sebuah Seni Bunuh Diri (Alternative Story);Novel atau Novela;Syarifudin Emseh
Sebuah Tempat untuk Pulang;Novel atau Novela;Rizqi Turama
Sejarah Gedung;Novel atau Novela;Ken Hanggara
Sejarah Perilaku Gila;Novel atau Novela;Han Gagas
Sekisah tentang Mualim dengan Fatimah;Novel atau Novela;Andriyana
Sekuntum Bunga Padma di Tresna Jalma;Novel atau Novela;Indra Agusta
Selusoh Sialang Rayo;Novel atau Novela;Iroy Mahyuni
Semata Akuarium;Novel atau Novela;Joan Oktavianie
Sempurna itu Kamu;Novel atau Novela;Windra Yuniarsih
Sendiko Gusti;Novel atau Novela;Dani Ismantoko
Senja di Lukisan;Novel atau Novela;Amy Djafar
Senja di Tanah Senja;Novel atau Novela;Susan Swansh
Senyawa;Novel atau Novela;Ria Lusiandri
Seorang Bulan, Sebuah Paus, dan Seekor Pria;Novel atau Novela;Odelia
Separuh Asa Sepenuh Rasa;Novel atau Novela;Dewi Alusia Laras
Sepasang Perisai Bumi;Novel atau Novela;Ira Oktiana
Seseorang yang Berdiri di Tepian Pekat;Novel atau Novela;Wisnu Adi Pratama
Shaddaw;Novel atau Novela;Vania Putri
Sharaza;Novel atau Novela;Yunita Safitri
Si Rara Ayu: Romansa ketika Tuhan Memberinya Kesatria;Novel atau Novela;Riri Hasa a.k.a Nuri Puji Hastuti
Siapa dia;Novel atau Novela;Akmal fadila
Simfoni di Hati Zara;Novel atau Novela;Asri Suciati
Sisi Kelam Dunia Literasi (Dunia Literasi? Hmmm ... Banyak Dramanya!;Tulisan Perjalanan;Ariny NH
Something Called Great Perhaps;Novel atau Novela;Febrianti Rahmalia Aldi
Sonata Untuk Orang Baik;Novel atau Novela;Hidayat D. Setyawan
Sonia;Novel atau Novela;Ahmad Rusdy
Spekulatif dan esai-esai lainnya;Esai atau Memoar;Wahyu Heriyadi
Stay With The Prince;Novel atau Novela;Shirley Du
Stupid Friendship;Novel atau Novela;Lattaphie_
Sudahi Sedih;Novel atau Novela;Hanif Iqbal
Sunyi dalam Kegelapan ;Novel atau Novela;Agusti Syahara, nama pena: arasyahh.a (katekuchan)
sweet but possessive;Novel atau Novela;maryam uffa
Tanah Timah;Novel atau Novela;Yersita
Tears of A Winner;Esai atau Memoar;Rosyda Amalia
Teen Dream;Novel atau Novela;Lukiarti Wardhani
Tempe Kemul;Novel atau Novela;Zee Astri
Tempo Hari;Novel atau Novela;SirroveneanGegeulu
Terdampar;Novel atau Novela;Joanne
Terlelap;Novel atau Novela;Topan Adiningrat
Ternyata Bulan Tidak Selalu Sama;Novel atau Novela;Wilman Praja Ibrahim
Ternyata Tresno;Novel atau Novela;Radityo Adi Wibowo
Terra Incognita;Novel atau Novela;Bagus Dwi Hananto
Thantophobia : Rasa Takut Kehilangan Orang yang Dicintai;Novel atau Novela;
the Arbitrator;Novel atau Novela;Ade Meirizal
The Black Hole;Novel atau Novela;Jufan Rizky
The Cursed Girl Finds Love;Novel atau Novela;Nabilla Anasty Fahzaria
The Edge of The Cliff;Novel atau Novela;Yurirsa Pradhipta
The Greatest Memories;Novel atau Novela;Lxhaprl
The Hearer;Novel atau Novela;Deby Safitri Primadani
The Million Faces;Esai atau Memoar;Ziwa Raisa
The Miracle Of Villa;Novel atau Novela;Melisa Carolus
The Story from Bromo;Novel atau Novela;Ilham Nursyifa
The Unspoken Words of Langit and Samudra;Novel atau Novela;Rindi Nuris Velarosdela
This is My Spring;Novel atau Novela;Chiaulina
Titik Balik;Novel atau Novela;Meilia Citra
Titik Temu;Novel atau Novela;Meilia Citra
Tokyo Red;Novel atau Novela;Bagus Dwi Hananto
Topeng kelinci;Novel atau Novela;Sufi
Torean;Novel atau Novela;Aliurridha
Toxic dan Rahasia-rahasia yang disembunyikannya;Novel atau Novela;Ni Nyoman Ayu Suciartini (Ayusha)
Travelling Hemat UK & Russia (under 10 jt);Tulisan Perjalanan;Riswihani
True Knight;Novel atau Novela;Trisnajaya Shalsabila
Tunggal;Novel atau Novela;Iroy Mahyuni
Tuntunlah Aku Menju Syurga My Husband;Novel atau Novela;Nur Aflah Yusdha
Tutur Dedes: Doa dan Kutukan;Novel atau Novela;Amalia Yunus
Ular Jahat;Novel atau Novela;Ang Rijal Amin
Untukmu, Aku Akan;Novel atau Novela;arleen a.
Usaha Seorang Lelaki dalam Menghardik Sunyi;Novel atau Novela;Ahmad Yusrifan Amrullah
UTOPIA Katak Menerkam Tempurung, selilit esai sastra;Esai atau Memoar;Anton Suparyanta
Uwais Melamar Guru Negeri;Novel atau Novela;Mustofa Psi
Virus dan Kutu di Jendela Dunia;Esai atau Memoar;Setyaningsih
wanita yang selingkuh dengan ular;Novel atau Novela;Cicilia Oday
Warung Jorok, Orang Kaya, dan Pancasila;Esai atau Memoar;Arie Saptaji
When The Rain Stops;Novel atau Novela;Wulan Cahyaningsih
Willechomen im Erinnerungkr‰m;Novel atau Novela;Azzahra R Kamila
Written In The Stars;Novel atau Novela;Andyni Hardianto
Yang Mungkin Ditemukan Saat Kita Menemukan Kata;Esai atau Memoar;Wawan Kurniawan
Yang Selalu Diumpet Rapat-Rapat;Novel atau Novela;Bobi Tuankotta
Yatra & Madhyaantar;Tulisan Perjalanan;Haryadi Yansyah
Yogyakarta Love Story;Novel atau Novela;Ahmad Dicka Hudzaifi
You Drives Me Crazy!;Novel atau Novela;Anindita Widya Ningrum
Zakia Aro;Novel atau Novela;Zulfairy
Zaman yang Dikalengkan;Esai atau Memoar;Andri Wikono
Zebrod;Novel atau Novela;Denny Herdy
Zoom, Why Did He Leave Meeting Quickly?;Novel atau Novela;Maurinta
`

const jsonData = {
    columns: [
        {
            label: 'Judul Naskah',
            field: 'Judul Naskah',
            sort: 'asc',
        },
        {
            label: 'Kategori',
            field: 'Kategori',
            sort: 'asc',
        },
        {
            label: 'Penulis',
            field: 'Penulis',
            sort: 'asc',
        },
    ],
    rows: [
        {
            "Judul Naskah": 2020,
            "Kategori": "Novel atau Novela",
            "Penulis": "Tian"
        },
        {
            "Judul Naskah": "(Mega)mendung",
            "Kategori": "Novel atau Novela",
            "Penulis": "B.E. Raynangge"
        },
        {
            "Judul Naskah": "(Tak) Seindah Bunga Lili",
            "Kategori": "Novel atau Novela",
            "Penulis": "Marliana Kuswanti"
        },
        {
            "Judul Naskah": "$ALFIE$",
            "Kategori": "Novel atau Novela",
            "Penulis": "Andra"
        },
        {
            "Judul Naskah": "11 Day",
            "Kategori": "Novel atau Novela",
            "Penulis": "pena putih"
        },
        {
            "Judul Naskah": "A Letter from Budapest",
            "Kategori": "Novel atau Novela",
            "Penulis": "Risty Khoirunisa"
        },
        {
            "Judul Naskah": "A Worker's Life",
            "Kategori": "Novel atau Novela",
            "Penulis": "Catharina Novia Christanti"
        },
        {
            "Judul Naskah": "Adlan & Adlina",
            "Kategori": "Novel atau Novela",
            "Penulis": "Nur Laila"
        },
        {
            "Judul Naskah": "Adu Rayu",
            "Kategori": "Novel atau Novela",
            "Penulis": "Mayangputri"
        },
        {
            "Judul Naskah": "Aftretaste",
            "Kategori": "Novel atau Novela",
            "Penulis": "Tary Lestari"
        },
        {
            "Judul Naskah": "Ahli Waris",
            "Kategori": "Novel atau Novela",
            "Penulis": "Sika Indriyawati"
        },
        {
            "Judul Naskah": "Airlangga",
            "Kategori": "Novel atau Novela",
            "Penulis": "Yeni Fitriyani"
        },
        {
            "Judul Naskah": "Aku dan Mereka Satu Jiwa",
            "Kategori": "Novel atau Novela",
            "Penulis": "Fitriyanti Setioninggrum"
        },
        {
            "Judul Naskah": "Aku Ingin Berubah",
            "Kategori": "Novel atau Novela",
            "Penulis": "Luthfiyah Qurrotul Aini"
        },
        {
            "Judul Naskah": "Aku Manusian yang Bangun Tidur Bahagia",
            "Kategori": "Novel atau Novela",
            "Penulis": "Mahar Alima"
        },
        {
            "Judul Naskah": "Aku Menulis, Maka Aku Ada",
            "Kategori": "Novel atau Novela",
            "Penulis": "Arifah Jauhari Syams"
        },
        {
            "Judul Naskah": "Amulet Suwarnadwipa",
            "Kategori": "Novel atau Novela",
            "Penulis": "Alexandro Dominic Harun"
        },
        {
            "Judul Naskah": "Anak Angin di Kerajaan Aero",
            "Kategori": "Novel atau Novela",
            "Penulis": "Kandil Sukma Ayu"
        },
        {
            "Judul Naskah": "Anatema",
            "Kategori": "Novel atau Novela",
            "Penulis": "Dwi Candra Nurrohman"
        },
        {
            "Judul Naskah": "Anathema",
            "Kategori": "Novel atau Novela",
            "Penulis": "Deby Saditri Primadani (Mahesvara)"
        },
        {
            "Judul Naskah": "Angel's Letters",
            "Kategori": "Novel atau Novela",
            "Penulis": "Yomita Sonia Tanjung"
        },
        {
            "Judul Naskah": "Angin di balik Tornado",
            "Kategori": "Novel atau Novela",
            "Penulis": "Tsaqifa Aulya"
        },
        {
            "Judul Naskah": "Antara Kamu dan Guru BK (The Youtube Podcast)",
            "Kategori": "Novel atau Novela",
            "Penulis": "Msutofa P"
        },
        {
            "Judul Naskah": "Antibiotik: Dokter, Mafia Obat, dan Jurnalistik",
            "Kategori": "Novel atau Novela",
            "Penulis": "Sirojul Khafid"
        },
        {
            "Judul Naskah": "Arath Lulureh",
            "Kategori": "Novel atau Novela",
            "Penulis": "A. Rifka Afifah"
        },
        {
            "Judul Naskah": "Are You Rainbow?",
            "Kategori": "Novel atau Novela",
            "Penulis": "Lely Qurrota A'yun"
        },
        {
            "Judul Naskah": "Arti Cinta",
            "Kategori": "Novel atau Novela",
            "Penulis": "Marlia Ayuni"
        },
        {
            "Judul Naskah": "Arunika",
            "Kategori": "Novel atau Novela",
            "Penulis": "Esti Menur Sukanti"
        },
        {
            "Judul Naskah": "Awal Mula Mengeja",
            "Kategori": "Novel atau Novela",
            "Penulis": "Udiarti"
        },
        {
            "Judul Naskah": "Awiligar",
            "Kategori": "Novel atau Novela",
            "Penulis": "ali busche"
        },
        {
            "Judul Naskah": "Ayah, Kita Orang Indonesia, Kan?",
            "Kategori": "Novel atau Novela",
            "Penulis": "Maurinta"
        },
        {
            "Judul Naskah": "Azka - Rahasia Sarang Cakrawala",
            "Kategori": "Novel atau Novela",
            "Penulis": "Sintong Arfiyansyah"
        },
        {
            "Judul Naskah": "Batas",
            "Kategori": "Novel atau Novela",
            "Penulis": "AL Wijaya"
        },
        {
            "Judul Naskah": "Batas Kenyataan",
            "Kategori": "Novel atau Novela",
            "Penulis": "Bella Puteri Shafariyenni"
        },
        {
            "Judul Naskah": "Beritahu Aku Suatu Hari Nanti",
            "Kategori": "Novel atau Novela",
            "Penulis": "rimachmed"
        },
        {
            "Judul Naskah": "Beutiful Mind",
            "Kategori": "Novel atau Novela",
            "Penulis": "Yanni Langen"
        },
        {
            "Judul Naskah": "Bidadari Surga Abizar",
            "Kategori": "Novel atau Novela",
            "Penulis": "Arfi Stiyani"
        },
        {
            "Judul Naskah": "Bingkai Surga Untuk Raiha",
            "Kategori": "Novel atau Novela",
            "Penulis": "ZiAldina"
        },
        {
            "Judul Naskah": "Blacklight Dawn",
            "Kategori": "Novel atau Novela",
            "Penulis": "Anky Prasetya"
        },
        {
            "Judul Naskah": "Bondari",
            "Kategori": "Novel atau Novela",
            "Penulis": "Fikra Fadilla"
        },
        {
            "Judul Naskah": "Boneka Sosial",
            "Kategori": "Novel atau Novela",
            "Penulis": "Morella Mulyadina"
        },
        {
            "Judul Naskah": "Bu Guru Nadia",
            "Kategori": "Novel atau Novela",
            "Penulis": "Risalati"
        },
        {
            "Judul Naskah": "bukan penembak jitu",
            "Kategori": "Novel atau Novela",
            "Penulis": "suharyo widagdo"
        },
        {
            "Judul Naskah": "Bukan Tentang Hari Ini",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Ach Sufyan Wahyudi"
        },
        {
            "Judul Naskah": "Bunga untuk Arjuna",
            "Kategori": "Novel atau Novela",
            "Penulis": "Yuanita Fransiska"
        },
        {
            "Judul Naskah": "Busway",
            "Kategori": "Novel atau Novela",
            "Penulis": "Dian S. Hadiningrum"
        },
        {
            "Judul Naskah": "Cakrawala Nusantara: Pencarian Gelang Mpu Nala",
            "Kategori": "Novel atau Novela",
            "Penulis": "Alexandro Dominic Harun"
        },
        {
            "Judul Naskah": "Candra Bagaskara: Rajapati Manjer Kawuryan",
            "Kategori": "Novel atau Novela",
            "Penulis": "Putriyana Asmarani"
        },
        {
            "Judul Naskah": "Cara Esay Menulis Esai, Panduan Praktis Menulis Untuk Para Guru",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Sofyan Aziz"
        },
        {
            "Judul Naskah": "Cara Terbaik Menulis Kitab Suci",
            "Kategori": "Novel atau Novela",
            "Penulis": "Yudhi Herwibowo"
        },
        {
            "Judul Naskah": "Cara Terburuk Untuk Melupakan",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ramona Sandi"
        },
        {
            "Judul Naskah": "Catatan Harian Anak-Anak Khatuslitiwa",
            "Kategori": "Novel atau Novela",
            "Penulis": "Elia Okki"
        },
        {
            "Judul Naskah": "Catatan Kecil",
            "Kategori": "Novel atau Novela",
            "Penulis": "Katyusha Melody Rusli"
        },
        {
            "Judul Naskah": "Catatan Seorang Bajingan",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Ra!"
        },
        {
            "Judul Naskah": "Cincin Perak Bermata Putih",
            "Kategori": "Novel atau Novela",
            "Penulis": "Erwan Hamdani"
        },
        {
            "Judul Naskah": "Cinta Hadir di Benua ke 3",
            "Kategori": "Novel atau Novela",
            "Penulis": "Putri Nur Pratiwi"
        },
        {
            "Judul Naskah": "Cinta Tanpa Alasan",
            "Kategori": "Novel atau Novela",
            "Penulis": "Willy Pangeran"
        },
        {
            "Judul Naskah": "Cinta Terlarang",
            "Kategori": "Novel atau Novela",
            "Penulis": "Dyan Roh Ayu"
        },
        {
            "Judul Naskah": "Cintaku Tak Salah?",
            "Kategori": "Novel atau Novela",
            "Penulis": "Anima"
        },
        {
            "Judul Naskah": "Classic",
            "Kategori": "Novel atau Novela",
            "Penulis": "Desi Ramadani"
        },
        {
            "Judul Naskah": "Courage",
            "Kategori": "Novel atau Novela",
            "Penulis": "Kartika Aska/ Kartikha Sri Rahmayanty"
        },
        {
            "Judul Naskah": "Darah dan Mimpi",
            "Kategori": "Novel atau Novela",
            "Penulis": "Latif Fianto"
        },
        {
            "Judul Naskah": "Demi Kalian",
            "Kategori": "Novel atau Novela",
            "Penulis": "Anima"
        },
        {
            "Judul Naskah": "Demi Yono",
            "Kategori": "Novel atau Novela",
            "Penulis": "Deviannistia Suyonoputri"
        },
        {
            "Judul Naskah": "Di Lingkaran Api",
            "Kategori": "Novel atau Novela",
            "Penulis": "Fajar R Ayuningtyas"
        },
        {
            "Judul Naskah": "Dia yang Tidak Disebutkan Namanya",
            "Kategori": "Novel atau Novela",
            "Penulis": "Januar Eriyanto"
        },
        {
            "Judul Naskah": "Diary Wanita Hebat",
            "Kategori": "Novel atau Novela",
            "Penulis": "Aroma"
        },
        {
            "Judul Naskah": "Dibalik Rahasia Tergelap",
            "Kategori": "Novel atau Novela",
            "Penulis": "Yesika Samara"
        },
        {
            "Judul Naskah": "Dispersionis",
            "Kategori": "Novel atau Novela",
            "Penulis": "Intan N.S."
        },
        {
            "Judul Naskah": "Don't Fall For Me",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ariska Putri Nugroho (Aoirisuka)"
        },
        {
            "Judul Naskah": "Dona Nobis Pacem",
            "Kategori": "Novel atau Novela",
            "Penulis": "Dionisius Sapto Nugroho"
        },
        {
            "Judul Naskah": "Dua Keberuntungan",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ika Arif Wulandari"
        },
        {
            "Judul Naskah": "Dunia katleya, Dunia Kadhafi",
            "Kategori": "Novel atau Novela",
            "Penulis": "Y Agusta Akhir"
        },
        {
            "Judul Naskah": "Dunia Literasi dari Kenangan Hingga Fredy S",
            "Kategori": "Esai atau Memoar",
            "Penulis": "F Daus AR"
        },
        {
            "Judul Naskah": "Dunia Romantisme dimana Chandie Hidup",
            "Kategori": "Novel atau Novela",
            "Penulis": "Vakker Foss dan Flowchan"
        },
        {
            "Judul Naskah": "Ego",
            "Kategori": "Novel atau Novela",
            "Penulis": "W_eird"
        },
        {
            "Judul Naskah": "Endles Love",
            "Kategori": "Novel atau Novela",
            "Penulis": "Istiyanti Utami"
        },
        {
            "Judul Naskah": "Etalase Sepatu",
            "Kategori": "Novel atau Novela",
            "Penulis": "Tian Topandi"
        },
        {
            "Judul Naskah": "Fake Memories",
            "Kategori": "Novel atau Novela",
            "Penulis": "Farah Nadia"
        },
        {
            "Judul Naskah": "Falang: Catatan Perjalanan ke Beberapa Negara di Asia Tenggara",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "Fuji Adriza"
        },
        {
            "Judul Naskah": "Finding Ara",
            "Kategori": "Novel atau Novela",
            "Penulis": "Najiyah"
        },
        {
            "Judul Naskah": "Gaea",
            "Kategori": "Novel atau Novela",
            "Penulis": "Brenda Maria"
        },
        {
            "Judul Naskah": "Gaia",
            "Kategori": "Novel atau Novela",
            "Penulis": "Yuhana Kusumaningrum"
        },
        {
            "Judul Naskah": "Galaunya Seperempat Abad",
            "Kategori": "Novel atau Novela",
            "Penulis": "Monica"
        },
        {
            "Judul Naskah": "Gembel Doyan Jalan",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "Agung Dermawan"
        },
        {
            "Judul Naskah": "Gula, Gula",
            "Kategori": "Novel atau Novela",
            "Penulis": "Nuril Basri"
        },
        {
            "Judul Naskah": "Gunung Mengajarkan Filosofi Kehidupan",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "Debora Juliana"
        },
        {
            "Judul Naskah": "Hadiah Perjalanan",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "Natalia Oetama"
        },
        {
            "Judul Naskah": "Hanya Karena Cinta",
            "Kategori": "Novel atau Novela",
            "Penulis": "Anima"
        },
        {
            "Judul Naskah": "Harta Karun di Ujung Pelangi",
            "Kategori": "Novel atau Novela",
            "Penulis": "Bobi Tuankotta"
        },
        {
            "Judul Naskah": "Hidup Dalam Koma",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ratu Agung Ayu Yulianatasya Agustina"
        },
        {
            "Judul Naskah": "HOMO RELIGIUS: Refleksi Religiusitas Masyarakat Urban di Era Pandemi",
            "Kategori": "Esai atau Memoar",
            "Penulis": "A. Fikri Amiruddin Ihsani"
        },
        {
            "Judul Naskah": "Hubbyna",
            "Kategori": "Novel atau Novela",
            "Penulis": "Anis Mubsiroh"
        },
        {
            "Judul Naskah": "I Wish You Were Here",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ryscha Dwi Iryani"
        },
        {
            "Judul Naskah": "Iblis dari Hutan Kan",
            "Kategori": "Novel atau Novela",
            "Penulis": "Sukron Hadi"
        },
        {
            "Judul Naskah": "Ibu Bukan Pelacur",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ansar Siri"
        },
        {
            "Judul Naskah": "Ikan-Ikan dan Kunang-Kunang di Kedung Mayit",
            "Kategori": "Novel atau Novela",
            "Penulis": "Dewanto Amin Sadono"
        },
        {
            "Judul Naskah": "Ilu Ilalang",
            "Kategori": "Novel atau Novela",
            "Penulis": "Al Ayubi"
        },
        {
            "Judul Naskah": "Imlek dan Empu",
            "Kategori": "Novel atau Novela",
            "Penulis": "Maurinta"
        },
        {
            "Judul Naskah": "impian dan halangan",
            "Kategori": "Novel atau Novela",
            "Penulis": "mathoyatul ma'rifah"
        },
        {
            "Judul Naskah": "In The End of The Day",
            "Kategori": "Novel atau Novela",
            "Penulis": "Hafshah Widya Arini"
        },
        {
            "Judul Naskah": "Ingatan Masa Lalu",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ria Yulia Priskilla"
        },
        {
            "Judul Naskah": "Insecure Inside Of Me",
            "Kategori": "Novel atau Novela",
            "Penulis": "Jafarrudin"
        },
        {
            "Judul Naskah": "Insekta",
            "Kategori": "Novel atau Novela",
            "Penulis": "Wi Noya"
        },
        {
            "Judul Naskah": "INSIGHT : When You See Me From Another Side",
            "Kategori": "Novel atau Novela",
            "Penulis": "Yunita Widyaningsih"
        },
        {
            "Judul Naskah": "Jagad Pramudita",
            "Kategori": "Novel atau Novela",
            "Penulis": "Gusti Aditya Suprihana"
        },
        {
            "Judul Naskah": "Jalan Keluar Berduri",
            "Kategori": "Novel atau Novela",
            "Penulis": "Rio Hosana"
        },
        {
            "Judul Naskah": "Jalan Setapak Menuju Rumah",
            "Kategori": "Novel atau Novela",
            "Penulis": "Rafael Yanuar"
        },
        {
            "Judul Naskah": "Jawaban yang Tak Pernah Sempurna Perihal Cinta",
            "Kategori": "Novel atau Novela",
            "Penulis": "Juli Prasetya"
        },
        {
            "Judul Naskah": "Jejak di Balik Waktu",
            "Kategori": "Novel atau Novela",
            "Penulis": "Tiarda Simarmata"
        },
        {
            "Judul Naskah": "Jejak Kaki Kita",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "Precillia Leonita"
        },
        {
            "Judul Naskah": "Jelaga dan Hujan Kering",
            "Kategori": "Novel atau Novela",
            "Penulis": "Winnie Tjahjono"
        },
        {
            "Judul Naskah": "Jemantik",
            "Kategori": "Novel atau Novela",
            "Penulis": "Adia Puja"
        },
        {
            "Judul Naskah": "Jendela Senja Rea",
            "Kategori": "Novel atau Novela",
            "Penulis": "Retnoasih"
        },
        {
            "Judul Naskah": "Jo's Bucket List",
            "Kategori": "Novel atau Novela",
            "Penulis": "saff"
        },
        {
            "Judul Naskah": "Jodoh Jenaka",
            "Kategori": "Novel atau Novela",
            "Penulis": "Nur Azizah"
        },
        {
            "Judul Naskah": "Judul Naskah",
            "Kategori": "Jenis Naskah",
            "Penulis": "Nama Penulis"
        },
        {
            "Judul Naskah": "Kacapiring Mekar Tak Jauh dari Rawanya",
            "Kategori": "Novel atau Novela",
            "Penulis": "Alfa Mahsuni"
        },
        {
            "Judul Naskah": "Kamelia Putih",
            "Kategori": "Novel atau Novela",
            "Penulis": "Nicco Machi"
        },
        {
            "Judul Naskah": "Karena Wajah Tuhan",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Kafha"
        },
        {
            "Judul Naskah": "Kartu Hati dan Mimpi",
            "Kategori": "Novel atau Novela",
            "Penulis": "Raisa Fardani"
        },
        {
            "Judul Naskah": "Kausa Mimpi",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ihza Handika"
        },
        {
            "Judul Naskah": "Kaylova",
            "Kategori": "Novel atau Novela",
            "Penulis": "Melati Afdhila"
        },
        {
            "Judul Naskah": "Ke Malaysia, ke Tanah Perang",
            "Kategori": "Novel atau Novela",
            "Penulis": "Romi Afriadi"
        },
        {
            "Judul Naskah": "Keheningan Batin, Cahaya, dan Sebuah Puisi",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Yus Amin. Db"
        },
        {
            "Judul Naskah": "Kelas-kelas di Luar Ruang Perkuliahan",
            "Kategori": "Novel atau Novela",
            "Penulis": "Wahyudi Bahtiar"
        },
        {
            "Judul Naskah": "Kembali Ke Awal",
            "Kategori": "Novel atau Novela",
            "Penulis": "AL Wijaya"
        },
        {
            "Judul Naskah": "Kidung Hening",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Esthy Wika"
        },
        {
            "Judul Naskah": "Kisah Mereka",
            "Kategori": "Novel atau Novela",
            "Penulis": "Asia Wistaria"
        },
        {
            "Judul Naskah": "Kisah Satu Hari yang Berakhir dengan Putusnya Alat Kelamin",
            "Kategori": "Novel atau Novela",
            "Penulis": "Adia Puja"
        },
        {
            "Judul Naskah": "Kita",
            "Kategori": "Novel atau Novela",
            "Penulis": "Aliyudin"
        },
        {
            "Judul Naskah": "Komidi Putar Witarsih",
            "Kategori": "Novel atau Novela",
            "Penulis": "Andriyana"
        },
        {
            "Judul Naskah": "Konstelasi Lestari",
            "Kategori": "Novel atau Novela",
            "Penulis": "edira putri"
        },
        {
            "Judul Naskah": "Kopi Subuh untuk Bapak",
            "Kategori": "Novel atau Novela",
            "Penulis": "Hadi Mulyono"
        },
        {
            "Judul Naskah": "Ksatria Khandra",
            "Kategori": "Novel atau Novela",
            "Penulis": "Prilda Titi Saraswati"
        },
        {
            "Judul Naskah": "Ku lepas kau dengan ikhlas",
            "Kategori": "Novel atau Novela",
            "Penulis": "Juliana"
        },
        {
            "Judul Naskah": "Kuda Nonggeng Membelah Jakarta",
            "Kategori": "Novel atau Novela",
            "Penulis": "Wildan.S.Chopa"
        },
        {
            "Judul Naskah": "Land of Freedom",
            "Kategori": "Novel atau Novela",
            "Penulis": "Sigit Candra Lesmana"
        },
        {
            "Judul Naskah": "Langit Magenta",
            "Kategori": "Novel atau Novela",
            "Penulis": "Sundea"
        },
        {
            "Judul Naskah": "Langkah-langkah Membunuh Kenangan",
            "Kategori": "Novel atau Novela",
            "Penulis": "Mawan Belgia"
        },
        {
            "Judul Naskah": "Larung Suwung",
            "Kategori": "Novel atau Novela",
            "Penulis": "Tary Lestari"
        },
        {
            "Judul Naskah": "Lebah Pukul 10:00 pm",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ogie Munaya"
        },
        {
            "Judul Naskah": "Lecah",
            "Kategori": "Novel atau Novela",
            "Penulis": "Rina R. Ridwan  (Nur Rina Chairani)"
        },
        {
            "Judul Naskah": "Legendarium Elenar (Kisah-kisah di Bumi Tengah)",
            "Kategori": "Novel atau Novela",
            "Penulis": "Syihabul Furqon"
        },
        {
            "Judul Naskah": "Lelaki Dari Masa Lalu",
            "Kategori": "Novel atau Novela",
            "Penulis": "Angelia Maharani"
        },
        {
            "Judul Naskah": "Lelaki yang Bercerita tentang Suara",
            "Kategori": "Novel atau Novela",
            "Penulis": "Sayyidatul Imamah"
        },
        {
            "Judul Naskah": "Liminal",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "Algonz Dimas B. Raharja"
        },
        {
            "Judul Naskah": "Lipur",
            "Kategori": "Novel atau Novela",
            "Penulis": "Latif Pungkasniar"
        },
        {
            "Judul Naskah": "Little Monster",
            "Kategori": "Novel atau Novela",
            "Penulis": "Tsalitsa Normadina"
        },
        {
            "Judul Naskah": "Logika Mayorita Mengungkung Kreativitas Kita",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Aliurridha"
        },
        {
            "Judul Naskah": "Lonceng Gereja dan Azan di Israel",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "Han Gagas"
        },
        {
            "Judul Naskah": "Loss And Breakage",
            "Kategori": "Novel atau Novela",
            "Penulis": "Choirul Anwar"
        },
        {
            "Judul Naskah": "Love In The Middle Of Corona",
            "Kategori": "Novel atau Novela",
            "Penulis": "Johan Aindy Putra Lubis"
        },
        {
            "Judul Naskah": "Love You Dear.......?",
            "Kategori": "Novel atau Novela",
            "Penulis": "Anima"
        },
        {
            "Judul Naskah": "Luka? (Sebuah Usaha Menyikapi Patah Hati, Supaya tak Berujung Menyakiti)",
            "Kategori": "Novel atau Novela",
            "Penulis": "Abdul Hamid (Hamid mmd)"
        },
        {
            "Judul Naskah": "M(a)mpir",
            "Kategori": "Novel atau Novela",
            "Penulis": "Jihan Bintang Pamungkas"
        },
        {
            "Judul Naskah": "Mahkota Raja",
            "Kategori": "Novel atau Novela",
            "Penulis": "Lissiery Khanran"
        },
        {
            "Judul Naskah": "manusia bertopeng",
            "Kategori": "Novel atau Novela",
            "Penulis": "suharyo widagdo"
        },
        {
            "Judul Naskah": "Marcapada",
            "Kategori": "Novel atau Novela",
            "Penulis": "Fajrin Yusup Muttaqin"
        },
        {
            "Judul Naskah": "Marissa",
            "Kategori": "Novel atau Novela",
            "Penulis": "Kangwindo"
        },
        {
            "Judul Naskah": "Markombur",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Acha Hallatu"
        },
        {
            "Judul Naskah": "Masih Ada Harapan",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "Maria Viktoria Yusta Alus"
        },
        {
            "Judul Naskah": "Mataluina",
            "Kategori": "Novel atau Novela",
            "Penulis": "Dimas Putra Ramadhan"
        },
        {
            "Judul Naskah": "Mataya",
            "Kategori": "Novel atau Novela",
            "Penulis": "Yuditeha"
        },
        {
            "Judul Naskah": "Maya",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ahmad Luel"
        },
        {
            "Judul Naskah": "Maysaroh Dalam Bahaya",
            "Kategori": "Novel atau Novela",
            "Penulis": "Eko Sabto Utomo"
        },
        {
            "Judul Naskah": "Me And Five Sister",
            "Kategori": "Novel atau Novela",
            "Penulis": "Wa Ode Al Wahyuni Madu"
        },
        {
            "Judul Naskah": "Mega",
            "Kategori": "Novel atau Novela",
            "Penulis": "Kevin Farrel Saifan"
        },
        {
            "Judul Naskah": "Melawan Nasib",
            "Kategori": "Novel atau Novela",
            "Penulis": "Tiffani Yulita"
        },
        {
            "Judul Naskah": "Melihat Diri",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Toto Priyono"
        },
        {
            "Judul Naskah": "Melihat Indonesia dengan Terbuka",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Ni Nyoman Ayu Suciartini"
        },
        {
            "Judul Naskah": "Melihat Orang-Orang Buta",
            "Kategori": "Novel atau Novela",
            "Penulis": "Aflaha Rizal Bahtiar (nama pena: Aflaha Rizal)"
        },
        {
            "Judul Naskah": "memanusiakan manusia",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "suharyo widagdo"
        },
        {
            "Judul Naskah": "Membaca Tubuh di Laut",
            "Kategori": "Novel atau Novela",
            "Penulis": "Aksan Taqwin Embe"
        },
        {
            "Judul Naskah": "Memberi Makan Malaikat",
            "Kategori": "Novel atau Novela",
            "Penulis": "Daniel"
        },
        {
            "Judul Naskah": "Memoar Pandemi 2020 : Pergulatan Batin Pada Covid-19",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Novy Khayra"
        },
        {
            "Judul Naskah": "Memoar Teratai Merah",
            "Kategori": "Novel atau Novela",
            "Penulis": "Evan Yovian"
        },
        {
            "Judul Naskah": "Menakar Rasa  (ROMANCE NEVER END)",
            "Kategori": "Novel atau Novela",
            "Penulis": "Heru Patria"
        },
        {
            "Judul Naskah": "Mencari Cara Mati Menderita",
            "Kategori": "Novel atau Novela",
            "Penulis": "Agus Subakir"
        },
        {
            "Judul Naskah": "Mencari Kebahagiaan, Menemukannya Di Dalam Hati",
            "Kategori": "Novel atau Novela",
            "Penulis": "Heri Widianto"
        },
        {
            "Judul Naskah": "Mencari Rakyat Di Tengah Pandemi Covid-19 Global",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Anicetus Windarto"
        },
        {
            "Judul Naskah": "Mencocol Sambal di Tasikmalaya, Menyeruput Latte di Paris",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Setyaningsih"
        },
        {
            "Judul Naskah": "Menepi dari Ingar Bingar Pesta",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Fajar Martha"
        },
        {
            "Judul Naskah": "Mengalah",
            "Kategori": "Novel atau Novela",
            "Penulis": "Dian Alima"
        },
        {
            "Judul Naskah": "Mengejar Cita Menuai Cinta",
            "Kategori": "Novel atau Novela",
            "Penulis": "Shainakaf"
        },
        {
            "Judul Naskah": "Mengunjungi Rumah Kekasih",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "Almunifah Nashir"
        },
        {
            "Judul Naskah": "Mentari Sehari Tidak Besinar",
            "Kategori": "Novel atau Novela",
            "Penulis": "Theresia Hardiyanti"
        },
        {
            "Judul Naskah": "Menukam Tambo",
            "Kategori": "Novel atau Novela",
            "Penulis": "Beri Hanna"
        },
        {
            "Judul Naskah": "Menunaikan Sepak Bola Indocina",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "Gusti Aditya Suprihana"
        },
        {
            "Judul Naskah": "Meru Anjani",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "Laily M Octavia"
        },
        {
            "Judul Naskah": "Meterai Terakhir",
            "Kategori": "Novel atau Novela",
            "Penulis": "Jemmy Piran"
        },
        {
            "Judul Naskah": "Mey Lin dan Isi Kepala Kekasihnya",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ardi Rai Gunawan"
        },
        {
            "Judul Naskah": "Mi Jowo",
            "Kategori": "Novel atau Novela",
            "Penulis": "Joanne"
        },
        {
            "Judul Naskah": "Mimpi Buruk Itu Membakar Kita",
            "Kategori": "Novel atau Novela",
            "Penulis": "Surya Gemilang"
        },
        {
            "Judul Naskah": "Miracantika",
            "Kategori": "Novel atau Novela",
            "Penulis": "Chandra Wulan"
        },
        {
            "Judul Naskah": "Misteri Hilangnya Sang Bidadari",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ogie Munaya"
        },
        {
            "Judul Naskah": "Mokondo: Lelaki Tua Kesepian yang Mencintai Buku-Buku",
            "Kategori": "Novel atau Novela",
            "Penulis": "Y Agusta Akhir"
        },
        {
            "Judul Naskah": "Momen Antara",
            "Kategori": "Novel atau Novela",
            "Penulis": "Andina Yudiarti"
        },
        {
            "Judul Naskah": "Morat-marit Sang Juru Lapar",
            "Kategori": "Novel atau Novela",
            "Penulis": "Eko sabto utomo"
        },
        {
            "Judul Naskah": "Museum Lengser",
            "Kategori": "Novel atau Novela",
            "Penulis": "Intan N.S."
        },
        {
            "Judul Naskah": "Musim Semi Revolusi",
            "Kategori": "Novel atau Novela",
            "Penulis": "Grace Angelica Nadapdap"
        },
        {
            "Judul Naskah": "Musulmanin",
            "Kategori": "Novel atau Novela",
            "Penulis": "Sarita Diang"
        },
        {
            "Judul Naskah": "Mutiara dalam Lumpur",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ariza Qanita"
        },
        {
            "Judul Naskah": "My Dangerous Husband",
            "Kategori": "Novel atau Novela",
            "Penulis": "Matchapio (kimmy keisha)"
        },
        {
            "Judul Naskah": "My Idiot Boyfriend",
            "Kategori": "Novel atau Novela",
            "Penulis": "Annisa Dwi Utami"
        },
        {
            "Judul Naskah": "My Troublemaker",
            "Kategori": "Novel atau Novela",
            "Penulis": "Aulia Rahma Tambusai"
        },
        {
            "Judul Naskah": "Nadif (Janji-Janji Masa Depan)",
            "Kategori": "Novel atau Novela",
            "Penulis": "Uli Aprilia Mukaromah (Little Magic)"
        },
        {
            "Judul Naskah": "Nafsu Binatang",
            "Kategori": "Novel atau Novela",
            "Penulis": "Afandi Garudatama"
        },
        {
            "Judul Naskah": "Nalar Kaum Sarungan: Pergulatan Pemikiran Islam Era Milenial",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Nasrullah Ainul Yaqin"
        },
        {
            "Judul Naskah": "Namaku Patra",
            "Kategori": "Novel atau Novela",
            "Penulis": "Faizatus Safiah"
        },
        {
            "Judul Naskah": "Negasi",
            "Kategori": "Novel atau Novela",
            "Penulis": "Danara"
        },
        {
            "Judul Naskah": "Nyutrayu",
            "Kategori": "Novel atau Novela",
            "Penulis": "Joko Gesang Santoso"
        },
        {
            "Judul Naskah": "Olivia",
            "Kategori": "Novel atau Novela",
            "Penulis": "Febri Angraini"
        },
        {
            "Judul Naskah": "One Name Disappeared in the World",
            "Kategori": "Novel atau Novela",
            "Penulis": "Samisih"
        },
        {
            "Judul Naskah": "Opera Sabun Mandi",
            "Kategori": "Novel atau Novela",
            "Penulis": "Trio Fajar Kurniawan"
        },
        {
            "Judul Naskah": "Our Home",
            "Kategori": "Novel atau Novela",
            "Penulis": "Amanda Christabella"
        },
        {
            "Judul Naskah": "Pahit.Manis",
            "Kategori": "Novel atau Novela",
            "Penulis": "Semesta Aksara"
        },
        {
            "Judul Naskah": "Pahlawan di Tepian Zaman",
            "Kategori": "Novel atau Novela",
            "Penulis": "Nur Cholish Majid"
        },
        {
            "Judul Naskah": "Pahlawan Hati",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Sarifah Aini Ritonga"
        },
        {
            "Judul Naskah": "Panah Untuk Claudia",
            "Kategori": "Novel atau Novela",
            "Penulis": "Marshalino Artland Ruimassa"
        },
        {
            "Judul Naskah": "Panggung Terakhir",
            "Kategori": "Novel atau Novela",
            "Penulis": "Faye Yolody"
        },
        {
            "Judul Naskah": "Papa dan Ayah",
            "Kategori": "Novel atau Novela",
            "Penulis": "Maurinta"
        },
        {
            "Judul Naskah": "Papua membuatku Jatuh Cinta",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "Siti Mukifah"
        },
        {
            "Judul Naskah": "Part Of My Wound",
            "Kategori": "Novel atau Novela",
            "Penulis": "Imelda Hafizah"
        },
        {
            "Judul Naskah": "Partition (Phase 1)",
            "Kategori": "Novel atau Novela",
            "Penulis": "Yurirsa Pradhipta"
        },
        {
            "Judul Naskah": "Patahkan Saja Hatinya!",
            "Kategori": "Novel atau Novela",
            "Penulis": "Elvira Tania"
        },
        {
            "Judul Naskah": "Patetis",
            "Kategori": "Novel atau Novela",
            "Penulis": "Febry Nur Rafahmi"
        },
        {
            "Judul Naskah": "Pembelajaran Jarak Jauh",
            "Kategori": "Novel atau Novela",
            "Penulis": "Cindy Wijaya"
        },
        {
            "Judul Naskah": "Pemburu-Pemburu Celaka",
            "Kategori": "Novel atau Novela",
            "Penulis": "Mochamad Nasrullah"
        },
        {
            "Judul Naskah": "Pencarian",
            "Kategori": "Novel atau Novela",
            "Penulis": "Yokko cesoeria lubis"
        },
        {
            "Judul Naskah": "Pencarian",
            "Kategori": "Novel atau Novela",
            "Penulis": "Sekar Aprilia Maharani"
        },
        {
            "Judul Naskah": "Pendidikan Pembebasan",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Ade Mulyono"
        },
        {
            "Judul Naskah": "Perempuan dan Penanya (Dalam Buku, Sastra, Bahasa)",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Anindita S Thayf"
        },
        {
            "Judul Naskah": "Perempuan Proyek",
            "Kategori": "Novel atau Novela",
            "Penulis": "Iroy"
        },
        {
            "Judul Naskah": "Perempuan yang Mendistorsi Semesta",
            "Kategori": "Novel atau Novela",
            "Penulis": "Riyanto"
        },
        {
            "Judul Naskah": "Perempuan yang Menyengam Sejarah",
            "Kategori": "Novel atau Novela",
            "Penulis": "Rian Gunady Putra (RG Putra)"
        },
        {
            "Judul Naskah": "Peri Kecil Patah Sayap",
            "Kategori": "Novel atau Novela",
            "Penulis": "Isna Hayu Rizqina"
        },
        {
            "Judul Naskah": "Perihal Aku dan Diri Berdiri, Mencari dan Berdamai",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "Ayunda Hanifah"
        },
        {
            "Judul Naskah": "Perjalanan Rahasia (Raga, Hasrat, Jiwa dan Asa)",
            "Kategori": "Novel atau Novela",
            "Penulis": "Annisa Adysti Ayunanditia"
        },
        {
            "Judul Naskah": "Perjanjian Dengan Arwah",
            "Kategori": "Novel atau Novela",
            "Penulis": "Kakanda Redi"
        },
        {
            "Judul Naskah": "Pernikahan Doktor ANN",
            "Kategori": "Novel atau Novela",
            "Penulis": "Almunifah Nashir"
        },
        {
            "Judul Naskah": "Pesan dari Perjalanan",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Risty Khoirunisa"
        },
        {
            "Judul Naskah": "Pesona Nusantara dalam Kata",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "Akhmad Idris"
        },
        {
            "Judul Naskah": "Pesta Boneka",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ahmad Dicka Hudzaifi"
        },
        {
            "Judul Naskah": "Petaka",
            "Kategori": "Novel atau Novela",
            "Penulis": "Beni Irawan"
        },
        {
            "Judul Naskah": "Piet",
            "Kategori": "Novel atau Novela",
            "Penulis": "Rindy Agassi"
        },
        {
            "Judul Naskah": "Piutang Rindu (Pendekatan Novel Berbasis Akuntansi)",
            "Kategori": "Novel atau Novela",
            "Penulis": "Fadli Refualu"
        },
        {
            "Judul Naskah": "Planet Biru",
            "Kategori": "Novel atau Novela",
            "Penulis": "Yuhana Kusumaningrum"
        },
        {
            "Judul Naskah": "Pocinta",
            "Kategori": "Novel atau Novela",
            "Penulis": "Akhmad Sekhu"
        },
        {
            "Judul Naskah": "Pohon Emas",
            "Kategori": "Novel atau Novela",
            "Penulis": "Yudhi Herwibowo"
        },
        {
            "Judul Naskah": "Pramanik",
            "Kategori": "Novel atau Novela",
            "Penulis": "Adya Tuti"
        },
        {
            "Judul Naskah": "Pramoedya, Multatuli dan Buli Buli Nirwan",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Endhiq Anang P"
        },
        {
            "Judul Naskah": "Puisi untuk Dirga",
            "Kategori": "Novel atau Novela",
            "Penulis": "Selviani"
        },
        {
            "Judul Naskah": "Pulang",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "Cybil Azri Yusnandha"
        },
        {
            "Judul Naskah": "Pulih",
            "Kategori": "Novel atau Novela",
            "Penulis": "Miranda Seftiana"
        },
        {
            "Judul Naskah": "Queen of The South Beach",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ahmad Dicka Hudzaifi"
        },
        {
            "Judul Naskah": "Rantai Makanan",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ahmad Dicka Hudzaifi"
        },
        {
            "Judul Naskah": "Redite",
            "Kategori": "Novel atau Novela",
            "Penulis": "Sri Nurani"
        },
        {
            "Judul Naskah": "Regal Couture",
            "Kategori": "Novel atau Novela",
            "Penulis": "Saxifraga"
        },
        {
            "Judul Naskah": "Rekam Jejak Langkah Kakiku",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "Diza Inuchia"
        },
        {
            "Judul Naskah": "Relief of Love",
            "Kategori": "Novel atau Novela",
            "Penulis": "Geti Oktaria Pulungan"
        },
        {
            "Judul Naskah": "Republik Rimba Raya",
            "Kategori": "Novel atau Novela",
            "Penulis": "Prilda Titi Saraswati"
        },
        {
            "Judul Naskah": "Resolusi Ketujuh",
            "Kategori": "Novel atau Novela",
            "Penulis": "agustina masito"
        },
        {
            "Judul Naskah": "Rin dan Pulau Pemimpi",
            "Kategori": "Novel atau Novela",
            "Penulis": "Saskara Jeneva"
        },
        {
            "Judul Naskah": "Rindu untuk sang Pendusta",
            "Kategori": "Novel atau Novela",
            "Penulis": "Yurizka"
        },
        {
            "Judul Naskah": "Rinne",
            "Kategori": "Novel atau Novela",
            "Penulis": "Evan Yovian"
        },
        {
            "Judul Naskah": "Risa",
            "Kategori": "Novel atau Novela",
            "Penulis": "rizqyfebrina"
        },
        {
            "Judul Naskah": "Riset di Finlandia: Catatan para Petualang Intelektual",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Adrianto Dwi Nugroho dkk."
        },
        {
            "Judul Naskah": "Riwayat Gila Swargaloka",
            "Kategori": "Novel atau Novela",
            "Penulis": "Pangga Rahmad"
        },
        {
            "Judul Naskah": "Riwayat Gila Swargaloka",
            "Kategori": "Novel atau Novela",
            "Penulis": "Pangga Rahmad"
        },
        {
            "Judul Naskah": "Rumah Pertama",
            "Kategori": "Novel atau Novela",
            "Penulis": "Wicak Hidayat"
        },
        {
            "Judul Naskah": "Rumah Syurga Para Bidadari",
            "Kategori": "Novel atau Novela",
            "Penulis": "Fathor Rosi"
        },
        {
            "Judul Naskah": "S (Sepotong Senja Sepenggal Sangka)",
            "Kategori": "Novel atau Novela",
            "Penulis": "Heru Patria"
        },
        {
            "Judul Naskah": "Saat Kaki Bercerita",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "Ziadah"
        },
        {
            "Judul Naskah": "Salah kirim file, pakai yang judulnya: Cincin Perak Bermata Putih (REVISI)",
            "Kategori": "Novel atau Novela",
            "Penulis": "Erwan Hamdani"
        },
        {
            "Judul Naskah": "Salju pun Turun di Jakarta",
            "Kategori": "Novel atau Novela",
            "Penulis": "Hizbul Ridho"
        },
        {
            "Judul Naskah": "Salma Tidak Diciptakan dari Tulang Rusuk Angki",
            "Kategori": "Novel atau Novela",
            "Penulis": "Bobi Tuankotta"
        },
        {
            "Judul Naskah": "Samarandu",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ade Maulana"
        },
        {
            "Judul Naskah": "Samsara",
            "Kategori": "Novel atau Novela",
            "Penulis": "Bagus Anugerah Yoga"
        },
        {
            "Judul Naskah": "Sapphire In The Lavender Garden",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ariska Putri Nugroho (Aoirisuka)"
        },
        {
            "Judul Naskah": "Sasmita dan Hikayat Burung Bence",
            "Kategori": "Novel atau Novela",
            "Penulis": "Hari Niskala"
        },
        {
            "Judul Naskah": "Sastra Komunal Era Digital - Sehimpun Esai dan Kritik Sastra",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Wahyu Kris"
        },
        {
            "Judul Naskah": "Sastra, Manusia, dan Negara",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Akhmad Idris"
        },
        {
            "Judul Naskah": "Satu Bulan Tiga Rindu",
            "Kategori": "Novel atau Novela",
            "Penulis": "Yus Gesa"
        },
        {
            "Judul Naskah": "Saujana",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "Denai Guna"
        },
        {
            "Judul Naskah": "Sebongkah Roti, Sepotong Keju, dan Sebakul Gegar",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Lusi Wulan"
        },
        {
            "Judul Naskah": "Sebuah Seni Bunuh Diri (Alternative Story)",
            "Kategori": "Novel atau Novela",
            "Penulis": "Syarifudin Emseh"
        },
        {
            "Judul Naskah": "Sebuah Tempat untuk Pulang",
            "Kategori": "Novel atau Novela",
            "Penulis": "Rizqi Turama"
        },
        {
            "Judul Naskah": "Sejarah Gedung",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ken Hanggara"
        },
        {
            "Judul Naskah": "Sejarah Perilaku Gila",
            "Kategori": "Novel atau Novela",
            "Penulis": "Han Gagas"
        },
        {
            "Judul Naskah": "Sekisah tentang Mualim dengan Fatimah",
            "Kategori": "Novel atau Novela",
            "Penulis": "Andriyana"
        },
        {
            "Judul Naskah": "Sekuntum Bunga Padma di Tresna Jalma",
            "Kategori": "Novel atau Novela",
            "Penulis": "Indra Agusta"
        },
        {
            "Judul Naskah": "Selusoh Sialang Rayo",
            "Kategori": "Novel atau Novela",
            "Penulis": "Iroy Mahyuni"
        },
        {
            "Judul Naskah": "Semata Akuarium",
            "Kategori": "Novel atau Novela",
            "Penulis": "Joan Oktavianie"
        },
        {
            "Judul Naskah": "Sempurna itu Kamu",
            "Kategori": "Novel atau Novela",
            "Penulis": "Windra Yuniarsih"
        },
        {
            "Judul Naskah": "Sendiko Gusti",
            "Kategori": "Novel atau Novela",
            "Penulis": "Dani Ismantoko"
        },
        {
            "Judul Naskah": "Senja di Lukisan",
            "Kategori": "Novel atau Novela",
            "Penulis": "Amy Djafar"
        },
        {
            "Judul Naskah": "Senja di Tanah Senja",
            "Kategori": "Novel atau Novela",
            "Penulis": "Susan Swansh"
        },
        {
            "Judul Naskah": "Senyawa",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ria Lusiandri"
        },
        {
            "Judul Naskah": "Seorang Bulan, Sebuah Paus, dan Seekor Pria",
            "Kategori": "Novel atau Novela",
            "Penulis": "Odelia"
        },
        {
            "Judul Naskah": "Separuh Asa Sepenuh Rasa",
            "Kategori": "Novel atau Novela",
            "Penulis": "Dewi Alusia Laras"
        },
        {
            "Judul Naskah": "Sepasang Perisai Bumi",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ira Oktiana"
        },
        {
            "Judul Naskah": "Seseorang yang Berdiri di Tepian Pekat",
            "Kategori": "Novel atau Novela",
            "Penulis": "Wisnu Adi Pratama"
        },
        {
            "Judul Naskah": "Shaddaw",
            "Kategori": "Novel atau Novela",
            "Penulis": "Vania Putri"
        },
        {
            "Judul Naskah": "Sharaza",
            "Kategori": "Novel atau Novela",
            "Penulis": "Yunita Safitri"
        },
        {
            "Judul Naskah": "Si Rara Ayu: Romansa ketika Tuhan Memberinya Kesatria",
            "Kategori": "Novel atau Novela",
            "Penulis": "Riri Hasa a.k.a Nuri Puji Hastuti"
        },
        {
            "Judul Naskah": "Siapa dia",
            "Kategori": "Novel atau Novela",
            "Penulis": "Akmal fadila"
        },
        {
            "Judul Naskah": "Simfoni di Hati Zara",
            "Kategori": "Novel atau Novela",
            "Penulis": "Asri Suciati"
        },
        {
            "Judul Naskah": "Sisi Kelam Dunia Literasi (Dunia Literasi? Hmmm ... Banyak Dramanya!",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "Ariny NH"
        },
        {
            "Judul Naskah": "Something Called Great Perhaps",
            "Kategori": "Novel atau Novela",
            "Penulis": "Febrianti Rahmalia Aldi"
        },
        {
            "Judul Naskah": "Sonata Untuk Orang Baik",
            "Kategori": "Novel atau Novela",
            "Penulis": "Hidayat D. Setyawan"
        },
        {
            "Judul Naskah": "Sonia",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ahmad Rusdy"
        },
        {
            "Judul Naskah": "Spekulatif dan esai-esai lainnya",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Wahyu Heriyadi"
        },
        {
            "Judul Naskah": "Stay With The Prince",
            "Kategori": "Novel atau Novela",
            "Penulis": "Shirley Du"
        },
        {
            "Judul Naskah": "Stupid Friendship",
            "Kategori": "Novel atau Novela",
            "Penulis": "Lattaphie_"
        },
        {
            "Judul Naskah": "Sudahi Sedih",
            "Kategori": "Novel atau Novela",
            "Penulis": "Hanif Iqbal"
        },
        {
            "Judul Naskah": "Sunyi dalam Kegelapan",
            "Kategori": "Novel atau Novela",
            "Penulis": "Agusti Syahara, nama pena: arasyahh.a (katekuchan)"
        },
        {
            "Judul Naskah": "sweet but possessive",
            "Kategori": "Novel atau Novela",
            "Penulis": "maryam uffa"
        },
        {
            "Judul Naskah": "Tanah Timah",
            "Kategori": "Novel atau Novela",
            "Penulis": "Yersita"
        },
        {
            "Judul Naskah": "Tears of A Winner",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Rosyda Amalia"
        },
        {
            "Judul Naskah": "Teen Dream",
            "Kategori": "Novel atau Novela",
            "Penulis": "Lukiarti Wardhani"
        },
        {
            "Judul Naskah": "Tempe Kemul",
            "Kategori": "Novel atau Novela",
            "Penulis": "Zee Astri"
        },
        {
            "Judul Naskah": "Tempo Hari",
            "Kategori": "Novel atau Novela",
            "Penulis": "SirroveneanGegeulu"
        },
        {
            "Judul Naskah": "Terdampar",
            "Kategori": "Novel atau Novela",
            "Penulis": "Joanne"
        },
        {
            "Judul Naskah": "Terlelap",
            "Kategori": "Novel atau Novela",
            "Penulis": "Topan Adiningrat"
        },
        {
            "Judul Naskah": "Ternyata Bulan Tidak Selalu Sama",
            "Kategori": "Novel atau Novela",
            "Penulis": "Wilman Praja Ibrahim"
        },
        {
            "Judul Naskah": "Ternyata Tresno",
            "Kategori": "Novel atau Novela",
            "Penulis": "Radityo Adi Wibowo"
        },
        {
            "Judul Naskah": "Terra Incognita",
            "Kategori": "Novel atau Novela",
            "Penulis": "Bagus Dwi Hananto"
        },
        {
            "Judul Naskah": "Thantophobia : Rasa Takut Kehilangan Orang yang Dicintai",
            "Kategori": "Novel atau Novela",
            "Penulis": ""
        },
        {
            "Judul Naskah": "the Arbitrator",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ade Meirizal"
        },
        {
            "Judul Naskah": "The Black Hole",
            "Kategori": "Novel atau Novela",
            "Penulis": "Jufan Rizky"
        },
        {
            "Judul Naskah": "The Cursed Girl Finds Love",
            "Kategori": "Novel atau Novela",
            "Penulis": "Nabilla Anasty Fahzaria"
        },
        {
            "Judul Naskah": "The Edge of The Cliff",
            "Kategori": "Novel atau Novela",
            "Penulis": "Yurirsa Pradhipta"
        },
        {
            "Judul Naskah": "The Greatest Memories",
            "Kategori": "Novel atau Novela",
            "Penulis": "Lxhaprl"
        },
        {
            "Judul Naskah": "The Hearer",
            "Kategori": "Novel atau Novela",
            "Penulis": "Deby Safitri Primadani"
        },
        {
            "Judul Naskah": "The Million Faces",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Ziwa Raisa"
        },
        {
            "Judul Naskah": "The Miracle Of Villa",
            "Kategori": "Novel atau Novela",
            "Penulis": "Melisa Carolus"
        },
        {
            "Judul Naskah": "The Story from Bromo",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ilham Nursyifa"
        },
        {
            "Judul Naskah": "The Unspoken Words of Langit and Samudra",
            "Kategori": "Novel atau Novela",
            "Penulis": "Rindi Nuris Velarosdela"
        },
        {
            "Judul Naskah": "This is My Spring",
            "Kategori": "Novel atau Novela",
            "Penulis": "Chiaulina"
        },
        {
            "Judul Naskah": "Titik Balik",
            "Kategori": "Novel atau Novela",
            "Penulis": "Meilia Citra"
        },
        {
            "Judul Naskah": "Titik Temu",
            "Kategori": "Novel atau Novela",
            "Penulis": "Meilia Citra"
        },
        {
            "Judul Naskah": "Tokyo Red",
            "Kategori": "Novel atau Novela",
            "Penulis": "Bagus Dwi Hananto"
        },
        {
            "Judul Naskah": "Topeng kelinci",
            "Kategori": "Novel atau Novela",
            "Penulis": "Sufi"
        },
        {
            "Judul Naskah": "Torean",
            "Kategori": "Novel atau Novela",
            "Penulis": "Aliurridha"
        },
        {
            "Judul Naskah": "Toxic dan Rahasia-rahasia yang disembunyikannya",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ni Nyoman Ayu Suciartini (Ayusha)"
        },
        {
            "Judul Naskah": "Travelling Hemat UK & Russia (under 10 jt)",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "Riswihani"
        },
        {
            "Judul Naskah": "True Knight",
            "Kategori": "Novel atau Novela",
            "Penulis": "Trisnajaya Shalsabila"
        },
        {
            "Judul Naskah": "Tunggal",
            "Kategori": "Novel atau Novela",
            "Penulis": "Iroy Mahyuni"
        },
        {
            "Judul Naskah": "Tuntunlah Aku Menju Syurga My Husband",
            "Kategori": "Novel atau Novela",
            "Penulis": "Nur Aflah Yusdha"
        },
        {
            "Judul Naskah": "Tutur Dedes: Doa dan Kutukan",
            "Kategori": "Novel atau Novela",
            "Penulis": "Amalia Yunus"
        },
        {
            "Judul Naskah": "Ular Jahat",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ang Rijal Amin"
        },
        {
            "Judul Naskah": "Untukmu, Aku Akan",
            "Kategori": "Novel atau Novela",
            "Penulis": "arleen a."
        },
        {
            "Judul Naskah": "Usaha Seorang Lelaki dalam Menghardik Sunyi",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ahmad Yusrifan Amrullah"
        },
        {
            "Judul Naskah": "UTOPIA Katak Menerkam Tempurung, selilit esai sastra",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Anton Suparyanta"
        },
        {
            "Judul Naskah": "Uwais Melamar Guru Negeri",
            "Kategori": "Novel atau Novela",
            "Penulis": "Mustofa Psi"
        },
        {
            "Judul Naskah": "Virus dan Kutu di Jendela Dunia",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Setyaningsih"
        },
        {
            "Judul Naskah": "wanita yang selingkuh dengan ular",
            "Kategori": "Novel atau Novela",
            "Penulis": "Cicilia Oday"
        },
        {
            "Judul Naskah": "Warung Jorok, Orang Kaya, dan Pancasila",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Arie Saptaji"
        },
        {
            "Judul Naskah": "When The Rain Stops",
            "Kategori": "Novel atau Novela",
            "Penulis": "Wulan Cahyaningsih"
        },
        {
            "Judul Naskah": "Willechomen im Erinnerungkr‰m",
            "Kategori": "Novel atau Novela",
            "Penulis": "Azzahra R Kamila"
        },
        {
            "Judul Naskah": "Written In The Stars",
            "Kategori": "Novel atau Novela",
            "Penulis": "Andyni Hardianto"
        },
        {
            "Judul Naskah": "Yang Mungkin Ditemukan Saat Kita Menemukan Kata",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Wawan Kurniawan"
        },
        {
            "Judul Naskah": "Yang Selalu Diumpet Rapat-Rapat",
            "Kategori": "Novel atau Novela",
            "Penulis": "Bobi Tuankotta"
        },
        {
            "Judul Naskah": "Yatra & Madhyaantar",
            "Kategori": "Tulisan Perjalanan",
            "Penulis": "Haryadi Yansyah"
        },
        {
            "Judul Naskah": "Yogyakarta Love Story",
            "Kategori": "Novel atau Novela",
            "Penulis": "Ahmad Dicka Hudzaifi"
        },
        {
            "Judul Naskah": "You Drives Me Crazy!",
            "Kategori": "Novel atau Novela",
            "Penulis": "Anindita Widya Ningrum"
        },
        {
            "Judul Naskah": "Zakia Aro",
            "Kategori": "Novel atau Novela",
            "Penulis": "Zulfairy"
        },
        {
            "Judul Naskah": "Zaman yang Dikalengkan",
            "Kategori": "Esai atau Memoar",
            "Penulis": "Andri Wikono"
        },
        {
            "Judul Naskah": "Zebrod",
            "Kategori": "Novel atau Novela",
            "Penulis": "Denny Herdy"
        },
        {
            "Judul Naskah": "Zoom, Why Did He Leave Meeting Quickly?",
            "Kategori": "Novel atau Novela",
            "Penulis": "Maurinta"
        }
    ]
}

export default function MencariNaskah(props) {

    const [isLoginFormShown, setIsLoginFormShown] = useState(false);
    const [loginMessage, setLoginMessage] = useState("")

    const buyTicketRef = useRef(null);
    const [cookie, setCookie, removeCookie] = useCookies()
    const isEN = cookie.lang !== "id";
    const [fbaSessions, setFbaSessions] = useState([]);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    const history = useHistory()

    const [isToCModalOpen, setIsToCModalOpen] = useState(false)
    const [isPPModalOpen, setIsPPModalOpen] = useState(false)

    const [isSessionModalShow, setIsSessionModalShown] = useState(false)
    const [isPaymentModalShow, setIsPaymentModalShow] = useState(false)
    const [paymentUrl, setPaymentUrl] = useState("")
    const [selectedSession, setSelectedSession] = useState(null)

    function handleResize() {
        console.log('width: ' + window.innerWidth)

        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
        })
    }

    const fetchSessions = async () => {

        console.log("is calling with " + GlobalData.token)

        let sessions = await new Session().getFBASessions();

        let defaultId = 0

        if (firstLoad) {
            if (props.match.params.id) {
                defaultId = this.props.match.params.id
                console.log("def", defaultId)
            }
            firstLoad = false
        }

        if (defaultId > 0) {
            let session = sessions.find(e => {
                console.log(defaultId, parseInt(e.id), parseInt(e.id) === parseInt(defaultId))
                return parseInt(e.id) === parseInt(defaultId)
            })
            console.log("selected session", sessions)
            if (session) {
                // this.setState({
                //     ...this.props,
                //     selectedSession: session,
                //     isSessionModalShown: true
                // })
            }

        }

        console.log(sessions)
        setFbaSessions(sessions)

    }

    useEffect(() => {

        fetchSessions()

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }

    }, []);

    const showLogin = () => {
        setIsLoginFormShown(true)
        setLoginMessage('Harap login untuk melanjutkan pendaftaran')
        setIsSessionModalShown(false)
    }

    const renderOldBody = () => {
        return <>
            <Container style={{paddingTop: '1em', paddingBottom: '1em', backgroundColor: "#FFFFFF50"}}>
                <Row style={{display: 'flex', justifyContent: 'center', marginTop: 50, marginBottom: 25}}>
                    <Col xl={10}>
                        <b style={{fontFamily: "Bebas-Neue", fontSize: 32}}>Renjana Indonesia Mencari Naskah
                        </b>
                        <p style={{marginTop: 15}}>
                            Tiga penerbit yang tergabung dalam kelompok Renjana Literasi Indonesia; Penerbit BaNaNa,
                            Penerbit Indonesia Tera, dan Indie Book Corner sedang mencari naskah-naskah terbaik dari
                            penulis terbaik Indonesia. Kami ingin mendengar suara-suara baru para penulis Indonesia
                            masa kini, baik dalam penulisan fiksi maupun nonfiksi.
                        </p>
                        <p>
                            Kami mencari naskah dalam tiga kategori berupa Novel atau Novela, Cerita Perjalanan,
                            Kumpulan Esai atau Memoar.
                        </p>
                        <p>
                            Jika kamu merasa memiliki kemampuan menulis yang baik, dan karyamu ingin diterbitkan,
                            dibaca banyak orang, menginspirasi, dan menawarkan sesuatu yang penting dari sudut
                            pandangmu, ini adalah waktu yang tepat untukmu mengirimkan karya. Selesaikan karya
                            terbaikmu, dan kami menunggu untuk membacanya.
                        </p>
                    </Col>
                </Row>
                <Row style={{display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 25}}>
                    <Col xl={10}>
                        <b style={{fontFamily: "Bebas-Neue", fontSize: 32}}>Kriteria Naskah
                        </b>
                        <ol style={{marginTop: 15}}>
                            <li><b>Novel atau Novela.</b> Panjang naskah novel minimal 30.000 kata, sementara
                                panjang naskah novela berkisar 15.000-40.000 kata.
                            </li>
                            <li style={{marginTop: 9}}><b>Cerita Perjalanan.</b> Panjang naskah minimum 30.000 kata.
                            </li>
                            <li style={{marginTop: 9}}><b>Kumpulan Esai atau Memoar.</b> Panjang naskah minimal
                                25.000 kata.
                            </li>
                        </ol>
                    </Col>
                </Row>
                <Row style={{display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 25}}>
                    <Col xl={10}>
                        <b style={{fontFamily: "Bebas-Neue", fontSize: 32}}>Ketentuan Pengiriman Naskah
                        </b>
                        <ol style={{marginTop: 15}}>
                            <li>Pengiriman naskah terbuka untuk umum.</li>
                            <li style={{marginTop: 9}}>Naskah merupakan karya asli, bukan hasil plagiat, bukan
                                terjemahan atau saduran.
                            </li>
                            <li style={{marginTop: 9}}>Naskah yang dikirim belum pernah dipublikasikan penerbit lain
                                atau tidak sedang mengikuti sayembara serupa.
                            </li>
                            <li style={{marginTop: 9}}>Memiliki sudut penceritaan yang unik dan segar.</li>
                            <li style={{marginTop: 9}}>Naskah utuh, sudah memiliki akhir, bukan sekadar
                                potongan-potongan bab.
                            </li>
                            <li style={{marginTop: 9}}>Tema penulisan bebas, tetapi diutamakan jenis karya yang
                                menggugah dan memiliki kesegaran gagasan.
                            </li>
                            <li style={{marginTop: 9}}>Boleh naskah yang kontroversial, tetapi tidak menyinggung
                                SARA.
                            </li>
                        </ol>
                    </Col>
                </Row>
                <Row style={{display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 25}}>
                    <Col xl={10}>
                        <b style={{fontFamily: "Bebas-Neue", fontSize: 32}}>Format Pengiriman Naskah
                        </b>
                        <ol style={{marginTop: 15}}>
                            <li>Naskah diketik dalam format ukuran kertas kuarto (A4) dengan 1.5 spasi.</li>
                            <li style={{marginTop: 9}}>Margin Atas 4, Kiri 4, Kanan 3, Bawah 3 (dalam cm).
                            </li>
                            <li style={{marginTop: 9}}>Jenis huruf Times New Roman, ukuran huruf 12 pt, paragraf
                                rata kiri-kanan.
                            </li>
                            <li style={{marginTop: 9}}>Pengiriman naskah dapat dilakukan dengan mengisi formulir di
                                bagian bawah halaman ini.
                            </li>
                            <li style={{marginTop: 9}}>Panitia akan memberikan surel konfirmasi melalui
                                renjana@patjarmerah.com, jika naskah kamu sudah kami terima dengan baik.
                            </li>
                            <li style={{marginTop: 9}}>Boleh naskah yang kontroversial, tetapi tidak menyinggung
                                SARA.
                            </li>
                        </ol>
                    </Col>
                </Row>
                <Row style={{display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 25}}>
                    <Col xl={10}>
                        <b style={{fontFamily: "Bebas-Neue", fontSize: 32}}>Ketentuan Tambahan
                        </b>
                        <ol style={{marginTop: 15}}>
                            <li>Setiap peserta yang mengirimkan naskah secara otomatis menyatakan kesediaan bahwa
                                naskah yang menang akan diterbitkan oleh penerbit di bawah kelompok Renjana
                                Indonesia, yaitu Penerbit BaNaNa, Penerbit Indonesia Tera, dan Indie Book Corner.
                            </li>
                            <li style={{marginTop: 9}}>Hak cipta naskah sepenuhnya milik penulis.</li>
                            <li style={{marginTop: 9}}>Akan dipilih 3 (tiga) pemenang pada setiap kategori naskah.
                                Total pemenang adalah 9 (sembilan) pemenang.
                            </li>
                            <li style={{marginTop: 9}}>Naskah para pemenang akan diterbitkan oleh penerbit
                                yang ada di bawah kelompok Renjana Indonesia, yaitu Penerbit BaNaNa, Indonesia Tera,
                                dan Indie Book Corner.
                            </li>
                            <li style={{marginTop: 9}}>Keputusan terkait penerbitan naskah merupakan keputusan juri
                                yang tidak dapat diganggu gugat.
                            </li>
                        </ol>
                    </Col>
                </Row>
                <Row style={{display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 25}}>
                    <Col xl={10}>
                        <b style={{fontFamily: "Bebas-Neue", fontSize: 32}}>Hadiah dan Kontrak Penerbitan
                        </b>
                        <ol style={{marginTop: 15}}>
                            <li>Masing-masing pemenang akan mendapatkan uang tunai sebesar <b>Rp2.500.000</b></li>
                            <li style={{marginTop: 9}}>Pemenang akan mendapatkan kelas khusus, serta pendampingan
                                penulisan dan penyuntingan naskah bersama para editor masing-masing penerbit.
                            </li>
                            <li style={{marginTop: 9}}>Para pemenang akan mendapatkan kontrak penerbitan naskah dan
                                penerbitannya akan dibiayai oleh penerbit yang ada di dalam kelompok Renjana
                                Literasi Indonesia, yaitu Penerbit BaNaNa, Penerbit Indonesia Tera, dan Indie Book
                                Corner.
                            </li>
                            <li style={{marginTop: 9}}>Setiap naskah pemenang akan diterbitkan dengan sistem
                                pembagian royalti.
                            </li>
                        </ol>
                    </Col>
                </Row>
                <Row style={{display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 25}}>
                    <Col xl={10}>
                        <b style={{fontFamily: "Bebas-Neue", fontSize: 32}}>Waktu Pelaksanaan
                        </b>
                        <ol style={{marginTop: 15}}>
                            <li>Periode pengiriman naskah dimulai sejak <b>1 November</b> hingga <b>25 Desember 2020</b>.
                            </li>
                            <li style={{marginTop: 9}}>Daftar panjang pemenang akan diumumkan pada <b>31 Januari
                                2021</b>.
                            </li>
                            <li style={{marginTop: 9}}>Daftar pendek pemenang akan akan diumumkan pada <b>7 Februari
                                2021</b>.
                            </li>
                            <li style={{marginTop: 9}}>Pemenang akan diumumkan pada <b>14 Februari
                                2021</b>.
                            </li>
                        </ol>
                    </Col>
                </Row>
                <Row style={{display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 25}}>
                    <Col xl={10}>
                        <b style={{fontFamily: "Bebas-Neue", fontSize: 32}}>Dewan Juri
                        </b>
                        <ol style={{marginTop: 15}}>
                            <li>Dorothea Rosa Herliany, Penerbit Indonesia Tera.
                            </li>
                            <li style={{marginTop: 9}}>Irwan Bajang, Indie Book Corner.
                            </li>
                            <li style={{marginTop: 9}}>Windy Ariestanty, Renjana Literasi Indonesia.
                            </li>
                            <li style={{marginTop: 9}}>Yusi Avianto Pareanom, Penerbit BaNaNa.
                            </li>
                        </ol>
                    </Col>
                </Row>
            </Container>
            <Container style={{paddingTop: '1em', paddingBottom: '1em', backgroundColor: "#FFFFFF50"}}>
                <Row>
                    <Col md={12}>
                        <h3><b>Daftar Naskah Diterima</b></h3>
                    </Col>
                </Row>
                <Row style={{display: 'flex', justifyContent: 'center', marginBottom: 25}}>
                    <Col md={12}>
                        <MDBDataTable
                            paginationLabel={["<", ">"]}
                            displayEntries={false}
                            info={false}
                            striped
                            data={jsonData}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    }

    return (
        <PageWrapper
            backgroundColor={"#e1b623"}
            loginShow={isLoginFormShown}
            message={loginMessage}
            customOnHide={isLoginFormShown ? () => setIsLoginFormShown(false) : null}
            headerActive={'mencarinaskah'}
            hideFooter={true}
        >

            <Container fluid style={{backgroundColor: "#e1b623"}}>
                <Row style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: "center",
                    backgroundColor: "#e1b623"
                }}>
                    <Col xl={10} style={{display: 'flex', justifyContent: 'center', alignContent: "center"}}>
                        <img src={require("../../asset/mencarinaskah/header-naskah.png")}
                             style={{width: '100%', maxWidth: 900, height: "100%"}}/>
                    </Col>
                </Row>

                <Container style={{paddingTop: '1em', paddingBottom: '1em', backgroundColor: "#FFFFFF50"}}>
                    <Row style={{display: 'flex', justifyContent: 'center', marginTop: 50, marginBottom: 25}}>
                        <Col xl={10}>
                            <b style={{fontFamily: "Bebas-Neue", fontSize: 32}}>Pengumuman Pemenang
                            </b>
                            <p style={{marginTop: 15}}>
                                Akhirnya kita sampai pada saat yang ditunggu-tunggu, pengumuman naskah pemenang dalam
                                ajang <b>“Kelompok Penerbit Renjana Indonesia Mencari Naskah”</b>.
                            </p>
                            <p>
                                Tadinya hanya akan ada tiga pemenang pada setiap kategori, yang kemudian akan dipilih
                                oleh masing-masing penerbit dalam Kelompok Renjana Indonesia untuk diterbitkan. Tetapi
                                melihat ada naskah lain yang berpeluang dan memang punya kekuatan penceritaan yang tak
                                kalah kuat, dewan juri akhirnya memutuskan menambah tiga naskah “terpilih” dari daftar
                                panjang yang diumumkan sebelumnya. Naskah terpilih ini selain mendapatkan kontrak
                                terbit, tentu juga mendapatkan hadiah yang akan diatur masing-masing penerbit.
                            </p>
                            <p><b>Dan berikut adalah Pemenang Kategori Novel/Novella:</b>
                            </p>
                            <ol style={{marginTop: 15}}>
                                <li>Cara Terbaik Membuat Kitab Suci, Yudhi Herwibowo. Akan diterbitkan oleh Penerbit
                                    Banana.
                                </li>
                                <li style={{marginTop: 9}}>Menukam Tambo, Beri Hanna. Akan diterbitkan oleh Indie Book
                                    Corner.
                                </li>
                                <li style={{marginTop: 9}}>Yang Selalu Diumpet Rapat-Rapat, Bobi Tuankotta. Akan
                                    diterbitkan oleh Penerbit Indonesia Tera.
                                </li>
                            </ol>
                            <p><b>Berikut adalah Naskah Pilihan Dewan Juri:</b>
                            </p>
                            <ol style={{marginTop: 15}}>
                                <li>Tutur Dedes: Doa dan Kutukan, Amalia Yunus. Akan diterbitkan oleh Penerbit Banana.
                                </li>
                                <li style={{marginTop: 9}}>Larung Suwung, Sri Lestari. Akan diterbitkan oleh Indie Book
                                    Corner.
                                </li>
                                <li style={{marginTop: 9}}>Mencari Cara Mati Menderita, Agus Subakir. Akan diterbitkan
                                    oleh Penerbit Indonesia Tera
                                </li>
                            </ol>
                            <p>
                                Selamat untuk para pemenang. Terima kasih untuk semua yang sudah berpartisipasi. Sukses
                                selalu untuk setiap naskah yang kalian tulis.
                            </p>
                            <p>
                                Bagi para pemenang dan penulis naskah terpilih, silakan menghubungi surel
                                <b> <a href="mailto:renjana@patjarmerah.com">renjana@patjarmerah.com</a></b>, mengirimkan nama lengkap, foto kartu pengenal dan nomor
                                rekening bank untuk proses administrasi.
                            </p>
                            <p>
                                Sampai jumpa di ajang-ajang selanjutnya. Sehat selalu di sana.
                            </p>
                            <p>
                                Salam sayang,
                            </p>
                            <p>
                                Dewan Juri
                            </p>
                            <p>
                                <b>Kelompok Penerbit Renjana Indonesia</b>
                            </p>
                        </Col>
                    </Row>
                </Container>

                <Row style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: "center",
                    backgroundColor: "#e1b623"
                }}>
                    <Col xl={10} style={{display: 'flex', justifyContent: 'center', alignContent: "center"}}>
                        <img src={require("../../asset/mencarinaskah/footer-naskah.png")}
                             style={{width: '100%', maxWidth: 900, height: "100%"}}/>
                    </Col>
                </Row>

            </Container>
        </PageWrapper>
    )
}
